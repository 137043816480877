import { calendarInitial } from "../actions/calendar";
import { CALENDAR } from "../constants/actions";
import { ReturnActionsTypes } from "../store";
import { initialCalendar } from "./initialStates/initialCalendar";

export interface CalendarEventType {
  typeEvent: string;
  fullNameEvent: string;
  color: number;
};

type CalendarInfo = {
  eventTypes: CalendarEventType[];
  allEvents: any;
  eventsFilter: string[];
};

const initialState: CalendarInfo = initialCalendar;

export type calendarType = ReturnActionsTypes<typeof calendarInitial>;

export const calendarReducer = (state = initialState, action: calendarType) => {
  switch (action.type) {
    case CALENDAR.EVENT_TYPES:
      return { ...state, eventTypes: action.payload };
    case CALENDAR.ALL_EVENTS:
      return { ...state, allEvents: action.payload };
    case CALENDAR.FILTER_EVENTS:
      return { ...state, eventsFilter: action.payload };
    default:
      return state;
  }
};
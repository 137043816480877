import React, { Dispatch, FC, SetStateAction } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import CustomButton from '../../../common/Buttons/CustomButton/CustomButton';
import MemberItem from "../Members/MemberItem/MemberItem";
import { PersonView } from "../../../../models/profile.models";

interface SearchResultMembersProps {
  totalFilteredMembers: number;
  setPage: Dispatch<SetStateAction<number>>;
}

export const SearchResultMembers: FC<SearchResultMembersProps> = ({
  totalFilteredMembers,
  setPage,
}) => {
  const filteredProfiles: PersonView[] = useAppSelector(
    (state) => state.profileReducer.filteredProfiles
  );

  const showMore = totalFilteredMembers > filteredProfiles.length;

  const getNextMembers = () => {
    setPage((prev: number) => prev + 1);
  };

  const moreMembersButtonProps = {
    onClick: getNextMembers,
    color: "secondary" as const,
    text: "Ещё сотрудники",
    styles: { width: "100%", mt: "8px" },
  };

  return (
    <Box>
      <Typography variant="body1" color="secondary" py={2}>
        Результаты
      </Typography>
      {filteredProfiles.map((item, index) => (
        <MemberItem userInfo={item} key={index} />
      ))}
      {showMore && <CustomButton {...moreMembersButtonProps} />}
    </Box>
  );
};

export default SearchResultMembers;

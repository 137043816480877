export const PROFILES = {
  PROFILE: "PROFILE",
  FILTERED_PROFILES: "FILTERED_PROFILES",
  EDIT_PROFILE: "EDIT_PROFILE",
  ALL_PROFILES: "ALL_PROFILES",
  TOTAL_COUNTS: "TOTAL_COUNTS",
};

export const DATA = {
  DATA_FEED: "DATA_FEED",
  DATA_FEED_ITEM: "DATA_FEED_ITEM",
  DATA_FEED_COMMENTS: "DATA_FEED_COMMENTS",
  DATA_STORY_GROUPS: "DATA_STORY_GROUPS",
  DATA_GROUP_STORIES: "DATA_GROUP_STORIES",
  DATA_ARTICLE_SECTIONS: "DATA_ARTICLE_SECTIONS",
  DATA_ARTICLE: "DATA_ARTICLE",
  DATA_IDEAS: "DATA_IDEAS",
  DATA_IDEA: "DATA_IDEA",
  DATA_IDEA_COMMENTS: "DATA_IDEA_COMMENTS",
  DATA_SMILES: "DATA_SMILES",
  DATA_NOTIFICATIONS: "DATA_NOTIFICATIONS",
  DATA_LIVE_NOTIFICATIONS: "DATA_LIVE_NOTIFICATIONS",
  DATA_SEEN_NOTIFICATIONS: "DATA_SEEN_NOTIFICATIONS",
};

export const SOCKETS = {
  LOGGED_USERS: "LOGGED_USERS",
};

export const CALENDAR = {
  EVENT_TYPES: "EVENT_TYPES",
  ALL_EVENTS: "ALL_EVENTS",
  FILTER_EVENTS: "FILTER_EVENTS",
};

export const COMMUNITY = {
  PERSON_COMMUNITIES: "PERSON_COMMUNITIES",
  COMMUNITY: "COMMUNITY",
  MESSAGES: "MESSAGES",
  MEMBERS: "MEMBERS",
  COMMUNITIES_LIST: "COMMUNITIES_LIST",
};

export const MANAGE_DATA = {
  DELETE_FILES: "DELETE_FILES",
};

export const ACHIEVEMENTS = {
  ACHIEVEMENT: "ACHIEVEMENT",
  ACHIEVEMENT_TASK: "ACHIEVEMENT_TASK",
  ACHIEVEMENTS_LIST: "ACHIEVEMENTS_LIST",
  ACHIEVEMENT_TAGS: "ACHIEVEMENT_TAGS",
  ACHIEVEMENT_COMMENTS: "ACHIEVEMENT_COMMENTS",
};

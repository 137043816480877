import React, { FC, useContext, Fragment, useState, useRef } from "react";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import moment from "moment";

import { theme } from "../../../constants/theme";
import { roles } from "../../../constants/role";
import { AppContext } from "../../shared/AppContextProvider";
import EditPopover from "../../common/Modals/EditPopover/EditPopover";
import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";

import { getBGColor } from "../CalendarDay/CalendarDay";
import { calendarController } from "../../../services/calendar.controller";
import { calendarInitial } from "../../../actions/calendar";
import { CalendarContext } from "../CalendarContext/CalendarContextProvider";
import EventModalContent from "../CalendarEventCreator/EventModalContent/EventModalContent";
import ConfirmModal from "../../common/Modals/ConfirmModal/ConfirmModal";
import { CalendarEventType } from '../../../reducer/calendar';
import { useAppSelector } from '../../../hooks/useAppSelector';

interface CalendarPopupProps {
  calendarDayEvents: any;
  onClose: () => void;
}

export const CalendarPopup: FC<CalendarPopupProps> = ({
  calendarDayEvents,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<number>(-1);
  const [openConfirmModal, setOpenConfirmModal] = useState<number>(-1);
  const [sendIsDisabled, setSendIsDisabled] = useState<boolean>(false);

  const forRepeatedEvent = useRef<boolean>();
  const confirmModalText = useRef<string>("");

  const { user } = useContext(AppContext);
  const notUserRole = user.role !== roles.user;

  const isMobile = useMediaQuery(useTheme().breakpoints.down("laptop"));

  const { onCalendarError: handleError } = useContext(CalendarContext);

  const eventTypes: CalendarEventType[] = useAppSelector(
    (state) => state.calendarReducer.eventTypes
  );

  const handleModal = (i: number) => {
    setOpenModal((prev) => (prev === -1 ? i : -1));
  };

  const handleCancel = () => {
    setOpenModal(-1);
    setSendIsDisabled(false);
  };

  const handleConfirmModal = (i: number) => {
    setOpenConfirmModal((prev) => (prev === -1 ? i : -1));
  };

  const closeConfirmModal = () => {
    setOpenConfirmModal(-1);
  };

  const handleDelete = (calendarEventId: number) => {
    calendarController
      .deleteEvent(calendarEventId, forRepeatedEvent.current)
      .then(() => {
        calendarController
          .allEvents()
          .then((res) => {
            dispatch(calendarInitial.allEvents(res.data));
          })
          .catch(() => {
            handleError();
          });
      })
      .catch(() => {
        handleError();
      })
      .finally(() => {
        closeConfirmModal();
        calendarDayEvents.length === 1 && onClose();
      });
  };

  const styleMainBox = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginLeft: "10px",
    marginTop: "6px",
    gap: "6px",
  };

  const styleCard = {
    margin: "4px 4px 8px 20px",
    minWidth: 280,
    maxWidth: 666,
    height: "auto",
    backgroundColor: "#212633",
    boxShadow: "none",
    overflowY: "scroll",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  };

  const styleContent = {
    marginTop: isMobile ? "-35px" : "-16px",
    paddingRight: isMobile ? "8px" : "12px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    "&:last-child": {
      paddingBottom: "16px",
    },
  };

  const boxIconStyle = {
    height: "16px",
    width: "16px",
    ml: "-26px",
    mr: "12px",
    borderRadius: "5px",
  };

  const hrStyle = {
    width: isMobile ? "108%" : "110%",
    backgroundColor: "rgba(241, 247, 255, 0.12)",
    height: "1px",
    border: "none",
    margin: "16px -32px 0px",
  };

  const modalButtonCreator = (calendarEvent: any): React.ReactNode => {
    const handleSend = () => {
      calendarController
        .editEvent(calendarEvent, forRepeatedEvent.current)
        .then(() => {
          calendarController
            .allEvents()
            .then((res) => {
              dispatch(calendarInitial.allEvents(res.data));
            })
            .catch(() => {
              handleError();
            });
        })
        .catch(() => {
          handleError();
        })
        .finally(() => {
          handleCancel();
        });
    };
    return (
      <ModalButtons
        nameButtonSend="Сохранить"
        handleSend={handleSend}
        handleCancel={handleCancel}
        justifyContent="end"
        disableSend={sendIsDisabled}
      />
    );
  };

  return (
    <Card sx={styleCard} onClick={(e) => e?.stopPropagation()}>
      {isMobile && (
        <Box display="flex" justifyContent="end" margin="8px">
          <IconButton
            sx={{ width: "32px", height: "32px" }}
            color="secondary"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <CardContent sx={styleContent}>
        <Box sx={styleMainBox}>
          {calendarDayEvents.map((calendarEvent: any, index: number) => {
            calendarEvent = structuredClone(calendarEvent);
            return (
              <Fragment key={index}>
                <Box display="flex" alignItems="center" mt="12px" width="100%">
                  <Box
                    bgcolor={getBGColor(eventTypes, calendarEvent.calendarEventType)}
                    {...boxIconStyle}
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    mt="1px"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Typography variant="subtitle2" mr="42px">
                      {calendarEvent.name}
                    </Typography>
                    {notUserRole && (
                      <>
                        <EditPopover
                          buttonsData={[
                            {
                              name: "Редактировать",
                              action: () => {
                                forRepeatedEvent.current = false;
                                handleModal(index);
                              },
                            },
                            {
                              name: "Удалить",
                              action: () => {
                                forRepeatedEvent.current = false;
                                confirmModalText.current = "Данное событие будет удалено.";
                                handleConfirmModal(index);
                              },
                            },
                            ...calendarEvent.repeatId ? [
                              {
                                name: "Редактировать цепочку событий",
                                action: () => {
                                  forRepeatedEvent.current = true;
                                  handleModal(index);
                                },
                              },
                              {
                                name: "Удалить цепочку событий",
                                action: () => {
                                  forRepeatedEvent.current = true;
                                  confirmModalText.current = "Данная цепочка событий будет удалена.";
                                  handleConfirmModal(index);
                                },
                              },
                            ] : [],
                          ]}
                        />
                        <DialogWrapper
                          openModal={openModal === index}
                          handleModal={() => handleModal(index)}
                          width="862px"
                          contentDividers
                          stylesContent={{ padding: "0px", borderTop: "none" }}
                          buttons={modalButtonCreator(calendarEvent)}
                        >
                          <EventModalContent
                            calendarEvent={calendarEvent}
                            setSendIsDisabled={setSendIsDisabled}
                            forRepeatedEvent={forRepeatedEvent.current}
                          />
                        </DialogWrapper>
                      </>
                    )}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="20px"
                  marginLeft="2px"
                  paddingRight="44px"
                >
                  <Typography
                    variant="body1"
                    color={theme?.palette?.text?.secondary}
                    sx={{
                      "&:first-letter": { textTransform: "uppercase" },
                      whiteSpace: "nowrap",
                    }}
                  >
                    {moment(calendarEvent.date, "DD-MM-YYYY").format(
                      "dddd, Do MMMM "
                    )}
                    {(calendarEvent.isWholeDay) ? "весь день" :  `${calendarEvent.startTime} - ${calendarEvent.finishTime}`}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={theme?.palette?.text?.secondary}
                  >
                    {calendarEvent.description}
                  </Typography>
                </Box>
                <ConfirmModal
                  openConfirmModal={openConfirmModal === index}
                  handleModal={() => handleConfirmModal(index)}
                  nameConfirmButton="Удалить"
                  handleConfirm={() => handleDelete(calendarEvent.id)}
                  text={confirmModalText.current}
                />
                {index < calendarDayEvents.length - 1 && <hr style={hrStyle} />}
              </Fragment>
            );
          })}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CalendarPopup;

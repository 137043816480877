import React, { FC, useContext, useState } from "react";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../../../common/Buttons/CustomButton/CustomButton";
import SectionModal from "../../KbModal/SectionModal";
import { AppContext } from "../../../shared/AppContextProvider";
import { roles } from "../../../../constants/role";

export const CreateKnowledgeButton: FC = () => {
  const { isMobile, user } = useContext(AppContext);
  const notUserRole = user.role !== roles.user;

  const wrapperButtonStyle = {
    mt: 4,
    display: "flex",
    justifyContent: "center",
  };

  const [createSectionModal, setCreateSectionModal] = useState<boolean>(false);
  const handleCreateSectioneModal = () => {
    setCreateSectionModal((prev) => !prev);
  };

  return (
    <>
      {notUserRole && (
        <Box sx={isMobile ? wrapperButtonStyle : undefined}>
          <CustomButton color="secondary" text="Создать раздел" startIcon={<AddIcon />} onClick={handleCreateSectioneModal} />
        </Box>
      )}
      {createSectionModal && (
        <SectionModal
          open={createSectionModal}
          handleModal={handleCreateSectioneModal}
          type="create"
        />
      )}
    </>
  );
};

export default CreateKnowledgeButton;

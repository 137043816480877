export const dbCalendarEventRecurrences = [
  "CENTURIES",
  "DAYS",
  "WEEKS",
  "MONTHS",
  "YEARS",
  "Каждый будний день",  // TODO: No corresponding API endpoint
];

export const calendarEventRecurrences = [
  { id: "0", name: "Не повторять" },
  { id: "1", name: "Ежедневно" },
  { id: "2", name: "Еженедельно" },
  { id: "3", name: "Ежемесячно" },
  { id: "4", name: "Каждый год" },
  // { id: "5", name: "Каждый будний день" },
];
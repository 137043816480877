import moment from 'moment';
import { regEx } from '../../constants/regEx';
import { checkLetters } from '../../operation/checkLetters';
import { UserInfo, UserInfoValidation } from "../../types/UserInfo.types";

export const PHONE_PLACEHOLDER = '+7 (___) ___ __ __';

/**
 * Returns true if specified parameter is valid date of birth string
 * Returns error string if parameter is invalid
 * @param {string} value
 * @returns {boolean}
 */
export const validateDateString = (value?: string | null): boolean | string => {
  const newDate = moment(value, 'YYYY-MM-DD');
  if (!newDate.isValid()) {
    return 'Введите корректную дату';
  }

  const maxDate = moment().subtract(14, 'years');
  if (newDate.isAfter(maxDate)) {
    return 'Сотрудник должен быть старше 14 лет';
  }

  const dateStr = newDate.format('YYYY-MM-DD');
  return dateStr !== 'Invalid date';
};

/**
 * Returns true if specified parameter is valid hired date string
 * Returns error string if parameter is invalid
 * @param {string} value
 * @returns {boolean}
 */
export const validateHiredDateString = (value?: string | null, dobValue?: string): boolean | string => {
  const newDate = moment(value, 'YYYY-MM-DD');
  if (!newDate.isValid()) {
    return 'Введите корректную дату';
  }

  const newBirthDate = moment(dobValue, 'YYYY-MM-DD');
  const birthDateStr = newBirthDate.format('YYYY-MM-DD');

  const dateStr = newDate.format('YYYY-MM-DD');
  if (dateStr === 'Invalid date') {
    return false;
  }

  if (birthDateStr !== 'Invalid date') {
    const hiredAge = newDate.diff(newBirthDate, 'years');
    if (hiredAge < 14) {
      return 'Сотрудник должен быть старше 14 лет на момент трудоустройства';
    }
  }

  return true;
};

/**
 * Returns true if specified parameter is valid e-mail or empty string
 * @param {string} email
 * @returns {boolean}
 */
export const checkEmail = (email?: string): boolean => (
  (email === '')
  || (
    !!email
    && email.length > 0
    && regEx.isEmail.test(email)
  )
);

/**
 * Returns true if specified parameter is valid telegram user id or empty string
 * @param {string} telegram
 * @returns {boolean}
 */
export const checkTelegram = (telegram?: string): boolean => (
  (telegram === '')
  || (
    !!telegram
    && telegram.length > 0
    && regEx.isTelegram.test(telegram)
  )
);

/**
 * Returns true if specified parameter is valid phone number or empty string
 * @param {string} phone
 * @returns {boolean}
 */
export const checkPhoneNumber = (phone?: string): boolean => (
  (phone === '')
  || (phone === PHONE_PLACEHOLDER)
  || (
    !!phone
    && phone.length > 0
    && regEx.isPhoneNumber.test(phone)
  )
);

type ValidatorKey = keyof UserInfoValidation;

type ValidatorFunc = (data: UserInfo) => boolean | string;
type ValidatorsMap = {
  [k in ValidatorKey]: ValidatorFunc;
};

export const validationMap: ValidatorsMap = {
  // PersonalInfo
  surname: (data: UserInfo) => data.surname !== '' && !checkLetters(data.surname),
  name: (data: UserInfo) => data.name !== '' && !checkLetters(data.name),
  patronymic: (data: UserInfo) => data.patronymic !== '' && !checkLetters(data.patronymic),
  // PersonalInfo.Job
  jobTitle: (data: UserInfo) => !!data.job?.id,
  department: (data: UserInfo) => !!data.department?.id,
  // PersonalInfo.DatePickerCustom
  dob: (data: UserInfo) => validateDateString(data.dob),
  hired: (data: UserInfo) => validateHiredDateString(data.hired, data.dob),
  // PersonalInfo.CityCombobox
  city: (data: UserInfo) => !!data.city?.id,
  // Contacts
  email: (data: UserInfo) => checkEmail(data.email),
  phone: (data: UserInfo) => checkPhoneNumber(data.phone),
  telegram: (data: UserInfo) => checkTelegram(data.telegram),
};

export const defaultValidation: UserInfoValidation = {
  // PersonalInfo
  surname: true,
  name: true,
  patronymic: true,
  // PersonalInfo.Job
  jobTitle: true,
  department: true,
  // PersonalInfo.DatePickerCustom
  dob: true,
  hired: true,
  // PersonalInfo.CityCombobox
  city: true,
  // Contacts
  email: true,
  phone: true,
  telegram: true,
};

/**
 * Sequentially runs validators for fields of user info
 * Returns validation result object on first fail or after all passed checks
 * @param {UserInfo} data
 * @returns {UserInfoValidation}
 */
export const validateUserForm = (data: UserInfo): UserInfoValidation => {
  const res: UserInfoValidation = {
    ...defaultValidation,
  };

  const fields = Object.keys(res);
  fields.every((field) => {
    const validator = validationMap[field as ValidatorKey];
    const isValidField = validator(data);
    res[field as ValidatorKey] = isValidField;
    return isValidField === true;
  });

  return res;
};

import React, { FC } from "react";

import { Box, Typography } from "@mui/material";

interface NameListProps {
  title: string;
  names: string[];
}

const textStyle = {
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",
};

export const NameList: FC<NameListProps> = ({ title, names }) => {
  return (
    <Box>
      <Typography color={(theme) => theme.palette.text.secondary}>
        {title}
      </Typography>
      {names.map((item, index) => (
        <Typography key={index} sx={textStyle}>{item}</Typography>
      ))}
    </Box>
  );
};

export default NameList;

import React, {
  FC,
  SetStateAction,
  Dispatch,
  useState,
  useContext,
  useMemo,
} from "react";

import Stack from "@mui/material/Stack";

import { UserInfo, UserInfoValidation } from "../../../../types/UserInfo.types";
import CustomSelect from "../../../common/Inputs/CustomSelect/CustomSelect";
import { useJobTitiles } from "../../../../hooks/useJobTitiles";
import { useDepartments } from "../../../../hooks/useDepartments";
import { AppContext } from "../../../shared/AppContextProvider";
import { findItemById } from '../../../../operation/findItemById';

interface PersonalInfoProps {
  personData: UserInfo;
  setPersonData: Dispatch<SetStateAction<UserInfo>>;
  disabled: boolean;
  validation: UserInfoValidation;
}

const pageSize = 10;

export const Job: FC<PersonalInfoProps> = ({
  personData,
  setPersonData,
  disabled,
}) => {
  const { isMobile } = useContext(AppContext);

  const [pageTitle, setPageTitle] = useState<number>(0);
  const [pageDepartment, setPageDepartment] = useState<number>(0);

  const titles = useJobTitiles(pageTitle);
  const departments = useDepartments(pageDepartment);

  const findJobById = (id: string) => findItemById(titles.titles, id);
  const findDepartmentById = (id: string) => findItemById(departments.departments, id);

  const handleChangeJob = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPersonData({ ...personData, job: findJobById(event.target.value) });
  };

  const handleChangeDepartment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPersonData({ ...personData, department: findDepartmentById(event.target.value) });
  };

  // +1 - it's test, for some users, scrolling does not work because 1px is lost
  const isScrolledToBottom = (event: any) => {
    const target = event.target;
    return target.scrollTop + target.clientHeight + 1 >= target.scrollHeight;
  };

  const handleTitlesScroll = (event: any) => {
    if (
      isScrolledToBottom(event)
      && titles.titles.length < titles.size
      && !titles.loading
    ) {
      setPageTitle(titles.titles.length / pageSize);
    }
  };

  const handleDeptsScroll = (event: any) => {
    if (
      isScrolledToBottom(event)
      && departments.departments.length < departments.size
      && !departments.loading
    ) {
      setPageDepartment(departments.departments.length / pageSize);
    }
  };

  const jobTitleItems = useMemo(() => (
    (!personData?.job || findJobById(personData?.job?.id))
      ? titles.titles
      : [personData?.job!, ...titles.titles].filter((item) => !!item)
  ), [personData?.job, titles.titles]);

  const jobDeptItems = useMemo(() => (
    (!personData?.department || findDepartmentById(personData?.department?.id))
      ? departments.departments
      : [personData?.department!, ...departments.departments].filter((item) => !!item)
  ), [personData?.department, departments.departments]);

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      sx={{ width: "100%", minWidth: 0 }}
      gap={isMobile ? "32px" : "12px"}
    >
      <CustomSelect
        testid="jobTitle"
        items={jobTitleItems}
        value={personData?.job?.id ?? ""}
        onChange={handleChangeJob}
        disabled={disabled}
        onScroll={handleTitlesScroll}
        placeholder="Выберите должность"
        height="400px"
      />
      <CustomSelect
        testid="department"
        items={jobDeptItems}
        value={personData?.department?.id ?? ""}
        onChange={handleChangeDepartment}
        disabled={disabled}
        onScroll={handleDeptsScroll}
        placeholder="Выберите департамент"
        height="400px"
      />
    </Stack>
  );
};

export default Job;

import React, { FC } from "react";

import { Box, Typography } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import CalendarTimePicker from "./CalendarTimePicker/CalendarTimePicker";

interface CalendarTimerProps {
  startValue: string;
  finishValue: string;
  handleStartTimeChange: (startTime: string) => void;
  handleFinishTimeChange: (finishTime: string) => void;
  disabled: boolean;
}
export const CalendarTimer: FC<CalendarTimerProps> = ({
  startValue,
  finishValue,
  handleStartTimeChange,
  handleFinishTimeChange,
  disabled,
}) => {
  const boxStyle = {
    display: "flex",
    flexDirection: "row",
    mt: "-10px",
    mb: "8px",
  };

  return (
    <Box sx={boxStyle}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <CalendarTimePicker
          value={startValue}
          handleTimeChange={handleStartTimeChange}
          disabled={disabled}
          title="Время начала события"
        />
        <Typography
          color={(theme) => theme?.palette?.text?.secondary}
          sx={{
            alignSelf: "center",
            marginLeft: 1,
            marginRight: 1,
          }}
        >
          {"-"}
        </Typography>
        <CalendarTimePicker
          value={finishValue}
          handleTimeChange={handleFinishTimeChange}
          disabled={disabled}
          title="Время окончания события"
        />
      </LocalizationProvider>
    </Box>
  );
};

export default CalendarTimer;
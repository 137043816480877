import React, { FC, ReactElement } from 'react'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import InputLine from '../InputLine/InputLine'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TextFieldProps } from '@mui/material'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import moment from 'moment'
import Typography from '@mui/material/Typography'

interface DatePickerCustomProps {
  value: any
  styles?: any
  onChange: (prop?: any) => void
  placeholder?: string
  helperText?: string
  maxDate?: Date | null
  disableFuture?: boolean
  disabled?: boolean
  error?: boolean
  label?: string | ReactElement
}

const styleDatePicker = {
  '.PrivatePickersYear-yearButton': {
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    appearance: 'button',
  },
}

export const DatePickerCustom: FC<DatePickerCustomProps> = ({
  value,
  styles,
  onChange,
  label,
  placeholder = 'ДД.ММ.ГГГГ',
  helperText = 'Введите корректную дату',
  maxDate = null,
  disableFuture = true,
  disabled,
  error = false,
}) => {
  const renderInput = (props: TextFieldProps): any => {
    const isError = (
      !!error
      || (value === 'Invalid date')
      || (
        !!disableFuture
        && moment(props.inputProps?.value, 'DD.MM.YYYY').isAfter()
      ) || (
        !!maxDate
        && moment(props.inputProps?.value, 'DD.MM.YYYY').isAfter(maxDate)
      )
    );

    return (
      <InputLine
        inputRef={props.inputRef}
        value={props.inputProps?.value}
        onChange={props.inputProps?.onChange}
        endAdornment={props.InputProps?.endAdornment}
        styles={{
          ...styles,
          '.MuiInputAdornment-root': {
            m: '0px 0px 2px',
          },
        }}
        placeholder={props.InputProps?.placeholder}
        error={isError}
        helperText={helperText}
        disabled={disabled}
      />
    )
  }
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      { !!label && (
        <Typography variant={'body1'} color={'textSecondary'} sx={{ mb: '-20px' }}>
          {label}
        </Typography>
      )}
      <DesktopDatePicker
        disableFuture={disableFuture}
        value={value}
        onChange={onChange}
        renderInput={renderInput}
        OpenPickerButtonProps={{ color: 'secondary' }}
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(33, 38, 51, 1)',
            ...styleDatePicker,
          },
        }}
        inputFormat='DD.MM.YYYY'
        components={{
          OpenPickerIcon: CalendarMonthOutlinedIcon,
        }}
        InputProps={{ placeholder: placeholder }}
        disabled={disabled}
      />
    </LocalizationProvider>
  )
}

export default DatePickerCustom

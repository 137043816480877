import React, { FC, useContext, useState, useRef } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import moment from "moment";

import { theme } from "../../../constants/theme";
import EditPopover, { buttonInfo } from "../../common/Modals/EditPopover/EditPopover";
import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";
import { useAppSelector } from "../../../hooks/useAppSelector";

import {
  useDeleteEventMutation,
  useUpdateEventMutation,
} from "../../../services/calendar.controller";
import { CalendarContext } from "../CalendarContext/CalendarContextProvider";
import EventModalContent from "../EventModalContent/EventModalContent";
import ConfirmModal from "../../common/Modals/ConfirmModal/ConfirmModal";
import { getBGColor } from "../CalendarDay/CalendarDay";
import { IEventsState, INewEvent } from "../../../types/CalendarEvent.type";
import { transformEventForPutRequest } from "../helpers";
import { styles } from "./styles";

interface CalendarPopoverProps {
  calendarEvent: IEventsState;
  closePopover: () => void;
}
// TODO: Combine CalendarPopup with CalendarPopover, as they share a lot of the same logic.
export const CalendarPopover: FC<CalendarPopoverProps> = ({
  calendarEvent,
  closePopover,
}) => {
  const eventTypes = useAppSelector((state) => state.calendarReducer.eventTypes);
  const { profile } = useAppSelector((state) => state.profileReducer);
  const ownUserId = profile.id;
  const eventRole = calendarEvent.participantsEvent.find(({ id }) => id === ownUserId)?.eventRole;
  const isRoleAdmin = profile.role.role === "ADMIN";
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [eventState, setEventState] = useState<INewEvent & Partial<IEventsState> | null>(calendarEvent)

  const [updateEvent, updateData] = useUpdateEventMutation();
  const [ deleteEvent, deleteData ] = useDeleteEventMutation();

  const forRepeatedEvent = useRef<boolean>();
  const confirmModalText = useRef<string>("");

  const { onCalendarError: handleError, editTagsFilter } =
    useContext(CalendarContext);

  const isInvalidEvent = !eventState;
  const isLoading = updateData.isLoading || deleteData.isLoading;
  const isInvalidTime = eventState && eventState.finishTime <= eventState.startTime;
  const isEmptyName = !eventState?.name;
  const isInvalidDate = !eventState?.date || eventState.date === "Invalid date"

  const disabled: boolean =
    isInvalidEvent || isLoading || isInvalidTime || isEmptyName || isInvalidDate;

  const handleModal = () => {
    setOpenModal((prev) => !prev);
  };

  const handleCancel = () => {
    setOpenModal(false);
    closePopover();
    setEventState(calendarEvent);
  };

  const handleConfirmModal = () => {
    setOpenConfirmModal((prev) => !prev);
  };

  const closeConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const handleSend = async () => {
    try {
      if (!eventState) return;
      const body = transformEventForPutRequest(eventState);
      const res = await updateEvent({ id: calendarEvent.id, body });
      if ("error" in res) throw new Error(res.error.message);
      handleCancel();
    } catch (error) {
      handleError();
    }
  };

  const handleDelete = async () => {
    try {
      const res = await deleteEvent({
        id: calendarEvent.id,
        params: forRepeatedEvent.current ? { repeatable: forRepeatedEvent.current } : undefined,
      });
      if ('error' in res) throw new Error(res.error.message);
      closeConfirmModal();
    } catch (error) {
      handleError();
    }
  };

  const modalButtons: React.ReactNode = (
    <ModalButtons
      nameButtonSend="Сохранить"
      handleSend={handleSend}
      handleCancel={handleCancel}
      justifyContent="end"
      disableSend={!isRoleAdmin && disabled}
    />
  );

  const getButtonsData = (): buttonInfo[] => {
    const result: buttonInfo[] = [];
    const isEditRights = (eventRole && eventRole !== 'PARTICIPANT') || isRoleAdmin;
    const isDeleteRights = (eventRole && eventRole === 'ORGANIZER') || isRoleAdmin;
    if (isEditRights)
      result.push({
        name: 'Редактировать',
        action: () => {
          forRepeatedEvent.current = false;
          setEventState(calendarEvent);
          handleModal();
        },
      });
    if (isDeleteRights)
      result.push({
        name: 'Удалить',
        action: () => {
          forRepeatedEvent.current = false;
          confirmModalText.current = 'Данное событие будет удалено.';
          handleConfirmModal();
        },
      });
    if (isDeleteRights && calendarEvent.repeatId)
      result.push({
        name: 'Удалить цепочку событий',
        action: () => {
          forRepeatedEvent.current = true;
          confirmModalText.current = 'Данная цепочка событий будет удалена.';
          handleConfirmModal();
        },
      });
    return result;
  };

  const buttonsData = getButtonsData();

  return (
    <Card sx={styles.styleCard}>
      <CardContent sx={styles.styleContent}>
        <Box sx={styles.styleMainBox}>
          <Box display="flex" alignItems="center" mt="12px" width="100%">
            <Box
              sx={styles.boxIconStyle}
              bgcolor={getBGColor(eventTypes, calendarEvent.calendarEventType)}
            />
            <Box
              display="flex"
              alignItems="center"
              mt="1px"
              width="100%"
              justifyContent="space-between"
            >
              <Typography variant="subtitle2" mr="42px">
                {calendarEvent.name}
              </Typography>
              {editTagsFilter.includes(
                  String(
                    eventTypes.find(
                      ({ id }) => id === calendarEvent.eventTypeId
                    )?.tag
                  )
                ) && Boolean(buttonsData.length) && (
                <>
                  <EditPopover buttonsData={buttonsData} />
                  <DialogWrapper
                    openModal={openModal}
                    handleModal={handleCancel}
                    width="862px"
                    contentDividers
                    stylesContent={{ padding: "0px", borderTop: "none" }}
                    buttons={modalButtons}
                  >
                    {eventState && <EventModalContent
                      forRepeatedEvent={forRepeatedEvent.current}
                      eventState={eventState}
                      setEventState={setEventState}
                    />}
                  </DialogWrapper>
                </>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
            marginLeft="2px"
            paddingRight="44px"
          >
            <Typography
              variant="body1"
              color={theme?.palette?.text?.secondary}
              sx={{
                "&:first-letter": { textTransform: "uppercase" },
                whiteSpace: "nowrap",
              }}
            >
              {moment(calendarEvent.date, "DD-MM-YYYY").format(
                "dddd, Do MMMM "
              )}
              {calendarEvent.isWholeDay
                ? "весь день"
                : `${calendarEvent.startTime} - ${calendarEvent.finishTime}`}
            </Typography>
            <Typography variant="body1" color={theme?.palette?.text?.secondary}>
              {calendarEvent.description}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <ConfirmModal
        openConfirmModal={openConfirmModal}
        handleModal={handleConfirmModal}
        nameConfirmButton="Удалить"
        handleConfirm={handleDelete}
        text={confirmModalText.current}
      />
    </Card>
  );
};

export default CalendarPopover;

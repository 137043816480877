import React, { FC, useContext } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";

import { theme } from "../../../constants/theme";
import { PersonView } from "../../../models/profile.models";
import { monthTranslate } from "../../../translators/month.translators";
import { AppContext } from "../../shared/AppContextProvider";

interface PersonalDataProps {
  userInfo: PersonView;
}

const textBreakStyle = {
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",
};

export const PersonalData: FC<PersonalDataProps> = ({ userInfo }) => {
  const { isMobile } = useContext(AppContext);
  const data = [
    {
      title: "Должность",
      text: userInfo?.job?.title.name,
      order: 1,
    },
    {
      title: "Пол",
      text: userInfo?.gender === "MALE" ? "муж." : "жен.",
      order: 3,
    },
    {
      title: "Город",
      text: userInfo?.city?.name,
      order: 2,
    },
    {
      title: "Дата рождения",
      text: userInfo?.dob
        ? `${moment(userInfo?.dob).format("DD")} ${monthTranslate(
            moment(userInfo?.dob).format("MMMM")
          )}`
        : "",
      order: 4,
    },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid item mobile={12}>
          <Typography variant="h4">Личные данные</Typography>
          <Typography
            variant="body2"
            color={theme?.palette?.text?.secondary}
            mt={1}
            sx={textBreakStyle}
          >
            {userInfo?.description}
          </Typography>
        </Grid>
        {data.map((item, index) => (
          <Grid
            item
            mobile={12}
            laptop={6}
            key={index}
            order={isMobile ? item.order : undefined}
          >
            <Typography variant="body1">{item.title}</Typography>
            <Typography variant="body2" color={theme?.palette?.text?.secondary}>
              {item.text}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PersonalData;

import React, { FC } from 'react';

import { Stack } from '@mui/material';

import { PersonView } from '../../../../models/profile.models';
import { ChipPerson } from '../../../common/ChipPerson';
import { styles } from './styles';

interface SelectedListProps {
  handleUnchose: (id: string) => void;
  selected: PersonView[];
}

export const SelectedList: FC<SelectedListProps> = ({ selected, handleUnchose }) => {
  if (!selected.length) return null;
  return (
    <Stack sx={styles.list}>
      {selected.map((person) => {
        return <ChipPerson key={person.id} person={person} onDelete={handleUnchose} />;
      })}
    </Stack>
  );
};

import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Image } from "mui-image";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";

import logo from "../../../assets/images/logo.png";

interface LogoProp {
  withTitle?: boolean;
  asLink?: boolean;
}

const logoStyle = {
  "&:hover, &:visited": {
    fontWeight: 700,
    textDecoration: "none",
    color: "unset",
  },

  display: "flex",
  gap: 1,
  fontWeight: 700,
  textDecoration: "none",
  color: "unset",
};

export const Logo: FC<LogoProp> = ({ withTitle, asLink }) => {
  const navigate = useNavigate();

  const handleLogo = () => {
    if (asLink) {
      navigate("/feed");
    }
  };

  return (
    <Box
      component={(asLink) ? 'a' : 'div'}
      href={(asLink) ? '/' : ''}
      mb={withTitle ? "16px" : "0"}
      onClick={handleLogo}
      sx={logoStyle}
    >
      <Image src={logo} alt="Logo" duration={0} height={40} width={52} />
      {withTitle && (
        <Typography variant="subtitle2" component="span">
          Loyalty <br />
          Labs
        </Typography>
      )}
    </Box>
  );
};

export default Logo;

import React, { FC, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";

import InputWrapper from "../../common/Inputs/InputWrapper/InputWrapper";
import InputLine from "../../common/Inputs/InputLine/InputLine";
import ChangePasswordButton from "../ChangePasswordButton/ChangePasswordButton";
import { personController } from "../../../services/person.controller";

interface MainInfoProps {
  userId: string;
}
export const MainInfo: FC<MainInfoProps> = ({ userId }) => {
  const [email, setEmail] = useState<string>("");
  useEffect(() => {
    if (userId) {
      personController.username(userId).then((res) => {
        setEmail(res.data.username);
      });
    }

    /* eslint-disable */
  }, [userId]);

  const data = [
    { name: "Е-mail (логин)", element: <InputLine testid="login" value={email} disabled /> },
    {
      name: "Пароль",
      element: <ChangePasswordButton userId={userId} />,
    },
  ];
  return (
    <Stack spacing={4}>
      {data.map((item, index) => (
        <InputWrapper key={index} title={item.name} element={item.element} />
      ))}
    </Stack>
  );
};

export default MainInfo;

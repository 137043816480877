import React, { FC, useState } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import LoggedUser from "./LoggedUser/LoggedUser";
import Members from "./Members/Members";
import SearchMember from "./Members/SearchMember/SearchMember";
import NotificationsTab from "./LoggedUser/NotificationsTab/NotificationsTab";

import { ReactComponent as UsersTabIcon } from "../../../assets/icons/user.svg";
import { ReactComponent as NotificationsTabIcon } from "../../../assets/icons/notificationsBell.svg";

import type { NotificationView } from "../../../models/notifications.model";

interface RightSidebarProps {
  style?: any;
}

export const RightSidebar: FC<RightSidebarProps> = ({ style }) => {
  const [valueSearch, setValueSearch] = useState<string>("");

  const [tabsValue, setTabsValue] = useState<number>(0);

  const handleTabsChange = (event: React.SyntheticEvent, newTabsValue: number) => {
    setTabsValue(newTabsValue);
  };

  const liveNotifications: NotificationView[] = useAppSelector(
    (state) => state.dataReducer.liveNotifications
  );

  const handleNotificationsTabClick = () => {
    "Notification" in window && Notification.permission === "default" && Notification.requestPermission();
  };
  
  const tabsStyle = {
    minHeight: "0px",
    mb: "16px",
    ".MuiTabs-flexContainer": {
      height: "40px",
      ".MuiButtonBase-root.MuiTab-root": {
        width: "50%",
        minHeight: "0px",
        alignItems: "start",
        paddingTop: "2px",
        borderBottom: "1px solid rgba(203, 225, 255, 0.17)",
        "&:hover:not(.Mui-selected)": {
          color: "rgba(230, 237, 245, 1)",
          borderBottom: "2px solid rgba(51, 146, 255, 0.4)",
        },
      },
    },
    ".MuiTabs-indicator": {
      height: "3px",
      borderRadius: "16px 16px 0px 0px",
    },
    ".MuiBadge-root": {
      gap: "8px",
      alignItems: "center",
      ".MuiBadge-badge": {
        height: "16px",
        minWidth: "16px",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
        padding: "4px",
        position: "relative",
        transform: "none",
        "&.MuiBadge-invisible": {
          display: "none",
        },
      },
    },
  };

  return (
    <Box sx={style} data-testid="rightSidebar">
      <LoggedUser />
      <Divider sx={{ margin: "12px 0px" }} />
      <Tabs value={tabsValue} onChange={handleTabsChange} sx={tabsStyle}>
        <Tab icon={<UsersTabIcon />} aria-label="users" />
        <Tab icon={
          <Badge badgeContent={liveNotifications.length} max={9} color="primary">
            <NotificationsTabIcon />
          </Badge>
        } aria-label="notifications" onClick={handleNotificationsTabClick} />
      </Tabs>
      {tabsValue ? <>
        <NotificationsTab />
      </> : <>
        <SearchMember valueSearch={valueSearch} setValueSearch={setValueSearch} />
        {!valueSearch && <Members />}
      </>}
    </Box>
  );
};

export default RightSidebar;

import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography } from "@mui/material";

import { iconButton } from "../../../../constants/buttonStyle";

interface BackButtonProps {
  authorName?: string;
  commentCount?: number;
  date?: string;
  showTextOnDesktop?: boolean;
  style?: any;
  iconStyle?: any;
  isTopRight?: boolean;
  handleNavigateBack?: () => void;
}

export const BackButton: FC<BackButtonProps> = ({
  authorName,
  commentCount,
  date,
  showTextOnDesktop,
  style,
  iconStyle,
  isTopRight,
  handleNavigateBack,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const styleFlexBox = {
    display: "flex",
    gap: "16px",
    marginLeft: isMobile ? "16px" : undefined,
    marginRight: isMobile ? "16px" : undefined,
    marginTop: isMobile && isTopRight ? "16px" : undefined,
    position: isMobile && isTopRight ? "absolute" : undefined,
    zIndex: isMobile && isTopRight ? 1000 : undefined,
    overflow: "hidden",
    alignItems: "flex-start",
    mb: "24px",
  };

  const authorTitleStyle = {
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  };

  const textColor = showTextOnDesktop ? "secondary" : undefined;

  const handleReturn = () => {
    handleNavigateBack?.();
    navigate("..", { relative: "path" });
  };

  return (
    <Box sx={{ ...styleFlexBox, ...style }}>
      <IconButton
        size={showTextOnDesktop ? "medium" : "small"}
        aria-label="delete"
        component="label"
        color="secondary"
        sx={{ ...iconButton, ...iconStyle }}
        onClick={handleReturn}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      {(showTextOnDesktop || isMobile) && authorName && (
        <Box display="flex" flexDirection="column">
          <Typography color={textColor} sx={authorTitleStyle} >{authorName}</Typography>
          {commentCount && <Typography color={textColor}>{commentCount} комментариев</Typography>}
          {date && <Typography color={textColor}>{date}</Typography>}
        </Box>
      )}
    </Box>
  );
};

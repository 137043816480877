import React from 'react';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { SharedButton } from '../../../../../common/Buttons/SharedButton';
import { styles } from './styles';

interface DialogFiredProps extends DialogProps {
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  content: string;
}

export const DialogFired = ({
  onCancel,
  onConfirm,
  content,
  ...rest
}: DialogFiredProps): JSX.Element => {
  return (
    <Dialog {...rest}>
      <Box sx={styles.dialogWrapper}>
        <DialogTitle sx={styles.title}>Подтвердите действие</DialogTitle>
        <DialogContentText sx={styles.content}>{content}</DialogContentText>
        <DialogActions sx={styles.dialogButtons}>
          <SharedButton sizeByDesign="48px" designType="secondary" onClick={onCancel}>
            Отмена
          </SharedButton>
          <SharedButton sizeByDesign="48px" onClick={onConfirm}>
            Подтвердить
          </SharedButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

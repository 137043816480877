import React, { FC, useState, useEffect, Dispatch, SetStateAction } from "react";

import moment from "moment";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Container, InputBase, SelectChangeEvent, Typography } from "@mui/material";

import InputLine from "../../../common/Inputs/InputLine/InputLine";
import DatePickerCustom from "../../../common/Inputs/DatePickerCustom/DatePickerCustom";
import CustomSelect from "../../../common/Inputs/CustomSelect/CustomSelect";

import CalendarTimer from "../../CalendarTimer/CalendarTimer";
import EventModalButtons from "../EventModalButtons/EventModalButtons";
import CheckboxWrapper from "../../../common/CheckboxWrapper/CheckboxWrapper";
import { calendarEventRecurrences } from "../../../../constants/calendar";
import { useAppSelector } from "../../../../hooks/useAppSelector";

import type { CalendarEvent } from "../../../../types/CalendarEvent.type";

interface EventModalContentProps {
  calendarEvent: CalendarEvent;
  setSendIsDisabled: Dispatch<SetStateAction<boolean>>;
  forRepeatedEvent?: boolean;
}

let tempId = 0;

const maxEventNameLength = 400;
const maxEventDescriptionLength = 400;

const chainEvent = (calendarEvent: CalendarEvent, allEvents: any) => {
  if (!calendarEvent.recurrence) {
    const date = moment(calendarEvent.date, "DD-MM-YYYY");
    const periods: (moment.unitOfTime.DurationConstructor | undefined)[] = [undefined, "d", "w", "M", "y"];
    let events: CalendarEvent[];
    for (let i = 1; i < periods.length; i++) {
      events = allEvents[date.clone().add(i, periods[i]).format("DD-MM-YYYY")];
      if (events?.some((event) => event.repeatId === calendarEvent.repeatId)) {
        return calendarEvent.recurrence = i;
      }
    }
  }
};

export const EventModalContent: FC<EventModalContentProps> = ({
  calendarEvent,
  setSendIsDisabled,
  forRepeatedEvent,
}) => {
  const allEvents = useAppSelector(state => state.calendarReducer.allEvents);
  forRepeatedEvent && chainEvent(calendarEvent, allEvents);
  const [eventInfo, setEventInfo] = useState<CalendarEvent>(calendarEvent);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));

  const [checkBoxValue, setCheckboxValue] = useState<boolean>(
    calendarEvent.startTime === "00:00" && calendarEvent.finishTime === "23:59"
  );

  useEffect(() => {
    calendarEvent.id = calendarEvent.id ?? tempId++;
  }, []);  // eslint-disable-line

  const handleTextInputChange =
    (prop: keyof CalendarEvent) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setEventInfo((prevState: CalendarEvent) => ({
        ...prevState,
        [prop]: event.target.value,
      }));
      calendarEvent[prop] = event.target.value as never;
      prop === "name" && setSendIsDisabled(event.target.value.trim() === "" ? true : false);
    };

  const handleDateChange = (newValue: string) => {
    newValue = moment(newValue).format("DD-MM-YYYY");
    setEventInfo({
      ...eventInfo,
      date: newValue,
    });
    calendarEvent.date = newValue;
  };

  const handleEventTypeChange = (newValue: string) => {
    setEventInfo({
      ...eventInfo,
      calendarEventType: newValue,
    });
    calendarEvent.calendarEventType = newValue;
  };

  const handleStartTimeChange = (startTime: string) => {
    setEventInfo({
      ...eventInfo,
      startTime: startTime,
    });
    calendarEvent.startTime = startTime;
  };

  const handleFinishTimeChange = (finishTime: string) => {
    setEventInfo({
      ...eventInfo,
      finishTime: finishTime,
    });
    calendarEvent.finishTime = finishTime;
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setCheckboxValue(checked);
    setEventInfo({
      ...eventInfo,
      startTime: "00:00",
      finishTime: "23:59",
    });
    calendarEvent.startTime = "00:00";
    calendarEvent.finishTime = "23:59";
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const newValue = calendarEventRecurrences.indexOf(e.target.value);
    setEventInfo({
      ...eventInfo,
      recurrence: newValue,
    });
    calendarEvent.recurrence = newValue;
  };

  const buttonsStyle = {
    margin: "8px",
    marginLeft: "-24px",
  };

  return (
    <Box display="flex" flexDirection="column" gap={3} px={{ mobile: "16px", laptop: "32px" }} py="32px">
      <InputBase
        placeholder="Начните вводить название"
        sx={{ fontSize: "32px", fontWeight: 700, lineHeight: "40px" }}
        onChange={handleTextInputChange("name")}
        multiline
        maxRows={3}
        value={eventInfo.name}
        autoFocus={!eventInfo.name}
        inputProps={{
          maxLength: maxEventNameLength,
          sx: { paddingInlineEnd: "0.5rem" },
        }}
      />
      <EventModalButtons styles={buttonsStyle} currentValue={eventInfo.calendarEventType} onChange={handleEventTypeChange} />
      <Container sx={{
        display: "flex", gap: "2px", alignItems: "center", pl: "0px", ".MuiFormControl-root": {
          minWidth: "130px",
          width: "initial",
          pt: "1px",
          ".MuiInputBase-root:not(.MuiInputBase-adornedEnd):before": {
            border: "none",
          },
        }
      }}>
        <DatePickerCustom
          value={moment(eventInfo.date, "DD-MM-YYYY")}
          onChange={handleDateChange}
          disableFuture={false}
          styles={{
            mr: "15px",
            pr: "8px",
            ".MuiButtonBase-root.MuiIconButton-root": {
              p: { mobile: "4px", tablet: "8px" },
            },
          }}
        />
        <CheckboxWrapper checked={checkBoxValue} onChange={handleCheckboxChange} />
        <Typography variant="body2" sx={{ minWidth: "fit-content", mr: "22px" }}>
          {"Весь день"}
        </Typography>
        {!isMobile && <CustomSelect
          items={calendarEventRecurrences}
          value={calendarEventRecurrences[eventInfo.recurrence]}
          onChange={handleSelectChange}
        />}
      </Container>
      {isMobile && <Box m="-8px 0px 8px">
        <CustomSelect
          items={calendarEventRecurrences}
          value={calendarEventRecurrences[eventInfo.recurrence]}
          onChange={handleSelectChange}
        />
      </Box>}
      <CalendarTimer
        handleStartTimeChange={handleStartTimeChange}
        handleFinishTimeChange={handleFinishTimeChange}
        disabled={checkBoxValue}
        startValue={eventInfo.startTime}
        finishValue={eventInfo.finishTime}
      />
      <InputBase
        placeholder="Начните вводить описание"
        value={eventInfo.description}
        multiline
        maxRows={5}
        inputProps={{
          maxLength: maxEventDescriptionLength,
          sx: { paddingInlineEnd: "0.5rem" },
        }}
        onChange={handleTextInputChange("description")}
        sx={{
          "&:before, &:hover:not(.Mui-disabled):before, &:after, &:focus-within:before, &:after, &.Mui-error:after": {
            borderBottom: "none",
          }
        }}
      />
    </Box>
  );
};

export default EventModalContent;
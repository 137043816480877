import React, { FC } from "react";

import InputBase from "@mui/material/InputBase";

interface InputFilledProps {
  testid?: string;
  value?: any;
  placeholder?: string;
  multiline?: boolean;
  handleValue?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  margin?: string | number;
}
//TODO: required field
export const InputFilled: FC<InputFilledProps> = ({
  testid,
  value,
  placeholder,
  multiline = false,
  handleValue,
  margin,
}) => {
  const styleInput = {
    bgcolor: "rgba(203, 225, 255, 0.17)",
    borderRadius: "12px",
    p: "12px 16px",
    m: margin,
    fontSize: "16px",
    lineHeight: "24px",
  };
  return (
    <InputBase
      data-testid={testid}
      fullWidth
      multiline={multiline}
      placeholder={placeholder}
      onChange={handleValue}
      value={value}
      sx={styleInput}
      minRows={4}
      maxRows={4}
    />
  );
};

export default InputFilled;

import React, { FC } from "react";

import {
  Stack,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  SelectChangeEvent,
} from "@mui/material";

interface SectionModalProps {
  value: 'replacement' | 'offset';
  onChange: (e: SelectChangeEvent) => void;
}

export const KbTypeSequence: FC<SectionModalProps> = ({
  value = 'replacement',
  onChange,
}) => {
  const styleRadio = {
    '& .MuiSvgIcon-root': {
      fontSize: 12,
    }
  }

  return (
    <FormControl>
      <RadioGroup
        name="radio-buttons-group-type-shift"
        value={value}
        onChange={onChange}
      >
        <Stack flexDirection={'row'} mt={0.5}>
          <FormControlLabel value="replacement" control={<Radio sx={styleRadio} />} label="C заменой" />
          <FormControlLabel value="offset" control={<Radio sx={styleRadio} />} label="Со смещением" />
        </Stack>
      </RadioGroup>
    </FormControl>
  );
};

export default KbTypeSequence;

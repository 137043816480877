import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import { theme } from '../../../../../constants/theme';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { IEventsState } from '../../../../../types/CalendarEvent.type';
import { ChipPerson } from '../../../../common/ChipPerson';
import { styles } from './styles';

interface descriptionAccordionProps extends Omit<AccordionProps, 'children'> {
  event: IEventsState;
}

export const DescriptionAccordion = ({
  event: { description, calendarEventType, participantsEvent },
  ...rest
}: descriptionAccordionProps): JSX.Element | null => {
  const eventTypes = useAppSelector((state) => state.calendarReducer.eventTypes);
  const generalName = eventTypes.find(({ tag }) => tag === 'GENERAL')?.calendarEventType;
  const isGeneralName = calendarEventType === generalName;

  if (!description && !isGeneralName) return null;

  return (
    <Accordion {...rest}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1" color={theme?.palette?.text?.secondary}>
          Описание:
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.details}>
        {description && (
          <Typography sx={styles.description} variant="body1" color={theme?.palette?.text?.secondary}>
            {description}
          </Typography>
        )}
        {isGeneralName && (
          <Box>
            <Typography variant="body1">Список участников: {participantsEvent.length}</Typography>
            <List>
              {participantsEvent.map((item) => {
                return (
                  <ListItem key={item.id}>
                    <ChipPerson person={item} />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

import React, { useContext, useEffect, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import moment from 'moment/moment';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { PersonView } from '../../../models/profile.models';
import { reviewThunks } from '../../../reducer/review';
import { ISkill } from '../../../types/Skill.type';
import ModalButtons from '../../common/Buttons/ModalButtons/ModalButtons';
import DatePickerCustom from '../../common/Inputs/DatePickerCustom/DatePickerCustom';
import DialogWrapper from '../../common/Modals/DialogWrapper/DialogWrapper';
import { AppContext } from '../../shared/AppContextProvider';
import { ReviewContext } from '../RewievContextProvider/ReviewInProfileContextProvider';
import GradeSelect from './Selects/GradeSelect';
import { UserSelect } from './Selects/UserSelect';
import { styleDatePicker } from './styles/createReviewModalStyles';

interface IProps {
  openModal: boolean;
  handleModal: () => void;
}

const CreateReviewModal = ({ openModal, handleModal }: IProps) => {
  const { isMobile } = useContext(AppContext);
  const { userId } = useContext(ReviewContext);
  const dispatch = useAppDispatch();

  const [selectedReviewers, setSelectedReviewers] = useState<PersonView[]>([]);
  const [currentSkill, setCurrentSkill] = useState<ISkill | null>(null);
  const [selectedEndReviewDate, setSelectedEndReviewDate] = useState('');
  const isDisabled =
    selectedEndReviewDate === 'Invalid date' ||
    selectedEndReviewDate === '' ||
    !selectedReviewers.length;

  const handleDateChange = (date: string) => {
    setSelectedEndReviewDate(date);
    const isAfterToday = moment(date).isAfter(moment());
    if (!isAfterToday) {
      setSelectedEndReviewDate('Invalid date');
    }
  };

  useEffect(() => {
    return () => {
      handleReset();
    };
  }, [openModal]);

  const serviceCreate = () => {
    const selectedReviewersID = selectedReviewers.map((el) => el.id);
    if (!selectedReviewersID || !currentSkill) return;
    const date = moment(selectedEndReviewDate).format('YYYY-MM-DD');
    const body = {
      ownerId: userId,
      reviewersId: selectedReviewersID,
      skillId: currentSkill.id,
      endReview: date,
    };
    dispatch(reviewThunks.createReview(body));
    handleModal();
  };

  const handleReset = () => {
    setSelectedReviewers([]);
    setCurrentSkill(null);
  };

  const handleSend = () => {
    serviceCreate();
    handleReset();
  };

  const handleCancel = () => {
    handleModal();
    handleReset();
  };

  return (
    <DialogWrapper
      openModal={openModal}
      handleModal={handleModal}
      width="532px"
      height={!isMobile ? '608px' : undefined}
      contentDividers
      buttons={
        <ModalButtons
          justifyContent={'right'}
          handleSend={handleSend}
          handleCancel={handleCancel}
          disableSend={isDisabled}
          nameButtonSend={'Сохранить'}
        />
      }
    >
      <Stack gap={3} display={'flex'} flexDirection={'column'}>
        <Typography variant="h4">{'Назначить ревью'}</Typography>
        {<GradeSelect currentGrade={currentSkill} setCurrentGrade={setCurrentSkill} />}
        <DatePickerCustom
          value={selectedEndReviewDate}
          label={
            <>
              Дата окончания ревью <span style={{ color: 'red' }}>*</span>
            </>
          }
          onChange={handleDateChange}
          disableFuture={false}
          styles={styleDatePicker}
        />
        <UserSelect
          selectedReviewers={selectedReviewers}
          setSelectedReviewers={setSelectedReviewers}
        />
      </Stack>
    </DialogWrapper>
  );
};

export default CreateReviewModal;

import React, { FC, useContext, useState } from "react";

import { Box, FormGroup, Typography } from "@mui/material";
import { FormControlLabel } from "@mui/material";

import CheckboxWrapper from "../../../../../../common/CheckboxWrapper/CheckboxWrapper";
import EditableTagPopover from "../../../EditableTagPopover/EditableTagPopover";
import { TaskTagView } from "../../../../../../../models/achievement.models";
import { AchievContext } from "../../../../../AchievContextProvider/AchievContextProvider";

interface TagItemProps {
  item: TaskTagView;
  isChecked: boolean;
  handleCheckbox: any;
}

export const TagItem: FC<TagItemProps> = ({
  item,
  isChecked,
  handleCheckbox,
}) => {
  const { setTag } = useContext(AchievContext);
  const handleEditButton = () => {
    setTag(item);
  };

  const [checked, setChecked] = useState<boolean>(isChecked);

  const styleTagBox = {
    p: "8px 12px",
    backgroundColor: item.color,
    borderRadius: 2,
    cursor: "auto",
  };

  const styleTitle = {
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <FormGroup sx={{ position: "relative", width: "100%" }}>
        <FormControlLabel
          sx={{ mr: "3px" }}
          control={
            <CheckboxWrapper
              onChange={(e) => handleCheckbox(e, setChecked, item)}
              checked={checked}
            />
          }
          label={
            <Box sx={styleTagBox}>
              <Typography sx={styleTitle}>
                {item.name}
              </Typography>
            </Box>
          }
          componentsProps={{
            typography: { sx: { display: "inline-block", width: "100%" } },
          }}
        />
      </FormGroup>
      <EditableTagPopover type="edit" onClick={handleEditButton} />
    </Box>
  );
};

export default TagItem;

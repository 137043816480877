import { SystemStyleObject, Theme } from '@mui/system';

const keys = ['chip', 'avatar'] as const;

type StylesType = Record<(typeof keys)[number], SystemStyleObject<Theme>>;

export const styles: StylesType = {
  chip: { width: 'max-content', backgroundColor: '#323947' },
  avatar: { width: '20px', height: '20px', marginLeft: '6px' },
};

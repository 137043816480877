import { SystemStyleObject, Theme } from '@mui/system';

const keys = ['list'] as const;

type StylesType = Record<(typeof keys)[number], SystemStyleObject<Theme>>;

export const styles: StylesType = {
  list: {
    gap: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
};

import React, { FC, SetStateAction, useState } from "react";

import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SendIcon from "@mui/icons-material/Send";

import { inputHoverStyle } from "../../../../constants/inputHoverStyle";
import { Box } from '@mui/material';
import AttachmentButton from "../../Buttons/AttachmentButton/AttachmentButton";
import IconCustomButton from "../../Buttons/IconCustomButton/IconCustomButton";
import AttachmentModal from "../AttachmentModal/AttachmentModal";
import { regEx } from "../../../../constants/regEx";
import { ErrorMessage } from "./ui/ErrorMessage";

interface TextMessageInputProps {
  textMessage: string;
  setTextMessage: SetStateAction<any>;
  handleSent: (props?: () => void) => void;
  handleAttach: (props?: any) => void;
  files: any[];
  setFiles: SetStateAction<any>;
  fullWidth?: boolean;
  disabled?: boolean
}

const maxTextLength = 11000

export const TextMessageInput: FC<TextMessageInputProps> = ({
  textMessage,
  setTextMessage,
  handleSent,
  handleAttach,
  files,
  setFiles,
  fullWidth,
  disabled
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));
  const isError = textMessage.length > maxTextLength;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > maxTextLength) {
      return
    }
    setTextMessage(event.target.value);
  };

  const checkEmptyMessage = regEx.isEmpty.test(textMessage);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleModal = () => {
    setOpenModal((prev) => !prev);
  };

  const handleFileSelection = (event: any) => {
    handleAttach(event);
    handleModal();
  };

  const handleKeypress = (e: any) => {
    if (e.key === "Enter" && !e.shiftKey && !checkEmptyMessage) {
      e.preventDefault();
      return handleSent();
    }
  };

  const inputStyle = {
    ...inputHoverStyle,
    borderRadius: 24,
    marginTop: 0,
    padding: "12px 0",
  };

  const styleFormControl = {
    width: isMobile ? "100%" : fullWidth ? "100%" : "45%",
  };

  return (
    <Box
      display="flex"
      justifyContent={fullWidth ? undefined : "center"}
      sx={{ width: fullWidth ? "100%" : undefined }}
    >
      <FormControl variant="filled" sx={styleFormControl}>
        <InputBase
          multiline
          maxRows={3}
          placeholder="Ваш комментарий"
          type="text"
          onChange={handleChange}
          sx={inputStyle}
          value={textMessage}
          onKeyDown={handleKeypress}
          startAdornment={
            <InputAdornment
              position="start"
              sx={{ mt: "0 !important", pl: "12px" }}
            >
              <AttachmentButton onChange={handleFileSelection} />
            </InputAdornment>
          }
          endAdornment={
            !checkEmptyMessage && (
              <InputAdornment
                position="end"
                sx={{ mt: "0 !important", pl: "12px", marginRight: "4px" }}
              >
                <IconCustomButton
                  icon={<SendIcon fontSize="small" />}
                  onClick={() => handleSent()}
                  styles={{ padding: "10px" }}
                  disabled={isError}
                />
              </InputAdornment>
            )
          }
          disabled={disabled}
        />
        {isError && <ErrorMessage maxTextLength={maxTextLength} />}
      </FormControl>
      <AttachmentModal
        openModal={openModal}
        handleModal={handleModal}
        files={files}
        setFiles={setFiles}
        textMessage={textMessage}
        setTextMessage={setTextMessage}
        handleSend={handleSent}
        disableSend={checkEmptyMessage || isError}
        handleAttache={handleAttach}
        errorMessage={
          isError ? (
            <ErrorMessage maxTextLength={maxTextLength} sx={{ padding: '0 0 0 1.5rem' }} />
          ) : undefined
        }
      />
    </Box>
  );
};

export default TextMessageInput;

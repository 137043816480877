import React, { FC, useContext, useState } from "react";

import ToggleButton from "@mui/material/ToggleButton";
import { Tabs, Theme } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useAppSelector } from '../../../../hooks/useAppSelector';
import { CalendarEventType } from "../../../../types/CalendarEvent.type";
import { CalendarContext } from "../../CalendarContext/CalendarContextProvider";

import { styles } from "./styles";
import { SystemStyleObject } from "@mui/system";

const StyledToggleButton = styled(ToggleButton, {
	shouldForwardProp(prop: string) {
		return (
			prop !== "textColor"
			&& prop !== "selectionFollowsFocus"
			&& prop !== "indicator"
		);
	},
})``;

interface EventModalButtonsProps {
	onChange: (eventTypeId: string) => void;
	currentValue: string;
	translator?: (name?: string) => string;
	stylesProp?: SystemStyleObject<Theme>;
	disabled?: boolean;
}

const EventModalButtons: FC<EventModalButtonsProps> = ({
	onChange,
	currentValue,
	translator,
	stylesProp = null,
	disabled = false,
}) => {
	const [value, setValue] = useState<string>(currentValue);
	const eventTypes: CalendarEventType[] = useAppSelector(
		(state) => state.calendarReducer.eventTypes
	);
	const { editTagsFilter } = useContext(CalendarContext);

	const handleValue = (
		event: React.SyntheticEvent<Element, Event>,
		newValue: string,
	) => {
		setValue(newValue);
		onChange(newValue);
	};

	return (
		<Tabs
			value={value}
			onChange={handleValue}
			variant="scrollable"
			scrollButtons={false}
			sx={[styles.styleGroup, stylesProp]}
			aria-label="scrollable events"
		>
			{eventTypes.filter(
					({ tag }) => editTagsFilter.includes(tag)
				).map(({id, fullName, colorHex}) => (
				<StyledToggleButton
					key={id}
					value={id}
					aria-label={fullName}
					sx={styles.styleSingle(colorHex)}
					disabled={disabled}
				>
					{translator ? translator(fullName) : fullName}
					
				</StyledToggleButton>)
			)}
		</Tabs>
	);
}

export default EventModalButtons;
import { createApi } from "@reduxjs/toolkit/query/react";

import { eventTypes, AllEventsQueryType } from "../reducer/calendar";
import type {
  CalendarEventType,
  IEventsResponse,
  INewEventRequest,
  IPutEventRequest,
  IWeekendsResponse,
} from "../types/CalendarEvent.type";

import { customBaseQuery } from "./customBaseQuery";

export const calendarApi = createApi({
  reducerPath: "calendarApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Events"],
  endpoints: (builder) => ({
    fetchAllEvents: builder.query<
      { items: IEventsResponse[], totalItems: number },
      AllEventsQueryType | void
    >({
      query: (params) => ({
        url: "calendar/corporate/events",
        method: "GET",
        params: params ?? undefined,
      }),
      providesTags: ["Events"],
    }),
    fetchEventTypes: builder.query<{ items: CalendarEventType[] }, void>({
      query: () => ({ url: "corporate/events/types", method: "GET" }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(eventTypes(data.items));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    fetchFile: builder.query<string, { eventTypesTag: string[] } | void>({
      query: (params) => {
        return {
          url: "calendar/corporate/events/create/file",
          method: "GET",
          params: params ?? undefined,
        };
      },
      providesTags: ["Events"],
    }),
    fetchWeekends: builder.query<IWeekendsResponse, { year: number }>({
      query: ({ year }) => ({
        url: `calendar/${year}`,
        method: "GET",
      }),
    }),
    updateEvent: builder.mutation<void, { id: string; body: IPutEventRequest }>(
      {
        query: ({ id, body }) => ({
          url: `calendar/corporate/events/${id}`,
          method: "PUT",
          body,
        }),
        invalidatesTags: ["Events"],
      }
    ),
    deleteEvent: builder.mutation<
      void,
      { id: string; params?: { repeatable: boolean } }
    >({
      query: ({ id, params }) => ({
        url: `calendar/corporate/events/${id}`,
        method: "DELETE",
        params,
      }),
      invalidatesTags: ["Events"],
    }),
    createEvent: builder.mutation<void, { body: INewEventRequest }>({
      query: ({ body }) => ({
        url: "calendar/corporate/events",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Events"],
    }),
  }),
});

export const {
  useFetchAllEventsQuery,
  useFetchEventTypesQuery,
  useLazyFetchFileQuery,
  useFetchWeekendsQuery,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useCreateEventMutation,
} = calendarApi;

import React, { FC, useEffect } from "react";
import { profileController } from "../../../../services/profile.controller";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../hooks/useAppSelector";

import UserInfo from "../../../common/User/UserInfo/UserInfo";
import { profilesInitial } from "../../../../actions/profiles";
import { PersonView } from "../../../../models/profile.models";

export const LoggedUser = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    profileController
      .profile()
      .then((res) => dispatch(profilesInitial.profile(res.data)))
      .catch(() => dispatch(profilesInitial.profile([])));
    /* eslint-disable */
  }, []);

  const personData: PersonView = useAppSelector(
    (state) => state.profileReducer.profile
  );

  return (
    <UserInfo withEllipsis={true}
              userInfo={personData}
              onlineStatus
              isCurrentUser
              testid="loggedUser"
    />
  );
};

export default LoggedUser;

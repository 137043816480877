import React, { FC, useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import DialogWrapper from "../../../../common/Modals/DialogWrapper/DialogWrapper";
import CommentTask from "../../../../common/CommentTask/CommentTask";
import ModalButtons from "../../../../common/Buttons/ModalButtons/ModalButtons";
import { AppContext } from "../../../../shared/AppContextProvider";
import { regEx } from "../../../../../constants/regEx";
import { CommentInfo } from "../Comment";
import { manageDataInitial } from "../../../../../actions/manageData";

interface EditCommentModalProps {
  openModal: boolean;
  handleModal: () => void;
  comment: CommentInfo;
  handleSend: (
    newContent: string,
    closeModal: () => void,
    newFiles?: any[]
  ) => void;
}

const maxTextLength = 11000

export const EditCommentModal: FC<EditCommentModalProps> = ({
  openModal,
  handleModal,
  comment,
  handleSend,
}) => {
  const dispatch = useDispatch();
  const { isMobile } = useContext(AppContext);
  const [content, setContent] = useState<string>("");
  const isMuchLong = content.length > maxTextLength;

  const handleComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  };

  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const handleAttachmentButton = (event: any) => {
    setSelectedFiles((prev) => [...prev, ...event.target.files]);
  };

  useEffect(() => {
    if (openModal) {
      setContent(comment.content);
      setSelectedFiles(comment.attachments ?? []);
    }
    /* eslint-disable-next-line */
  }, [openModal]);

  const handleCancel = () => {
    handleModal();
    setContent("");
    setSelectedFiles([]);
    dispatch(manageDataInitial.deleteFiles([]));
  };

  const handleSendButton = () => {
    handleSend(content, handleCancel, selectedFiles);
  };

  const buttons = (
    <ModalButtons
      disableSend={regEx.isEmpty.test(content) || isMuchLong}
      handleCancel={handleCancel}
      handleSend={handleSendButton}
      handleAttache={!isMobile ? handleAttachmentButton : undefined}
      fullWidth
    />
  );

  return (
    <DialogWrapper
      openModal={openModal}
      handleModal={handleModal}
      buttons={buttons}
      contentDividers
      width="862px"
    >
      <CommentTask
        comment={content}
        handleComment={handleComment}
        files={selectedFiles}
        setFiles={setSelectedFiles}
        handleAttache={isMobile ? handleAttachmentButton : undefined}
        fileLocation="bottom"
        placeholder="Ваш комментарий"
        mt={false}
        saveDeleteId
        errorTextMessage={isMuchLong ? `Достигнута максимальная длина (${maxTextLength} символов)` : undefined}
      />
    </DialogWrapper>
  );
};

export default EditCommentModal;

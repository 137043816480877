import React, { useState } from 'react';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Moment } from 'moment';

import { SharedButton } from '../../../common/Buttons/SharedButton';
import CustomSelect from '../../../common/Inputs/CustomSelect/CustomSelect';
import DatePickerCustom from '../../../common/Inputs/DatePickerCustom/DatePickerCustom';
import { styles } from './styles';

interface DialogBlockProps extends DialogProps {
  firedDate: Moment | null;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onConfirmBlock: React.MouseEventHandler<HTMLButtonElement>;
  onConfirmFired: React.MouseEventHandler<HTMLButtonElement>;
  onSetFiredDate: React.Dispatch<React.SetStateAction<Moment | null>>;
}

type TReason = 'quit' | 'offence';
interface IItem {
  id: TReason;
  name: string;
}

const items: IItem[] = [
  { id: 'quit', name: 'Увольнение сотрудника' },
  { id: 'offence', name: 'Нарушение правил безопасности' },
];

export const DialogBlock = ({
  open,
  firedDate,
  onClose,
  onCancel,
  onConfirmBlock,
  onConfirmFired,
  onSetFiredDate,
  ...rest
}: DialogBlockProps) => {
  const [reason, setReason] = useState<IItem>(items[0]);
  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <Box sx={styles.dialogWrapper}>
        <DialogTitle sx={styles.title}>Подтвердите блокировку</DialogTitle>
        <DialogContent sx={styles.content}>
          <Box sx={styles.selectBlock}>
            <Typography sx={styles.selectTitle}>Причина</Typography>
            <Box sx={styles.selectContainer}>
              <CustomSelect
                items={items}
                value={reason.id}
                onChange={(item) => {
                  setReason(items.find(({ id }) => id === item.target.value) ?? items[0]);
                }}
              />
            </Box>
          </Box>
          <Box>
            {reason.id === 'quit' && (
              <DialogContentText>
                Укажите дату увольнения сотрудника. После наступления этой даты доступ к системе
                будет автоматически заблокирован
              </DialogContentText>
            )}
            {reason.id === 'offence' && (
              <DialogContentText>
                После подтверждения блокировки доступ к системе будет автоматически заблокирован
              </DialogContentText>
            )}
          </Box>
          {reason.id === 'quit' && (
            <Box sx={styles.selectBlock}>
              <Typography sx={styles.selectTitle}>Дата увольнения</Typography>
              <Box sx={styles.selectContainer}>
                <DatePickerCustom
                  value={firedDate?.format() ?? ''}
                  disableFuture={false}
                  onChange={(date: Moment) => {
                    onSetFiredDate(date);
                  }}
                />
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={styles.dialogButtons}>
          <SharedButton sizeByDesign="48px" designType="secondary" onClick={onCancel}>
            Отмена
          </SharedButton>
          <SharedButton
            sizeByDesign="48px"
            onClick={reason.id === 'offence' ? onConfirmBlock : onConfirmFired}
            disabled={Boolean(reason.id === 'quit' && !firedDate)}
          >
            Заблокировать
          </SharedButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

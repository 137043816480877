import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../shared/AppContextProvider'
import { Stack, Typography } from '@mui/material'
import { UserSelect } from './Selects/UserSelect'
import DialogWrapper from '../../common/Modals/DialogWrapper/DialogWrapper'
import ModalButtons from '../../common/Buttons/ModalButtons/ModalButtons'
import GradeSelect from './Selects/GradeSelect'
import { PersonView } from '../../../models/profile.models'
import { ReviewContext } from '../RewievContextProvider/ReviewContextProvider'
import { reviewThunks } from '../../../reducer/review'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { skillListSelect } from '../../../reducer/selectors/reviewSelectors'
import DatePickerCustom from '../../common/Inputs/DatePickerCustom/DatePickerCustom'
import moment from 'moment/moment'

interface IProps {
  openModal: boolean
  handleModal: () => void
}

const styleDatePicker = {
  width: '100%',
  mr: '15px',
  pr: '8px',
  '.MuiButtonBase-root.MuiIconButton-root': {
    p: { mobile: '4px', tablet: '8px' },
  },
}

const ReviewModal = ({ openModal, handleModal }: IProps) => {
  const { isMobile } = useContext(AppContext)
  const { userId } = useContext(ReviewContext)
  const skillList = useAppSelector(skillListSelect)
  const dispatch = useAppDispatch()

  const [selectedReviewers, setSelectedReviewers] = useState<PersonView[]>([])
  const [currentSkill, setCurrentSkill] = useState('')
  const [selectedEndReviewDate, setSelectedEndReviewDate] = useState('')

  const handleDateChange = (date: string) => {
    setSelectedEndReviewDate(date)
    const isAfterToday = moment(date).isAfter(moment())
    if (!isAfterToday) {
      setSelectedEndReviewDate('Invalid date')
    }
  }

  useEffect(() => {
    return () => {
      handleReset()
    }
  }, [openModal])

  const serviceCreate = () => {
    const selectedReviewersID = selectedReviewers.map((el) => el.id)
    const selectedSkillID = skillList.find((grade) => grade.id === currentSkill)
    const date = moment(selectedEndReviewDate).format('YYYY-MM-DD')

    if (selectedReviewersID && selectedSkillID) {
      const body = {
        ownerId: userId,
        reviewersId: selectedReviewersID,
        skillId: selectedSkillID.id,
        endReview: date,
      }
      dispatch(reviewThunks.createReview(body))
      handleModal()
    }
  }

  const handleReset = () => {
    setSelectedReviewers([])
    setCurrentSkill('')
  }

  const handleSend = () => {
    serviceCreate()
    handleReset()
  }

  const handleCancel = () => {
    handleModal()
    handleReset()
  }

  return (
    <DialogWrapper
      openModal={openModal}
      handleModal={handleModal}
      width='532px'
      height={!isMobile ? '608px' : undefined}
      contentDividers
      buttons={
        <ModalButtons
          justifyContent={'right'}
          handleSend={handleSend}
          handleCancel={handleCancel}
          disableSend={selectedEndReviewDate === 'Invalid date' || selectedEndReviewDate === ''}
          nameButtonSend={'Сохранить'}
        />
      }
    >
      <Stack gap={3} display={'flex'} flexDirection={'column'}>
        <Typography variant='h4'>{'Назначить ревью'}</Typography>
        <GradeSelect skillList={skillList} currentGrade={currentSkill} setCurrentGrade={setCurrentSkill} />
        <DatePickerCustom
          value={selectedEndReviewDate}
          label={
            <>
              Дата окончания ревью <span style={{ color: 'red' }}>*</span>
            </>
          }
          onChange={handleDateChange}
          disableFuture={false}
          styles={styleDatePicker}
        />
        <UserSelect selectedReviewers={selectedReviewers} setSelectedReviewers={setSelectedReviewers} />
      </Stack>
    </DialogWrapper>
  )
}

export default ReviewModal

import React, { FC, useState, useContext, ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";

import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

import PreviewFiles from "../../common/Attachments/PreviewFiles/PreviewFiles";
import AttachmentButton from "../../common/Buttons/AttachmentButton/AttachmentButton";
import CustomButton from "../../common/Buttons/CustomButton/CustomButton";
import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import { secondaryButton } from "../../../constants/buttonStyle";
import { ideasController } from "../../../services/ideas.controller";
import { dataInitial } from "../../../actions/data";
import { LoadProgress } from "../../common/LoadProgress/LoadProgress";
import { IdeasContext } from "../IdeasContext/IdeasContextProvider";

import type { Idea as IdeaView } from "../../../types/Idea.type";

interface CreateEditIdeaProps {
  ideaData?: IdeaView;
  isEditOpen?: boolean;
  handleEditClose?: () => void;
}

const maxIdeaNameLength = 70;
const maxIdeaDescriptionLength = 250;

export const CreateEditIdea: FC<CreateEditIdeaProps> = ({ ideaData, isEditOpen, handleEditClose }) => {
  const dispatch = useDispatch();
  const ideas: IdeaView[] = useAppSelector(
    (state) => state.dataReducer.ideas
  );
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const {
    pageSize,
    totalPages,
    status,
    saveIdeasSessionData,
    onIdeasError: handleError,
  } = useContext(IdeasContext);

  const [ideaHeaderText, setIdeaHeaderText] = useState<string>("");
  const handleHeaderInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIdeaHeaderText(event.target.value);
  };

  const [ideaContentText, setIdeaContentText] = useState<string>("");
  const handleContentInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIdeaContentText(event.target.value);
  };

  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
  const handleDialogOpen = () => setIsDialogOpened(true);
  const handleDialogClose = () => {
    if (ideaData) {
      handleEditClose!();
    } else {
      setIsDialogOpened(false);
      setIdeaContentText("");
      setIdeaHeaderText("");
      setSelectedFiles([]);
    }
  };

  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const handleAttachmentButton = (event: any) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
    event.target.value = "";
  };

  useEffect(() => {
    setIdeaHeaderText(ideaData?.header ?? "");
    setIdeaContentText(ideaData?.content ?? "");
    setSelectedFiles(ideaData?.attachments ?? []);
  }, [ideaData]);

  const handleSend = () => {
    setShowLoader(true);
    const formData = new FormData();
    formData.append(
      "request",
      new Blob(
        [
          JSON.stringify({
            header: ideaHeaderText,
            content: ideaContentText,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    selectedFiles.forEach((file) => {
      !ideaData?.attachments.some(attachment => attachment.id === file?.id)
        && formData.append(ideaData ? "file" : "files", file);
    });
    (ideaData
      ? ideasController
        .editIdea(ideaData.id, {
          header: ideaHeaderText,
          content: ideaContentText,
        })
        .then(() => {
          dispatch(dataInitial.idea({ ...ideaData, header: ideaHeaderText, content: ideaContentText }));
          const deleteAttPromise = ideasController
            .deleteIdeaAttachments(ideaData.id, {
              data: {
                attachmentsIds: ideaData.attachments
                  .filter(attachment => (
                    !selectedFiles.find(file => file?.id === attachment.id)
                  ))
                  .map(attachment => attachment.id),
              },
            });
          const addAttPromise = formData.has("file") && ideasController
            .addIdeaAttachments(ideaData.id, formData);
          Promise.all([deleteAttPromise, addAttPromise])
            .then(() => {
              ideasController
                .idea(ideaData.id)
                .then((res) => {
                  const ideaIndex = ideas.findIndex(idea => idea.id === ideaData.id);
                  const newIdeas = structuredClone(ideas);
                  newIdeas[ideaIndex] = {
                    ...res.data,
                    likesCount: ideaData.likesCount,
                    likeIsPressed: ideaData.likeIsPressed,
                    dislikesCount: ideaData.dislikesCount,
                    dislikeIsPressed: ideaData.dislikeIsPressed,
                  };
                  dispatch(dataInitial.idea(newIdeas[ideaIndex]));
                  dispatch(dataInitial.ideas(newIdeas));
                })
                .catch(() => {
                  handleError();
                });
            })
            .catch(() => {
              handleError();
            });
        })
      : ideasController
        .newIdea(formData)
        .then((res) => {
          if (status === "Актуальные") {
            const newIdeas = structuredClone(ideas);
            newIdeas.unshift({
              ...res.data,
              likesCount: 0,
              likeIsPressed: false,
              dislikesCount: 0,
              dislikeIsPressed: false,
            });
            newIdeas.length % pageSize === 1 && newIdeas.length !== 1
              && newIdeas.pop() && ++totalPages.current
              && saveIdeasSessionData({ totalPages: totalPages.current });
            dispatch(dataInitial.ideas(newIdeas));
          }
        })
        .catch(() => {
          handleError();
        })
    ).then(() => {
      handleDialogClose();
    })
    .catch(() => {
      handleError();
    })
    .finally(() => {
      setShowLoader(false);
    });
  };

  const buttonStyle = {
    borderRadius: "60px",
    padding: "12px 24px",
    typography: "body1",
  };

  const wrapperButtonStyle = {
    position: "fixed",
    left: "50%",
    bottom: { mobile: "128px", laptop: "40px" },
    transform: "translateX(-55%)",
  };

  const createButton = (
    <Box sx={wrapperButtonStyle}>
      <CustomButton
        color="secondary"
        text="Создать"
        startIcon={<AddIcon />}
        onClick={handleDialogOpen}
      />
    </Box>
  );

  const loaderWrapper = (loader: ReactNode) => (
    ideaData ? (
      <Box position="absolute" left="calc(50% - 20px)" top={{ mobile: "25%", laptop: "20%" }}>
        {loader}
      </Box>
    ) : loader
  );

  const dialogButtons = (
    <Box
      mt={selectedFiles.length > 0 ? 3 : undefined}
      display="flex"
      justifyContent="space-between"
      width="100%"
    >
      <AttachmentButton
        onChange={handleAttachmentButton}
        withBackground
        padding="10px"
      />
      <Box>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={handleDialogClose}
          sx={{
            ...buttonStyle,
            ...secondaryButton,
            mr: "16px",
          }}
        >
          Отмена
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={ideaContentText && ideaHeaderText ? false : true}
          sx={buttonStyle}
          size="small"
          onClick={handleSend}
        >
          Сохранить
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      {showLoader ? loaderWrapper(<LoadProgress />) : <>
        {!ideaData && createButton}
        <DialogWrapper
          openModal={isEditOpen ?? isDialogOpened}
          handleModal={handleDialogClose}
          width="862px"
          buttons={dialogButtons}
          contentDividers
        >
          <InputBase
            placeholder="Начните вводить название идеи"
            fullWidth
            multiline
            sx={{ fontSize: "32px", fontWeight: 700, lineHeight: "40px" }}
            value={ideaHeaderText}
            onChange={handleHeaderInput}
            autoFocus={!ideaHeaderText}
            inputProps={{ maxLength: maxIdeaNameLength }}
          />
          <InputBase
            sx={{ fontSize: "16px", lineHeight: "24px", mb: "8px" }}
            fullWidth
            placeholder="Начните вводить описание идеи"
            multiline
            value={ideaContentText}
            inputProps={{ maxLength: maxIdeaDescriptionLength }}
            onChange={handleContentInput}
          />
          {selectedFiles?.length > 0 && (
            <PreviewFiles
              files={selectedFiles}
              setFiles={setSelectedFiles}
              type="upload"
            />
          )}
        </DialogWrapper>
      </>}
    </>
  );
};

export default CreateEditIdea;
import { SystemStyleObject, Theme } from '@mui/system';

import { designTypes, sizes } from './types';

const keys = [...designTypes, ...sizes, 'default'] as const;

type StylesType = Record<(typeof keys)[number], SystemStyleObject<Theme>>;

export const styles: StylesType = {
  default: { fontSize: '0.875rem', lineHeight: '1.25rem', fontWeight: '500' },
  primary: {
    color: '#E6EDF5',
    backgroundColor: '#1884FF',
    '&:hover': { backgroundColor: '#1A75DE' },
    '&:focus': { backgroundColor: '#0659B9' },
    '&:disabled': { color: '#CBE1FF2B', backgroundColor: '#B9D7FF14' },
  },
  secondary: {
    color: '#1884FF',
    backgroundColor: '#3392FF40',
    '&:hover': { backgroundColor: '#3392FF66' },
    '&:focus': { backgroundColor: '#3392FF40' },
    '&:disabled': { color: '#CBE1FF2B', backgroundColor: '#B9D7FF14' },
  },
  tonal: {
    color: '#E5EBF2A6',
    backgroundColor: '#CBE1FF2B',
    '&:hover': { backgroundColor: '#B9D7FF14' },
    '&:focus': { backgroundColor: '#CBE1FF2B' },
    '&:disabled': { color: '#CBE1FF2B', backgroundColor: '#B9D7FF14' },
  },
  text: {
    color: '#E5EBF2A6',
    backgroundColor: 'transparent',
    '&:hover': { backgroundColor: '#B9D7FF14' },
    '&:focus': { backgroundColor: '#CBE1FF2B' },
    '&:disabled': { color: '#CBE1FF2B', backgroundColor: '#B9D7FF14' },
  },
  '24px': {
    minHeight: '1.5rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    borderRadius: '1.5rem',
  },
  '32px': {
    minHeight: '2rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    paddingRight: '0.75rem',
    paddingLeft: '0.75rem',
    borderRadius: '2rem',
  },
  '40px': {
    minHeight: '2.5rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    borderRadius: '2.5rem',
  },
  '48px': {
    minHeight: '3rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
    borderRadius: '3rem',
  },
};

export const regEx = {
  isAlphaNumeric: /[^A-Za-z0-9]/,
  isCyrillic: /[^А-Яа-я-ёЁ]/,
  isEmail: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  isEmpty: /^\s*$/,
  isComplexPassword:
    /^(?=.*[A-Z])(?=.*[!=_\-+])(?=.*[0-9])(?=.*[a-z])(?!.*[ \n]).{8,}$/,
  isTelegram: /^@[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/,
  isPhoneNumber: /^\+7 \(\d{3}\) \d{3} \d{2} \d{2}/,
};

import React, { FC, useContext, useEffect } from "react";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { useDispatch } from "react-redux";

import { Box, Stack } from "@mui/material";

import CustomSelect from "../../../../common/Inputs/CustomSelect/CustomSelect";
import NameList from "./NameList/NameList";
import TagsPopover from "../../Tags/TagsPopover/TagsPopover";
import {
  AchievContext,
  TaskStatus,
} from "../../../AchievContextProvider/AchievContextProvider";
import SelectedTag from "../../Tags/SelectedTag/SelectedTag";
import { PersonView } from "../../../../../models/profile.models";
import ObserversPopover from "../../ObserversPopover/ObserversPopover";
import { translateTaskStatus } from "../../../../../translators/taskStatus.translate";
import {
  TaskTagView,
  TaskView,
} from "../../../../../models/achievement.models";
import { RecordModificationType } from "../../../../../types/RecordModification.type";
import { achievementController } from "../../../../../services/achievement.controller";
import { achievementsInitial } from "../../../../../actions/achievements";

interface StatusBarProps {
  type: RecordModificationType;
  task?: TaskView;
}

export const StatusBar: FC<StatusBarProps> = ({ type, task }) => {
  const dispatch = useDispatch();
  const isEdit = type === "edit";
  const loggedUser = useAppSelector((state) => state.profileReducer.profile);
  const achievement = useAppSelector(
    (state) => state.achievementsReducer.achievement
  );
  const {
    tagList,
    status,
    setStatus,
    setTagList,
    handleSnackbar,
    taskTable,
    setTaskTable,
    observerList,
    editableAcces,
    setIsChangeStatus,
  } = useContext(AchievContext);
  const statusItems = Object.values(taskTable.columns).map((column) => ({
    id: translateTaskStatus(column.title),
    name: column.title,
  }));

  useEffect(() => {
    if (isEdit) {
      setStatus(task?.status! as TaskStatus);
    }

    return () => {
      setStatus("PENDING");
    };
    /* eslint-disable-next-line */
  }, [task?.status]);

  const handleStatus = (props: any) => {
    setIsChangeStatus(true);
    if (isEdit) {
      const request = {
        sequence: null,
        status: props.target.value,
      };
      achievementController
        .moveTask(achievement.id, task?.id!, request)
        .then((res) => {
          dispatch(achievementsInitial.achievementTask(res.data));
        })
        .catch(() => handleSnackbar("error"));
    } else {
      setStatus(props.target.value);
    }
  };

  const userNames = (users: PersonView[] | PersonView) => {
    if (Array.isArray(users)) {
      return users.map((user) => `${user.name} ${user.surname}`);
    }
    return [`${users.name} ${users.surname}`];
  };

  const updateTask = () => {
    achievementController
      .getTask(achievement.id, task?.id!)
      .then((res) => {
        dispatch(achievementsInitial.achievementTask(res.data));
        const newState = {
          ...taskTable,
          tasks: { ...taskTable.tasks, [task?.id!]: res.data },
        };
        setTaskTable(newState);
      })
      .catch(() => handleSnackbar("error"));
  };

  const addTag = (item: TaskTagView) => {
    if (!isEdit) {
      setTagList((prev) => [...prev, item]);
    } else {
      achievementController
        .addTaskTag(achievement.id, task?.id!, { tagIds: [item.id] })
        .then(() => updateTask())
        .catch(() => handleSnackbar("error"));
    }
  };

  const deleteTag = (item: TaskTagView) => {
    if (!isEdit) {
      setTagList((prev) => prev.filter((tag) => tag.id !== item.id));
    } else {
      achievementController
        .deleteTaskTag(achievement.id, task?.id!, item.id)
        .then(() => updateTask())
        .catch(() => handleSnackbar("error"));
    }
  };

  const createTagList = tagList.length > 0 && (
    <Stack gap={1}>
      {tagList?.map((tag) => (
        <SelectedTag tag={tag} key={tag.id} handleDelete={deleteTag} />
      ))}
    </Stack>
  );

  const currentTagList = task?.tags && task?.tags.length > 0 && (
    <Stack gap={1}>
      {task?.tags?.map((tag) => (
        <SelectedTag tag={tag} key={tag.id} handleDelete={deleteTag} />
      ))}
    </Stack>
  );

  return (
    <Stack gap={3}>
      <CustomSelect
        items={statusItems}
        value={status}
        onChange={handleStatus}
      />
      {isEdit ? currentTagList : createTagList}
      {editableAcces && (
        <TagsPopover type={type} deleteTag={deleteTag} addTag={addTag} />
      )}
      <Box>
        <Stack gap={1}>
          <NameList
            title="Автор"
            names={userNames(isEdit ? task?.author : loggedUser)}
          />
          <NameList
            title="Наблюдатели"
            names={userNames(isEdit ? task?.observers! : observerList)}
          />
        </Stack>
      </Box>
      {editableAcces && <ObserversPopover type={type} />}
    </Stack>
  );
};

export default StatusBar;

import React, { FC, useState, useContext, useMemo } from "react";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";

import EditPopover from "../../common/Modals/EditPopover/EditPopover";
import CustomButton from "../../common/Buttons/CustomButton/CustomButton";
import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";

import { roles } from "../../../constants/role";
import { AppContext } from "../../shared/AppContextProvider";

import EventModalContent from "./EventModalContent/EventModalContent";
import { calendarController } from "../../../services/calendar.controller";
import type { CalendarEvent } from "../../../types/CalendarEvent.type";
import { calendarInitial } from "../../../actions/calendar";
import { CalendarContext } from "../CalendarContext/CalendarContextProvider";
import { useAppSelector } from '../../../hooks/useAppSelector';
import { CalendarEventType } from '../../../reducer/calendar';

interface CalendarEventCreatorProps {
  selectedDate: string;
}

export const CalendarEventCreator: FC<CalendarEventCreatorProps> = ({
  selectedDate,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [sendIsDisabled, setSendIsDisabled] = useState<boolean>(true);
  const dispatch = useDispatch();

  const eventTypes: CalendarEventType[] = useAppSelector(
    (state) => state.calendarReducer.eventTypes
  );

  const calendarEvent: CalendarEvent = useMemo(
    () => ({
      calendarEventType: "",
      name: "",
      description: "",
      date: selectedDate,
      startTime: "00:00",
      finishTime: "00:00",
      recurrence: 0,
    }),
    [selectedDate]
  );

  const { user } = useContext(AppContext);
  const notUserRole = user.role !== roles.user;

  const { onCalendarError: handleError } = useContext(CalendarContext);

  const handleModal = () => {
    setOpenModal((prev) => !prev);
  };
  const handleCancel = () => {
    calendarEvent.name = "";
    calendarEvent.description = "";
    calendarEvent.startTime = "00:00";
    calendarEvent.finishTime = "00:00";
    calendarEvent.recurrence = 0;
    setOpenModal(false);
    setSendIsDisabled(true);
  };
  const handleSend = () => {
    calendarController
      .addEvent(calendarEvent)
      .then(() => {
        calendarController
          .allEvents()
          .then((res) => {
            dispatch(calendarInitial.allEvents(res.data));
          })
          .catch(() => {
            handleError();
          });
      })
      .catch(() => {
        handleError();
      })
      .finally(() => {
        handleCancel();
      });
  };

  const modalButtons: React.ReactNode = (
    <ModalButtons
      nameButtonSend="Сохранить"
      handleSend={handleSend}
      handleCancel={handleCancel}
      justifyContent="end"
      disableSend={sendIsDisabled}
      fullWidth
    />
  );

  const wrapperButtonStyle = {
    position: "fixed",
    left: "50%",
    bottom: { mobile: "128px", laptop: "40px" },
    transform: "translateX(-55%)",
  };

  const button = (
    <CustomButton color="secondary" text="Создать" startIcon={<AddIcon />} />
  );

  const popoverActions = eventTypes.map((item) => ({
    name: item.fullNameEvent,
    action: () => {
      calendarEvent.calendarEventType = item.typeEvent;
      handleModal();
    },
  }));

  return (
    <>
      {notUserRole && (
        <>
          <Box sx={wrapperButtonStyle}>
            <EditPopover
              customButton={button}
			        buttonsData={popoverActions}
            />
          </Box>
          <DialogWrapper
            openModal={openModal}
            handleModal={handleModal}
            width="862px"
            contentDividers
            stylesContent={{ padding: "0px", borderTop: "none" }}
            buttons={modalButtons}
          >
            <EventModalContent
              calendarEvent={calendarEvent}
              setSendIsDisabled={setSendIsDisabled}
            />
          </DialogWrapper>
        </>
      )}
    </>
  );
};

export default CalendarEventCreator;
import React, { FC, useRef, SetStateAction, Dispatch } from "react";

import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";

import { reactionController } from "../../../../services/reaction.controller";

import type { ReactionView } from "../../../../models/reaction.model";

interface ReactionsButtonsProps {
  reactionId: string;
  reactions: ReactionView[];
  setReactions: Dispatch<SetStateAction<ReactionView[]>>;
  handleError: () => void;
}

export const ReactionsButtons: FC<ReactionsButtonsProps> = ({
  reactionId,
  reactions,
  setReactions,
  handleError,
}) => {
  const debounceTime = 500;
  const timeoutId = useRef<NodeJS.Timeout | undefined>();

  const handleReaction = (reaction: ReactionView) => {
    const reactionIndex = reactions.indexOf(reaction);
    let changedReactions = [...reactions];
    const pressed = !reaction.pressed;
    changedReactions[reactionIndex].pressed = pressed;
    clearTimeout(timeoutId.current);
    if (pressed) {
      changedReactions[reactionIndex].clickCount++;
      setReactions(changedReactions);
      timeoutId.current = setTimeout(() => {
        reactionController
          .reactions(reactionId)
          .then(res => {
            !res.data.items.find((bdReaction: ReactionView) => (
              bdReaction.smile.id === reaction.smile.id
            ))?.pressed && (
              reactionController
                .setReaction(reactionId, reaction.smile.id)
                .catch(() => {
                  handleError();
                })
            );
          })
          .catch(() => {
            handleError();
          });
      }, debounceTime);
    } else {
      const changedReaction = changedReactions[reactionIndex];
      changedReaction.clickCount--;
      if (changedReaction.clickCount === 0) {
        changedReactions = changedReactions.toSpliced(reactionIndex, 1);
      }

      setReactions(changedReactions);
      timeoutId.current = setTimeout(() => {
        reactionController
          .reactions(reactionId)
          .then(res => {
            res.data.items.some((bdReaction: ReactionView) => (
              bdReaction.smile.id === reaction.smile.id && bdReaction.pressed
            )) && (
              reactionController
                .deleteReaction(reactionId, reaction.smile.id)
                .catch(() => {
                  handleError();
                })
            );
          })
          .catch(() => {
            handleError();
          });
      }, debounceTime);
    }
  };

  const reactionButtonStyle = {
    backgroundColor: "rgba(203, 225, 255, 0.17)",
    color: "rgba(229, 235, 242, 0.65)",
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "rgba(24, 132, 255, 1)",
    },
    height: "32px",
    borderRadius: "60px",
    border: "none",
    padding: "12px",
    gap: "8px",
  };

  const buttonTextStyle = {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    minWidth: "20px",
  };

  return (
    <Box display="flex" justifyContent="start" flexWrap="wrap" gap="8px" mr="8px">
      {reactions.map(reaction => (
        <ToggleButton
          key={reaction.smile.id}
          sx={reactionButtonStyle}
          onChange={() => handleReaction(reaction)}
          value="emoji"
          selected={reaction.pressed}
        >
          {reaction.smile.symbol}
          <Typography {...buttonTextStyle}>{reaction.clickCount}</Typography>
        </ToggleButton>
      ))}
    </Box>
  );
};
export default ReactionsButtons;
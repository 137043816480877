import React, { FC, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";
import InputWrapper from "../../common/Inputs/InputWrapper/InputWrapper";
import CustomSelect from "../../common/Inputs/CustomSelect/CustomSelect";
import { useKbSections } from "../../../hooks/useKbSections";
import { SelectChangeEvent } from "@mui/material/Select";

import { Typography } from "@mui/material";
import { kbController } from "../../../services/kb.controller";
import { KbContext } from "../KbContextProvider/KbContextProvider";
import { ArticleMainData } from "../../../types/ArticleMainData.types";

interface SectionSelectionModalProps {
  open: boolean;
  handleModal: () => void;
  handleEditorModal?: () => void;
  type: "create" | "move";
}

const pageSize = 10;

export const SectionSelectionModal: FC<SectionSelectionModalProps> = ({
  open,
  handleModal,
  handleEditorModal,
  type,
}) => {
  const navigate = useNavigate();
  const isMove = type === "move";
  const { handleSnackbar } = useContext(KbContext);
  const { sections, setCurrentPage, totalItems, loading } = useKbSections();
  const [section, setSection] = useState<string>("");

  const handleSection = (event: SelectChangeEvent) => {
    setSection(event?.target?.value);
  };

  const getSectionId = (name: string) => {
    return sections.find((item) => item.name === name)?.id;
  };

  const closeModal = () => {
    setSection("");
    handleModal();
  };

  const createArticleService = (id: string) => {
    const formData = new FormData();
    formData.append(
      "request",
      new Blob(
        [
          JSON.stringify({
            content: "",
            name: "",
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    kbController
      .createArticle(id, formData)
      .then((res) => {
        localStorage.setItem(
          "article",
          JSON.stringify({
            articleId: res.data.id,
            sectionId: res.data.sectionId,
          })
        );
        handleEditorModal?.();
      })
      .catch(() => {
        handleSnackbar("error");
      })
      .finally(() => closeModal());
  };

  const moveArticleService = (id: string) => {
    const articleMainData: ArticleMainData = JSON.parse(
      localStorage.getItem("article") || "{}"
    );
    kbController
      .moveArticle(articleMainData.sectionId, articleMainData.articleId, {
        kbSectionId: id,
      })
      .then(() => {
        navigate(-1);
        handleSnackbar("success", `Статья была перемещена в ${section}`);
      })
      .catch(() => {
        handleSnackbar("error");
      })
      .finally(() => closeModal());
  };

  const handleSaveButton = () => {
    const id = getSectionId(section);
    if (id) {
      if (isMove) {
        moveArticleService(id);
      } else {
        createArticleService(id);
      }
    } else {
      handleSnackbar("error");
    }
  };

  const createModalButtons = (
    <ModalButtons
      handleCancel={closeModal}
      handleSend={handleSaveButton}
      fullWidth
      nameButtonSend={isMove ? "Переместить" : "Далее"}
      justifyContent="flex-end"
      disableSend={!section}
    />
  );

  // +1 - it's test, for some users, scrolling does not work because 1px is lost
  const isScrolledToBottom = (event: any) => {
    const target = event.target;
    return target.scrollTop + target.clientHeight + 1 >= target.scrollHeight;
  };

  const handleSectionsScroll = (event: any) => {
    if (
      isScrolledToBottom(event)
      && sections.length < totalItems
      && !loading
    ) {
      setCurrentPage(sections.length / pageSize);
    }
  }

  return (
    <>
      <DialogWrapper
        openModal={open}
        handleModal={closeModal}
        buttons={createModalButtons}
        contentDividers
        width="450px"
      >
        <Typography variant="h4" mb={3}>
          {isMove ? "Переместить статью" : "Создать статью"}
        </Typography>
        <InputWrapper
          flexDirection="column"
          title="Выберите раздел"
          element={
            <CustomSelect
              items={sections.map((item) => item.name)}
              value={section}
              onChange={handleSection}
              onScroll={handleSectionsScroll}
              height="400px"
            />
          }
        />
      </DialogWrapper>
    </>
  );
};

export default SectionSelectionModal;

import React, { FC, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Paper, Card, CardHeader, Collapse, Typography } from "@mui/material";
import ProgressSlider from "./ProgressSlider/ProgressSlider";
import { AchievementView } from "../../../models/achievement.models";
import { CardActions } from "./CardActions/CardActions";
import DoneContent from "./DoneContent/DoneContent";
import { TaskTable } from "../Tasks/TaskTable/TaskTable";
import { achievementsInitial } from "../../../actions/achievements";
import { AchievContext } from "../AchievContextProvider/AchievContextProvider";
import { achievementController } from "../../../services/achievement.controller";
import { initialTaskTable } from "../Tasks/TaskTable/initialTaskTable";
import { AppContext } from "../../shared/AppContextProvider";
import { useAppSelector } from "../../../hooks/useAppSelector";

interface AchievCardProps {
  achivementData: AchievementView;
}
export const AchievCard: FC<AchievCardProps> = ({ achivementData }) => {
  const dispatch = useDispatch();
  const { id, countOfDoneTasks, name, tasksSize, status } = achivementData;
  const { setTaskTable, handleSnackbar, handleTasks, setSelectedDot } =
    useContext(AchievContext);
  const { isMobile } = useContext(AppContext);

  const [expanded, setExpanded] = useState<boolean>(false);

  const expandedAchiv = useAppSelector(
    (state) => state.achievementsReducer.achievement
  );

  // only 1 achivement card can be open at a time
  const isExpanded = id === expandedAchiv.id;
  useEffect(() => {
    if (!isExpanded) {
      setExpanded(false);
      setTaskTable(initialTaskTable);
    }
    /* eslint-disable-next-line */
  }, [isExpanded]);

  const handleExpanded = () => {
    setExpanded(!expanded);

    if (expanded) {
      setTaskTable(initialTaskTable);
    } else {
      sessionStorage.setItem("achievementId", id);
      dispatch(achievementsInitial.achievement(achivementData));
      achievementController
        .tasks(id)
        .then((res) => handleTasks(res.data.items))
        .catch(() => handleSnackbar("error"));
    }
  };

  const stylePaper = {
    p: 3,
    borderRadius: "12px",
    boxShadow: "none",
  };

  const styleCard = {
    backgroundColor: "unset",
    boxShadow: "none",
  };

  const styleTitle = {
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  };

  const styleCardHeader = { p: 0, "& .MuiCardHeader-action": { m: 0 } };

  const allTasksCompleted = countOfDoneTasks === tasksSize;

  //for table slides
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const handleTouchStart = (e: any) => {
    setTouchStart(e.targetTouches[0].clientX);
  };
  const handleTouchMove = (e: any) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };
  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 150) {
      console.log("right");
      setSelectedDot((prev) => {
        if (prev === 3) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }

    if (touchStart - touchEnd < -150) {
      console.log("left");
      setSelectedDot((prev) => {
        if (prev === 0) {
          return 3;
        } else {
          return prev - 1;
        }
      });
    }
  };

  return (
    <Paper sx={stylePaper} key={id}>
      <Card sx={styleCard}>
        <CardHeader
          title={
            <Typography sx={styleTitle} variant="subtitle1">
              {name}
            </Typography>
          }
          action={
            <CardActions
              achivementId={id}
              expanded={expanded}
              handleExpanded={handleExpanded}
              status={status}
              name={name}
              allTasksCompleted={allTasksCompleted}
            />
          }
          sx={styleCardHeader}
        />
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
          onTouchStart={isMobile ? handleTouchStart : undefined}
          onTouchMove={isMobile ? handleTouchMove : undefined}
          onTouchEnd={isMobile ? handleTouchEnd : undefined}
          sx={{
            position: "relative",
          }}
          collapsedSize={isMobile && expanded ? "415px" : undefined}
        >
          <ProgressSlider />
          {status === "DONE" ? <DoneContent /> : <TaskTable />}
        </Collapse>
      </Card>
    </Paper>
  );
};

export default AchievCard;

import React from 'react';

import { Typography, TypographyProps } from '@mui/material';

interface ErrorMessageProps extends TypographyProps {
  maxTextLength: number;
}

export const ErrorMessage = ({maxTextLength, sx,...rest}: ErrorMessageProps): JSX.Element => (
  <Typography
    color="error"
    variant="caption"
    sx={{ padding: '0.5rem 3rem', fontSize: '0.825rem', ...sx }}
    {...rest}
  >
    {`Достигнута максимальная длина (${maxTextLength} символов)`}
  </Typography>
);

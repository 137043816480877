import React from 'react';

import { MenuItem, MenuList, MenuListProps } from '@mui/material';

import { PersonView } from '../../../../models/profile.models';
import MemberItem from '../../../layout/RightSidebar/Members/MemberItem/MemberItem';

interface PersonListProps extends MenuListProps {
  unselected: PersonView[];
  handleChose: (person: PersonView) => void;
  notShowIds: string[];
}

export const PersonList = ({
  unselected,
  handleChose,
  notShowIds,
  ...rest
}: PersonListProps): JSX.Element => {
  const { children, ...menuListRest } = rest;
  const notShowDictionary = new Map<string, { id: string }>(notShowIds.map((id) => [id, { id }]));

  return (
    <MenuList {...menuListRest}>
      {unselected.map((item) => {
        if (notShowDictionary.has(item.id)) return null;
        return (
          <MenuItem key={item.id} onClick={() => handleChose(item)}>
            <MemberItem withPopover={true} userInfo={item} onClick={() => {}} />
          </MenuItem>
        );
      })}
      {children}
    </MenuList>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes rowLoadingEffect {
    from {
        background-position: 200% 0;
    }
    to {
        background-position: 0 0;
    }
}

.MuiDataGrid-root .row-action {
    background: linear-gradient(
            90deg,
            rgba(68, 67, 67, 0.13) 25%,
            rgba(100, 149, 237, 0.6) 50%,
            rgba(68, 67, 67, 0.14) 75%
    );
    background-size: 200% 100%;
    animation: rowLoadingEffect 1s linear infinite;
    opacity: 0.8;
    pointer-events: none;
    transition: opacity 0.8s ease, background 0.3s ease;
}`, "",{"version":3,"sources":["webpack://./src/components/Review/ReviewPage/reviewPage.css"],"names":[],"mappings":"AAAA;IACI;QACI,2BAA2B;IAC/B;IACA;QACI,wBAAwB;IAC5B;AACJ;;AAEA;IACI;;;;;KAKC;IACD,0BAA0B;IAC1B,8CAA8C;IAC9C,YAAY;IACZ,oBAAoB;IACpB,mDAAmD;AACvD","sourcesContent":["@keyframes rowLoadingEffect {\n    from {\n        background-position: 200% 0;\n    }\n    to {\n        background-position: 0 0;\n    }\n}\n\n.MuiDataGrid-root .row-action {\n    background: linear-gradient(\n            90deg,\n            rgba(68, 67, 67, 0.13) 25%,\n            rgba(100, 149, 237, 0.6) 50%,\n            rgba(68, 67, 67, 0.14) 75%\n    );\n    background-size: 200% 100%;\n    animation: rowLoadingEffect 1s linear infinite;\n    opacity: 0.8;\n    pointer-events: none;\n    transition: opacity 0.8s ease, background 0.3s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

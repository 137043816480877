import React, { FC, SetStateAction } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";

import DialogWrapper from "../../Modals/DialogWrapper/DialogWrapper";
import PreviewFiles from "../../Attachments/PreviewFiles/PreviewFiles";
import InputFilled from "../../Inputs/InputFilled/InputFilled";
import ModalButtons from "../../Buttons/ModalButtons/ModalButtons";
import AttachmentButton from "../../Buttons/AttachmentButton/AttachmentButton";

interface AttachmentModalProps {
  openModal: boolean;
  handleModal: () => void;
  files: any[];
  setFiles: SetStateAction<any>;
  textMessage: string;
  setTextMessage: SetStateAction<any>;
  handleSend: (props?: () => void) => void;
  disableSend: boolean;
  handleAttache: (event: any) => void;
  errorMessage?: JSX.Element
}

export const AttachmentModal: FC<AttachmentModalProps> = ({
  openModal,
  handleModal,
  files,
  setFiles,
  textMessage,
  setTextMessage,
  handleSend,
  disableSend,
  handleAttache,
  errorMessage
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const handleCancel = () => {
    handleModal();
    setFiles([]);
    setTextMessage("");
  };

  const handleSendButton = () => {
    handleSend(handleModal);
  };

  const modalButtons: React.ReactNode = (
    <ModalButtons
      nameButtonSend="Применить"
      handleSend={handleSendButton}
      handleCancel={handleCancel}
      justifyContent="end"
      fullWidth
      disableSend={disableSend}
    />
  );

  const modalStyles = isMobile
    ? {
        height: "50%",
        top: "auto",
      }
    : undefined;

  return (
    <DialogWrapper
      openModal={openModal}
      handleModal={handleModal}
      width="532px"
      buttons={modalButtons}
      contentDividers
      styles={modalStyles}
    >
      <Stack spacing={3}>
        {files?.length > 0 && (
          <PreviewFiles files={files} setFiles={setFiles} type="upload" />
        )}
        <InputFilled
          placeholder="Ваш комментарий"
          value={textMessage}
          handleValue={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTextMessage(event.target.value);
          }}
          multiline
        />
        <Box sx={{display: 'flex', alignItems: "center", marginTop: '1.5rem'}}>
          <AttachmentButton onChange={handleAttache} withBackground />
          {errorMessage}
        </Box>
      </Stack>
    </DialogWrapper>
  );
};

export default AttachmentModal;

import React, { FC, useEffect, useRef, useState } from 'react';

import { Button, MenuItem, Stack, StackProps } from '@mui/material';

import { PersonView } from '../../../../models/profile.models';
import { sanitizeAndTrimStart } from '../../../../operation/sanitizeAndTrimStart';
import { IFetchPersonsParams, useFetchPersonsQuery } from '../../../../services/person.controller';
import { LoadProgress } from '../../../common/LoadProgress/LoadProgress';
import Search from '../../../common/Search/Search';
import { getSearchString } from '../../helpers';
import { ISearchOptions } from '../../types';
import { PersonList } from '../PersonList/PersonList';
import { SelectedList } from '../SelectedList/SelectedList';
import { styles } from './styles';

interface IItem extends PersonView {
  isSelected?: boolean;
}

interface PersonListFromFetchProps extends StackProps {
  getUsers: (users: PersonView[]) => void;
  selected: PersonView[];
  notShowIds: string[];
  searchProps?: ISearchOptions;
  hideChosen: boolean;
}

interface TotalItems {
  totalItems: number;
  isEndOfList: boolean;
}

const sizeIncrement: number = 10;

const defaultSearchProps: ISearchOptions = {
  isShowBloked: false,
  fields: ['name', 'surname'],
  isShowYourself: false,
};

export const PersonListFromFetch: FC<PersonListFromFetchProps> = ({
  getUsers,
  selected,
  searchProps = defaultSearchProps,
  notShowIds,
  hideChosen,
  ...rest
}) => {
  const [usersState, setUsersState] = useState<Map<string, IItem>>(
    new Map(selected.map((item) => [item.id, { ...item, isSelected: true }]))
  );
  const [params, setParams] = useState<IFetchPersonsParams>(() => {
    const newState: IFetchPersonsParams = { size: sizeIncrement };
    const search = getSearchString('', undefined, searchProps);
    if (search) newState.search = search;
    return newState;
  });
  const [search, setSearch] = useState<string>('');
  const { data, isFetching } = useFetchPersonsQuery(params);
  const usersArray = Array.from(usersState.values());
  const unselected = usersArray.filter(({ isSelected }) => !isSelected);
  const refItemsCounter = useRef<TotalItems>({ totalItems: 0, isEndOfList: false });

  useEffect(() => {
    if (!data) return;
    setUsersState((prev) => {
      const newState = new Map(prev);
      data.items.forEach((user) => {
        if (!newState.has(user.id)) newState.set(user.id, { ...user, isSelected: false });
      });
      return newState;
    });
    const newRef: TotalItems = {
      totalItems: data.totalItems,
      isEndOfList: data.items.length === refItemsCounter.current.totalItems,
    };
    refItemsCounter.current = newRef;
  }, [data]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    const sanitizedValue = sanitizeAndTrimStart(value);
    setParams((prev) => {
      const newState = { ...prev };
      const search = getSearchString(sanitizedValue, undefined, searchProps);
      if (search) newState.search = search;
      return newState;
    });
  };

  const handleChose = (person: PersonView) => {
    const newState = new Map(usersState);
    newState.set(person.id, { ...person, isSelected: true });
    setUsersState(newState);
    getUsers(
      [...newState.values()]
        .filter(({ isSelected }) => isSelected)
        .map((item) => {
          const newItem = { ...item };
          delete newItem.isSelected;
          return newItem;
        })
    );
  };

  const handleUnchose = (id: string) =>
    setUsersState((prev) => {
      const newState = new Map(prev);
      const deletePerson = newState.get(id);
      if (deletePerson) delete deletePerson.isSelected;
      getUsers(
        [...newState.values()]
          .filter(({ isSelected }) => isSelected)
          .map((item) => {
            const newItem = { ...item };
            delete newItem.isSelected;
            return newItem;
          })
      );
      return newState;
    });

  return (
    <Stack {...rest} gap={2}>
      <Search placeholder={'Поиск'} value={search} handleChange={handleSearch} />
      {!hideChosen && (
        <SelectedList
          selected={usersArray.filter(({ isSelected }) => isSelected)}
          handleUnchose={handleUnchose}
        />
      )}
      <PersonList unselected={unselected} handleChose={handleChose} notShowIds={notShowIds}>
        {isFetching ? (
          <LoadProgress />
        ) : (
          !refItemsCounter.current.isEndOfList && (
            <MenuItem sx={styles.itemButtonMore}>
              <Button
                sx={styles.buttonMore}
                onClick={() =>
                  setParams((prev) => ({
                    ...prev,
                    size: prev.size ? prev.size + sizeIncrement : sizeIncrement,
                  }))
                }
              >
                Показать еще...
              </Button>
            </MenuItem>
          )
        )}
      </PersonList>
    </Stack>
  );
};

import React, { FC, useContext, useState } from "react";
import { useDispatch } from "react-redux";

import { Box, Grid, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import CloseButton from "../../../../CloseButton/CloseButton";
import InputWrapper from "../../../../../common/Inputs/InputWrapper/InputWrapper";
import InputLine from "../../../../../common/Inputs/InputLine/InputLine";
import CustomButton from "../../../../../common/Buttons/CustomButton/CustomButton";
import { achievementController } from "../../../../../../services/achievement.controller";
import { CreateTaskTagRequest } from "../../../../../../models/achievement.models";
import { AchievContext } from "../../../../AchievContextProvider/AchievContextProvider";
import { achievementsInitial } from "../../../../../../actions/achievements";

interface EditableContentProps {
  closePopover: () => void;
  type: "create" | "edit";
}

const maxTagNameLength = 100;

export const EditableContent: FC<EditableContentProps> = ({
  closePopover,
  type,
}) => {
  const achievementId = sessionStorage.getItem("achievementId") || "";
  const isCreate = type === "create";
  const { tag, setTag, handleSnackbar, setTagList } = useContext(AchievContext);
  const dispatch = useDispatch();

  const [name, setName] = useState<string>(isCreate ? "" : tag?.name!);
  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const colors = ["#AE193D", "#B5651A", "#258176", "#4E59C2"];
  const [color, setColor] = useState<string>(isCreate ? "" : tag?.color!);
  const handleColor = (boxColor: string) => {
    if (boxColor === color) {
      setColor("");
      return;
    }
    setColor(boxColor);
  };
  const disableSend = !name || !color;

  const handleClose = () => {
    closePopover();
    setName("");
    setColor("");
    setTag(null);
  };

  const updTags = () => {
    achievementController
      .getTags(achievementId)
      .then((res) => {
        dispatch(achievementsInitial.achievementTags(res.data.items));
        handleClose();
      })
      .catch(() => handleSnackbar("error"));
  };

  const serviceCreate = (body: CreateTaskTagRequest) => {
    achievementController
      .newTag(achievementId, body)
      .then(() => updTags())
      .catch(() => handleSnackbar("error"));
  };
  const serviceEdit = (body: CreateTaskTagRequest) => {
    achievementController
      .editTag(achievementId, tag?.id!, body)
      .then(() => updTags())
      .catch(() => handleSnackbar("error"));
  };
  const serviceDelete = () => {
    achievementController
      .deleteTag(achievementId, tag?.id!)
      .then(() => {
        updTags();
        setTagList((prev) => prev.filter((item) => item.id !== tag?.id));
      })
      .catch(() => handleSnackbar("error"));
  };

  const handleSave = () => {
    const body = {
      color: color,
      name: name,
    };
    if (isCreate) {
      serviceCreate(body);
    } else {
      serviceEdit(body);
    }
  };

  const styleBoxColor = (item: string) => {
    return {
      backgroundColor: item,
      borderRadius: "8px",
      padding: "12px",
      height: "32px",
      "&:hover": {
        outline:
          item === color
            ? "2px solid #1884FF"
            : "2px solid rgba(51, 146, 255, 0.25)",
      },
      outline: item === color ? "2px solid #1884FF" : undefined,
      cursor: "pointer",
    };
  };

  return (
    <Stack gap={3}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <IconButton size="small" color="secondary" onClick={handleClose}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="subtitle2">Метки</Typography>
        <CloseButton handleClose={handleClose} />
      </Stack>
      <InputWrapper
        title="Название"
        titleColor="secondary"
        flexDirection="column"
        styles={{ gap: 0 }}
        element={(
          <InputLine
            value={name}
            onChange={handleName}
            inputProps={{ maxLength: maxTagNameLength }}
          />)}
      />
      <Box>
        <Typography
          variant="body1"
          mb={1}
          color={(theme) => theme.palette.text.secondary}
        >
          Цвет
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={1}>
          {colors.map((item, index) => (
            <Grid item mobile={6} key={index}>
              <Box sx={styleBoxColor(item)} onClick={() => handleColor(item)} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box>
        <CustomButton
          color="primary"
          text="Сохранить"
          styles={{ mr: 1 }}
          disabled={disableSend}
          onClick={handleSave}
        />
        <CustomButton
          color="secondary"
          text={isCreate ? "Отмена" : "Удалить"}
          onClick={isCreate ? handleClose : serviceDelete}
        />
      </Box>
    </Stack>
  );
};

export default EditableContent;

import React, { FC, SetStateAction, Dispatch } from 'react';
import moment from 'moment';

import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import InputWrapper from '../../common/Inputs/InputWrapper/InputWrapper';
import InputLine from '../../common/Inputs/InputLine/InputLine';
import InputFilled from '../../common/Inputs/InputFilled/InputFilled';
import EditAvatar from './EditAvatar/EditAvatar';
import DatePickerCustom from '../../common/Inputs/DatePickerCustom/DatePickerCustom';
import { UserInfo, UserInfoValidation } from '../../../types/UserInfo.types';
import { checkLanguage } from '../../../constants/errorText';
import { roles } from '../../../constants/role';
import Gender from '../../common/Gender/Gender';
import CityCombobox from './CityCombobox/CityCombobox';
import Job from './Job/Job';

interface PersonalInfoProps {
  personData: UserInfo;
  setPersonData: Dispatch<SetStateAction<UserInfo>>;
  newAvatar: File | null;
  setNewAvatar: Dispatch<SetStateAction<File | null>>;
  validation: UserInfoValidation;
}

export const maxUserNameLength = 70;
export const maxUserSurnameLength = 70;
export const maxUserPatronymicLength = 70;

export const PersonalInfo: FC<PersonalInfoProps> = ({
  personData,
  setPersonData,
  newAvatar,
  setNewAvatar,
  validation,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('laptop'));

  const currentUserRole = JSON.parse(
    localStorage.getItem('REACT_TOKEN_AUTH_KEY') || '{}',
  ).role;

  const handleChangeInputText =
    (prop: keyof UserInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPersonData({ ...personData, [prop]: event.target.value });
    };

  const handleChangeDate = (newValue?: string | null) => {
    const newDate = moment(newValue, 'DD.MM.YYYY');
    setPersonData({
      ...personData,
      dob: newDate.format('YYYY-MM-DD'),
    });
  };

  const minDate = moment().subtract(14, 'years').toDate();

  const disableEditInfo = currentUserRole === roles.user;
  const data = [
    {
      name: 'Аватар',
      element: <EditAvatar newAvatar={newAvatar} setNewAvatar={setNewAvatar} />,
    },
    {
      name: 'Фамилия',
      element: (
        <InputLine
          testid="surname"
          value={personData?.surname}
          onChange={handleChangeInputText('surname')}
          error={validation.surname !== true}
          helperText={checkLanguage}
          disabled={disableEditInfo}
          inputProps={{ maxLength: maxUserSurnameLength }}
        />
      ),
    },
    {
      name: 'Имя',
      element: (
        <InputLine
          testid="name"
          value={personData?.name}
          onChange={handleChangeInputText('name')}
          error={validation.name !== true}
          helperText={checkLanguage}
          disabled={disableEditInfo}
          inputProps={{ maxLength: maxUserNameLength }}
        />
      ),
    },
    {
      name: 'Отчество',
      element: (
        <InputLine
          testid="patronymic"
          value={personData?.patronymic}
          onChange={handleChangeInputText('patronymic')}
          error={validation.patronymic !== true}
          helperText={checkLanguage}
          disabled={disableEditInfo}
          inputProps={{ maxLength: maxUserPatronymicLength }}
        />
      ),
    },
    {
      name: 'Должность и Департамент',
      element: (
        <Job
          personData={personData}
          setPersonData={setPersonData}
          disabled={disableEditInfo}
          validation={validation}
        />
      ),
    },
    {
      name: 'Дата рождения',
      element: (
        <DatePickerCustom
          value={personData?.dob}
          styles={{
            width: isMobile ? '100%' : 'calc(50% - 6px)',
            pr: '8px',
          }}
          minDate={minDate}
          helperText={(typeof validation.dob === 'string') ? validation.dob : undefined}
          onChange={handleChangeDate}
        />
      ),
    },
    {
      name: 'Пол',
      element: (
        <Gender
          personData={personData}
          setPersonData={setPersonData}
          disabled={disableEditInfo}
        />
      ),
    },
    {
      name: 'Город',
      element: (
        <CityCombobox
          personData={personData}
          setPersonData={setPersonData}
          disabled={disableEditInfo}
          width={isMobile ? '100%' : '48%'}
        />
      ),
    },
    {
      name: 'О себе',
      element: (
        <InputFilled
          testid="description"
          multiline
          value={personData?.description}
          handleValue={handleChangeInputText('description')}
        />
      ),
    },
  ];
  return (
    <Stack spacing={4}>
      {data.map((item, index) => (
        <InputWrapper key={index} title={item.name} element={item.element} />
      ))}
    </Stack>
  );
};

export default PersonalInfo;

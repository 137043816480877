import React, { FC } from "react";

import Popover from "@mui/material/Popover";

import { PersonView } from "../../../../../models/profile.models";
import { LoggedPopoverCard } from "./Content/LoggedPopoverCard";

interface LoggedPopoverProps {
  userInfo: PersonView;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

export const LoggedPopover: FC<LoggedPopoverProps> = ({ userInfo, anchorEl, handleClose }) => {
  const isOpen = Boolean(anchorEl);

  return (
    <Popover
      data-testid="loggedUserPopover"
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{ sx: { borderRadius: "12px" } }}
    >
      <LoggedPopoverCard userInfo={userInfo} closePopover={handleClose} />
    </Popover>
  );
};

export default LoggedPopover;

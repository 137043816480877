import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import SvgIcon from "@mui/material/SvgIcon";

import { ReactComponent as GrafanaIcon } from "../../../../assets/icons/services/grafana.svg";
import { ReactComponent as JiraIcon } from "../../../../assets/icons/services/jira.svg";
import { ReactComponent as GitLabIcon } from "../../../../assets/icons/services/gitlab.svg";
import { ReactComponent as TestLabIcon } from "../../../../assets/icons/services/testlab.svg";
import { ReactComponent as HuntLabIcon } from "../../../../assets/icons/services/huntlab.svg";
import { ReactComponent as ServicesIcon } from "../../../../assets/icons/services.svg";

import MenuList from "../../../common/MenuList/MenuList";

const servicesStyle = {
  "&:before": {
    display: "none",
  },
  ":first-of-type": {
    borderRadius: "24px",
  },
  borderRadius: "24px",
  marginBottom: "24px",
};

const iconStyle = {
  minWidth: "auto",
  marginRight: "20px",
};

const iconProps = {
  sx: {
    width: "32px",
    height: "32px",
  },
  viewBox: "0 0 32 32",
};

const servicesMenu = [
  {
    name: "Test Lab",
    icon: <SvgIcon component={TestLabIcon} {...iconProps} />,
    href: "https://testlab-new.loylabs.ru",
  },
  {
    name: "Hunt Lab",
    icon: <SvgIcon component={HuntLabIcon} {...iconProps} />,
    href: "https://huntlab-test.loylabs.ru",
  },
  {
    name: "Grafana",
    icon: <SvgIcon component={GrafanaIcon} {...iconProps} />,
    href: "http://90.156.206.55:3000",
  },
  {
    name: "Jira",
    icon: <SvgIcon component={JiraIcon} {...iconProps} />,
    href: "https://loylabs-jira.cp12.ru",
  },
  {
    name: "GitLab",
    icon: <SvgIcon component={GitLabIcon} {...iconProps} />,
    href: "https://code.dev.loylabs.ru",
  },
];

const ServicesList = () => (
  <Accordion sx={servicesStyle}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon color="secondary" />}
      aria-controls="services-menu-content"
      id="services-menu-header"
    >
      <ListItemIcon sx={iconStyle}>
        <SvgIcon component={ServicesIcon} {...iconProps} />
      </ListItemIcon>
      <ListItemText
        secondary="Сервисы"
        secondaryTypographyProps={{
          variant: "button",
        }}
      />
    </AccordionSummary>
    <AccordionDetails>
      <MenuList items={servicesMenu} />
    </AccordionDetails>
  </Accordion>
);

export default ServicesList;

import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import UserHeader from './UserHeader/UserHeader';
import MainInfo, { TMainInfoData } from './MainInfo/MainInfo';
import Contacts from './Contacts/Contacts';
import PersonalInfo from './PersonalInfo/PersonalInfo';
import Roles from '../common/Roles/Roles';
import BlockUserButton from './BlockUserButton/BlockUserButton';
import { PersonView } from '../../models/profile.models';
import { IPersonView, ITagView, UserInfo, UserInfoValidation } from '../../types/UserInfo.types';
import BottomActions from '../common/BottomActions/BottomActions';
import ModalButtons from '../common/Buttons/ModalButtons/ModalButtons';
import { personController } from '../../services/person.controller';
import { profilesInitial } from '../../actions/profiles';
import Snackbars from '../common/Snackbars/Snackbars';
import { roles } from '../../constants/role';
import { companyInitialState } from '../Company/CompanyContext/CompanyContextProvider';
import { textSnackbars } from '../../constants/snackBarNotifications';
import { defaultValidation, validateUserForm } from './validation';
import { ListItem } from '../../types/ListItem.js';
import Career from './Career/Career';
import AboutMe from './AboutMe/AboutMe';
import { unformatPhone } from '../../helpers/telephoneFormat';
import { tagController } from '../../services/tag.controller';

export interface BackendError {
  errorCode: number;
  errorMessage: string;
  status?: string;
}


export const UserEditing = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userId = localStorage.getItem("userId") || "";
  const currentUser = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  );

  const isUserRole = currentUser.role === roles.user;
  const isEditorRole = currentUser.role === roles.editor;
  const isAdminRole = currentUser.role === roles.admin;
  const isCurrentUser = userId === currentUser.id;

  const [snackbarMessage, setSnackbarMessage] = useState<string>(textSnackbars.default);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const [srcUserData, setSrcUserData] = useState<IPersonView | null>(null);

  const [editData, setEditData] = useState<UserInfo>({
    id: "",
    name: "",
    surname: "",
    patronymic: "",
    blocked: false,
    job: null,
    gender: "",
    dob: "",
    hired: "",
    description: "",
    email: "",
    phone: "",
    telegram: "",
    city: { id: "", name: "" },
    department: null,
    role: "",
    projects: null,
    tags: null,
    communities: null,
    fired: null
  });

  const [newAvatar, setNewAvatar] = useState<File | null>(null);
  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setNewAvatar(file);
  };
  const [validation, setValidation] = useState<UserInfoValidation>(defaultValidation);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!isAdminRole && !isEditorRole && !isCurrentUser) {
      navigate(`/profile/${userId}`, { replace: true });
      return;
    }

    personController.person(userId).then((res) => {
      dispatch(profilesInitial.editProfile(res.data));
      setSrcUserData(res.data)
      setEditData({
        id: res.data.id ?? "",
        name: res.data.name ?? "",
        surname: res.data.surname ?? "",
        patronymic: res.data.patronymic ?? "",
        blocked: res.data.blocked ?? false,
        job: res.data.job?.title as ListItem ?? null,
        gender: res.data.gender ?? "",
        dob: res.data.dob ?? "",
        hired: res.data.hired ?? "",
        description: res.data.description ?? "",
        email: res.data.contact?.email ?? "",
        phone: unformatPhone(res.data.contact?.phone) ?? "",
        telegram: res.data.contact?.telegram ?? "",
        city: res.data.city ?? { id: "", name: "" },
        department: res.data.job?.department as ListItem ?? null,
        role: res.data.role.role ?? "",
        projects: res.data.projects ?? null,
        tags: res.data.tags ?? null,
        communities: res.data.communities ?? null,
        fired: res.data.fired,
      });
      setIsLoaded(true);
    });
    /* eslint-disable */
  }, [userId]);
  const userData: PersonView = useAppSelector(
    (state) => state.profileReducer.editProfile
  );

  // Form validation
  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    const validationResult = validateUserForm(editData);
    setValidation(validationResult);
    const isValidForm = Object.values(validationResult).every((item) => item === true);
    setIsSaveDisabled(!isValidForm);
  }, [editData, isLoaded]);

  const additionalMainInfoData: TMainInfoData[] = [{
    name: 'Права доступа',
    element: <Roles
      personData={editData}
      setPersonData={setEditData}
      disabled={!isAdminRole || isCurrentUser}
    />
  }]

  const profileData = [
    {
      title: "Основная информация",
      data: <MainInfo userId={userData.id} additionalData={additionalMainInfoData} />,
    },
    {
      title: "Личные данные",
      data: (
        <PersonalInfo
          personData={editData}
          setPersonData={setEditData}
          newAvatar={newAvatar}
          setNewAvatar={handleAvatarChange}
          validation={validation}
          bgInfo={userData.background}
        />
      ),
    },
    {
      title: "Карьера",
      data: (
        <Career
          personData={editData}
          setPersonData={setEditData}
          newAvatar={newAvatar}
          setNewAvatar={setNewAvatar}
          validation={validation}
          bgInfo={userData.background}
        />
      ),
    },
    {
      title: "Контактные данные",
      data: (
        <Contacts
          personData={editData}
          setPersonData={setEditData}
          validation={validation}
        />
      ),
    },
    {
      title: "Обо мне",
      data: (
        <AboutMe
          personData={editData}
          setPersonData={setEditData}
        />
      ),
    },
  ];

  const updatePersons = () => {
    personController.allPersons(`id!:${currentUser.id}`).then((res) => {
      dispatch(profilesInitial.filteredProfiles(res.data.items));
    });
  };
  const handleCancel = () => {
    if (companyInitialState.isForUserEditing) {
      companyInitialState.isPreserved = true;
      companyInitialState.shouldGetData = false;
    }
    navigate(-1);
  };

  useEffect(() => () => {
    companyInitialState.isForUserEditing = false;
  }, []);

  const afterSuccessUpdate = (result: any) => {
    isCurrentUser
      ? dispatch(profilesInitial.profile(result.data))
      : updatePersons();
  };

  const putData = () => {
    const updAvatar = () => {
      if (newAvatar) {
        const formData = new FormData();
        formData.append("file", newAvatar);
        return personController.avatar(userData.id, formData);
      }
    };
    const updJob = () => {
      if (!isUserRole) {
        return personController.job(userData.id, {
          department: editData.department?.id ?? null,
          grade: null,
          title: editData.job?.id ?? null,
        });
      }
    };
    const updRole = () => {
      if (isAdminRole && !isCurrentUser) {
        return personController.role(userData.id, editData.role);
      }
    };

    const updTags = (): Promise<any>[] => {
      const tagsForUnpin = srcUserData?.tags
        ?.map(tag => tag.id)
        ?.filter(tagId => !editData?.tags?.map(t => t.id)?.includes(tagId)) || []
      const tagsForPin = editData?.tags
        ?.map(tag => tag.id)
        ?.filter(tagId => !srcUserData?.tags?.map(t => t.id)?.includes(tagId)) || []
      return [
        ...tagsForUnpin.map(tagId => tagController.removeFromPerson(editData.id, tagId)) as Promise<any>[],
        ...tagsForPin.map(tagId => tagController.addToPerson(editData.id, tagId)) as Promise<any>[]
      ]
    };

    const handleSubmitError = (e: AxiosError) => {
      const error = e?.response?.data as BackendError;
      const message = error?.errorMessage ?? textSnackbars.default;

      setSnackbarMessage(message);
      setOpenSnackbar(true)
    };

    if (isUserRole) {
      personController
        .editByUSer(userData.id, editData)
        .then((result) => {
          Promise.all([updAvatar()])
            .then((res) => {
              afterSuccessUpdate(res[0] ? res[0] : result);
              // updTags()
              handleCancel();
            })
            .catch(handleSubmitError);
        }).catch(handleSubmitError);
    } else {
      personController
        .editPerson(userData.id, editData)
        .then((result) => {
          Promise.all([updAvatar(), updJob(), updRole(), ...updTags()])
            .then((res) => {
              afterSuccessUpdate(res[0] ? res[0] : result);
              handleCancel();
            })
            .catch(handleSubmitError);
        })
        .catch(handleSubmitError);
    }
  };

  return (
    <>
      <Stack spacing={4} sx={{ m: isMobile ? 2 : undefined }}>
        <UserHeader profileInfo={userData} />
        {profileData
          .filter((item) => {
            if (!isCurrentUser) {
              return item.title !== "Основная информация";
            }
            return item;
          })
          .map((item, index) => (
            <Box key={index}>
              <Typography variant="h4" mb={4}>
                {item?.title}
              </Typography>
              {item?.data}
            </Box>
          ))}
        {isMobile && !editData.blocked && <BlockUserButton userId={userData.id} />}
      </Stack>
      <BottomActions>
        <ModalButtons
          nameButtonSend="Сохранить"
          handleSend={putData}
          handleCancel={handleCancel}
          disableSend={isSaveDisabled}
          fullWidth
          justifyContent="center"
          additionalStyles={{
            width: "45%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        />
      </BottomActions>
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type="error"
        position="center"
        message={snackbarMessage}
      />
    </>
  );
};

export default UserEditing;

import React, { FC, useState } from "react";

import ToggleButton from "@mui/material/ToggleButton";
import { Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CalendarEventType } from '../../../../reducer/calendar';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { intToColor } from '../../CalendarDay/CalendarDay';

const StyledToggleButton = styled(ToggleButton, {
	shouldForwardProp(prop: string) {
		return (
			prop !== "textColor"
			&& prop !== "selectionFollowsFocus"
			&& prop !== "indicator"
		);
	},
})``;

interface EventModalButtonsProps {
	onChange: (props?: any) => void;
	currentValue: string;
	translator?: (props?: any) => string;
	styles?: any;
}

const EventModalButtons: FC<EventModalButtonsProps> = ({
	onChange,
	currentValue,
	translator,
	styles,
}) => {
	const [value, setValue] = useState<string>(currentValue);
	const eventTypes: CalendarEventType[] = useAppSelector(
		(state) => state.calendarReducer.eventTypes
	);

	const handleValue = (
		event: React.SyntheticEvent<Element, Event>,
		newValue: string,
	) => {
		setValue(newValue);
		onChange(newValue);
	};

	const styleGroup = {
		mx: { mobile: "-16px", laptop: "-32px" },
		".MuiTabs-scroller": {
			pl: { mobile: "10px", laptop: "26px" },
			pr: { mobile: "16px", laptop: "32px" },
		},
		".MuiButtonBase-root": {
			borderRadius: "60px",
			fontSize: "14px",
			lineHeight: "20px",
			padding: "10px 12px",
			margin: "0px 6px",
			backgroundColor: "rgba(203, 225, 255, 0.17)",
			color: "rgba(229, 235, 242, 0.65)",
			"&:last-of-type": {
				position: "relative",
				"&::after": {
					position: "absolute",
					content: "''",
					right: { mobile: "-16px", laptop: "-32px" },
					width: { mobile: "16px", laptop: "32px" },
					height: "100%",
				},
			},
		},
		".MuiTabs-indicator": {
			height: "0px",
		},
		...styles,
	};

	const styleSingle = eventTypes.map((type) => (
		{
			"&.MuiButtonBase-root.Mui-selected": {
				backgroundColor: intToColor(type.color),
				color: "rgba(230, 237, 245, 1)",
			},
		}
	));

	return (
		<Tabs
			value={value}
			onChange={handleValue}
			variant="scrollable"
			scrollButtons={false}
			sx={styleGroup}
			aria-label="scrollable events"
		>
			{eventTypes.map((item, index) => (
				<StyledToggleButton value={item.typeEvent} aria-label={item.fullNameEvent} key={index} sx={styleSingle[index]}>
					{translator ? translator(item.fullNameEvent) : item.fullNameEvent}
				</StyledToggleButton>
			))}
		</Tabs>
	);
}

export default EventModalButtons;
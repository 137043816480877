import { CALENDAR } from "../constants/actions";

export const calendarInitial = {
  eventTypes: (payload: any) => {
    return { type: CALENDAR.EVENT_TYPES, payload };
  },
  allEvents: (payload: any) => {
    return { type: CALENDAR.ALL_EVENTS, payload };
  },
  filterEvents: (payload: string[]) => {
    return { type: CALENDAR.FILTER_EVENTS, payload };
  },
};
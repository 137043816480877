import React, { FC, useState } from 'react';

import { Box, Dialog, Stack, Typography } from '@mui/material';

import { EventStateParticipant } from '../../../../../types/CalendarEvent.type';
import { PersonsSelect } from '../../../../PersonsSelect';
import CustomButton from '../../../../common/Buttons/CustomButton/CustomButton';
import { ChipPerson } from '../../../../common/ChipPerson';
import { styles } from './styles';

interface GeneralSelectProps {
  participantsEvent: EventStateParticipant[];
  setParticipants: (users: EventStateParticipant[]) => void;
  eventRole: EventStateParticipant['eventRole'];
  hasAllEditorRights: boolean;
}

export const GeneralSelect: FC<GeneralSelectProps> = ({
  participantsEvent,
  eventRole,
  setParticipants,
  hasAllEditorRights,
}) => {
  const [modal, setModal] = useState<EventStateParticipant['eventRole'] | null>(null);
  const participantsList = participantsEvent.filter(({ eventRole }) => eventRole === 'PARTICIPANT');
  const moderatorsList = participantsEvent.filter(({ eventRole }) => eventRole === 'MODERATOR');

  const getUsers = (users: EventStateParticipant[]) => {
    const newUsersDict: Map<string, EventStateParticipant> = new Map(
      [...participantsEvent, ...users].map((item) => [item.id, item])
    );
    if (modal === 'PARTICIPANT') {
      newUsersDict.forEach(({ eventRole }, key) => {
        if (eventRole === 'PARTICIPANT') newUsersDict.delete(key);
      });
      users.forEach((item) => {
        newUsersDict.set(item.id, { ...item, eventRole: 'PARTICIPANT' });
      });
    } else if (modal === 'MODERATOR') {
      newUsersDict.forEach((item, key) => {
        if (item.eventRole === 'MODERATOR')
          newUsersDict.set(key, { ...item, eventRole: 'PARTICIPANT' });
      });
      users.forEach(({ id }) => {
        const newUser = newUsersDict.get(id);
        if (newUser) newUser.eventRole = 'MODERATOR';
      });
    }
    setParticipants([...newUsersDict.values()]);
  };

  if (!participantsList.length && modal === 'MODERATOR') setModal(null);

  return (
    <Box>
      <Typography sx={styles.stackTitle} variant="h6">
        Организатор
      </Typography>
      <Stack sx={styles.list}>
        {participantsEvent.map((participant) => {
          if (participant.eventRole !== 'ORGANIZER') return null;
          return <ChipPerson key={participant.id} person={participant} />;
        })}
      </Stack>
      <Typography sx={styles.stackTitle} variant="h6">
        Участники
      </Typography>
      <Stack sx={styles.list}>
        {(hasAllEditorRights || eventRole !== 'PARTICIPANT') && (
          <CustomButton
            styles={styles.plusButton}
            onClick={() => setModal('PARTICIPANT')}
            text="+"
          />
        )}
        {participantsEvent.map((participant) => {
          if (participant.eventRole !== 'PARTICIPANT') return null;
          return (
            <ChipPerson
              key={participant.id}
              person={participant}
              onDelete={
                hasAllEditorRights || eventRole !== 'PARTICIPANT'
                  ? () => {
                      setParticipants(participantsEvent.filter(({ id }) => id !== participant.id));
                    }
                  : undefined
              }
            />
          );
        })}
      </Stack>
      <Typography sx={styles.stackTitle} variant="h6">
        Модераторы встречи
      </Typography>
      <Stack sx={styles.list}>
        {(hasAllEditorRights || eventRole === 'ORGANIZER') && (
          <>
            {Boolean(participantsList.length) && (
              <CustomButton
                styles={styles.plusButton}
                onClick={() => setModal('MODERATOR')}
                text="+"
              />
            )}
            {!participantsList.length && !moderatorsList.length && (
              <Typography>Чтобы добавить модераторов, сперва добавьте учатников</Typography>
            )}
          </>
        )}
        {participantsEvent.map((moderator) => {
          if (moderator.eventRole !== 'MODERATOR') return null;
          return (
            <ChipPerson
              key={moderator.id}
              person={moderator}
              onDelete={
                hasAllEditorRights || eventRole === 'ORGANIZER'
                  ? () => {
                      setParticipants(
                        participantsEvent.map((item) => {
                          if (item.id === moderator.id) moderator.eventRole = 'PARTICIPANT';
                          return item;
                        })
                      );
                    }
                  : undefined
              }
            />
          );
        })}
      </Stack>
      <Dialog
        sx={styles.dialog}
        open={Boolean(modal === 'PARTICIPANT')}
        onClose={() => setModal(null)}
      >
        <PersonsSelect
          sx={styles.select}
          selected={participantsList}
          notShowIds={participantsEvent
            .filter(
              ({ eventRole }) =>
                eventRole && (eventRole === 'MODERATOR' || eventRole === 'ORGANIZER')
            )
            .map(({ id }) => id)}
          getUsers={getUsers}
          hideChosen={true}
        />
      </Dialog>
      <Dialog
        sx={styles.dialog}
        open={Boolean(modal === 'MODERATOR')}
        onClose={() => setModal(null)}
      >
        <PersonsSelect
          sx={styles.select}
          source={participantsEvent}
          selected={moderatorsList}
          notShowIds={participantsEvent
            .filter(({ eventRole }) => eventRole && eventRole === 'ORGANIZER')
            .map(({ id }) => id)}
          getUsers={getUsers}
          hideChosen={true}
        />
      </Dialog>
    </Box>
  );
};

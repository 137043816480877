import React, { useState } from 'react';

import { Stack, StackProps } from '@mui/material';

import { PersonView } from '../../../../models/profile.models';
import { PersonList } from '../PersonList/PersonList';
import { SelectedList } from '../SelectedList/SelectedList';

interface IItem extends PersonView {
  isSelected?: boolean;
}

interface PesonListFromSourceProps extends StackProps {
  source: IItem[];
  getUsers: (users: PersonView[]) => void;
  selected: PersonView[];
  notShowIds: string[];
  hideChosen: boolean;
}

export const PesonListFromSource = ({
  source,
  selected,
  getUsers,
  hideChosen,
  notShowIds,
  ...rest
}: PesonListFromSourceProps): JSX.Element => {
  const [usersState, setUsersState] = useState<Map<string, IItem>>(() => {
    const state = new Map(source.map((item) => [item.id, item]));
    selected.forEach(({ id }) => {
      const selected = state.get(id);
      if (selected) selected.isSelected = true;
    });
    return state;
  });
  const usersArray = Array.from(usersState.values());

  const handleChose = (person: PersonView) => {
    setUsersState((prev) => {
      const newState = new Map(prev);
      const deletePerson = newState.get(person.id);
      if (deletePerson) deletePerson.isSelected = true;
      getUsers(
        [...newState.values()]
          .filter(({ isSelected }) => isSelected)
          .map((item) => {
            const newItem = { ...item };
            delete newItem.isSelected;
            return newItem;
          })
      );
      return newState;
    });
  };

  const handleUnchose = (id: string) =>
    setUsersState((prev) => {
      const newState = new Map(prev);
      const deletePerson = newState.get(id);
      if (deletePerson) delete deletePerson.isSelected;
      getUsers(
        [...newState.values()]
          .filter(({ isSelected }) => isSelected)
          .map((item) => {
            const newItem = { ...item };
            delete newItem.isSelected;
            return newItem;
          })
      );
      return newState;
    });

  return (
    <Stack gap={2} {...rest}>
      {!hideChosen && (
        <SelectedList
          handleUnchose={handleUnchose}
          selected={usersArray.filter((item) => item.isSelected)}
        />
      )}
      <PersonList
        unselected={usersArray.filter((item) => !item.isSelected)}
        handleChose={handleChose}
        notShowIds={notShowIds}
      />
    </Stack>
  );
};

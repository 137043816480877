import { SystemStyleObject, Theme } from '@mui/system';

const keys = ['list', 'select', 'selectDialog', 'dialog', 'stackTitle', 'plusButton'] as const;

type StylesType = Record<(typeof keys)[number], SystemStyleObject<Theme>>;

export const styles: StylesType = {
  list: {
    marginBottom: '2rem',
    minHeight: '1.5rem',
    gap: 2,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  selectDialog: { padding: '1rem' },
  select: { padding: '1rem', maxWidth: '500px', minWidth: '280px' },
  dialog: { padding: '1rem' },
  stackTitle: {marginBottom: '1rem'},
  plusButton: { padding: '0.25rem 0.5rem', height: '2rem', width: '2rem' },
};

import React from 'react';

import { Button, ButtonProps } from '@mui/material';
import { SystemStyleObject, Theme } from '@mui/system';

import { styles } from './styles';
import { DesignType, SizeType } from './types';

interface SharedButtonProps extends ButtonProps {
  designType?: DesignType;
  sizeByDesign?: SizeType;
  systemStyleObject?: SystemStyleObject<Theme>;
}

export const SharedButton = ({
  designType = 'primary',
  sizeByDesign = '24px',
  systemStyleObject = null,
  children,
  ...rest
}: SharedButtonProps): JSX.Element => {
  return (
    <Button
      sx={[styles.default, styles[sizeByDesign], styles[designType], systemStyleObject]}
      {...rest}
    >
      {children}
    </Button>
  );
};

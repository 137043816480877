import { apiUrlPortal } from "../constants/apiUrl";
import { UserInfo, IPersonView } from "../types/UserInfo.types";
import jwtInterceptor from "./jwtInterceptor";
import { customBaseQuery } from "./customBaseQuery";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { PersonView } from "../models/profile.models";
import { AxiosResponse } from 'axios';

export interface IFetchPersonsParams {
  page?: number;
  size?: number;
  search?: string;
  sort?: "id" | "desc"
}

export const personsApi = createApi({
  reducerPath: 'personsApi',
  baseQuery: customBaseQuery,
  tagTypes: ['Persons'],
  endpoints: (builder) => ({
    fetchPersons: builder.query<
      { items: PersonView[]; totalItems: number },
      IFetchPersonsParams | undefined
    >({
      query: (params) => ({
        url: 'persons',
        method: 'GET',
        params: params,
      }),
      providesTags: ['Persons'],
    }),
    updateFired: builder.mutation<void, { id: string; body: { fired: string } }>({
      query: ({ id, body }) => ({
        url: `persons/${id}/fired`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Persons'],
    }),
  }),
});

export const { useFetchPersonsQuery, useLazyFetchPersonsQuery, useUpdateFiredMutation } =
  personsApi;

export const personController = {
  person: async (id: string | null): Promise<AxiosResponse<IPersonView>> => {
    return await jwtInterceptor.get(`${apiUrlPortal}/persons/${id}`);
  },
  allPersons: async (
    search?: string,
    sort?: string,
    page?: number,
    size?: number
  ): Promise<any> => {
    return await jwtInterceptor.get(
      `${apiUrlPortal}/persons?search=${search ?? ""}&sort=${sort ?? ""}&page=${
        page ?? ""
      }&size=${size ?? ""}`
    );
  },
  editPerson: async (id: string | null, editedData: UserInfo): Promise<AxiosResponse<IPersonView>> => {
    const bodyRequest = {
      cityId: editedData.city?.id,
      contact: {
        email: editedData.email,
        github: null,
        instagram: null,
        linkedin: null,
        phone: editedData.phone,
        skype: null,
        telegram: editedData.telegram,
        vk: null,
      },
      description: editedData.description,
      dob: editedData.dob,
      gender: editedData.gender,
      name: editedData.name,
      patronymic: editedData.patronymic || null,
      surname: editedData.surname,
      fired: editedData.fired
    };
    return await jwtInterceptor.put(
      `${apiUrlPortal}/persons/${id}`,
      bodyRequest
    );
  },
  editByUSer: async (id: string | null, editedData: UserInfo): Promise<any> => {
    const bodyRequest = {
      contact: {
        email: editedData.email,
        github: null,
        instagram: null,
        linkedin: null,
        phone: editedData.phone,
        skype: null,
        telegram: editedData.telegram,
        vk: null,
      },
      description: editedData.description,
      dob: editedData.dob,
    };
    return await jwtInterceptor.put(
      `${apiUrlPortal}/persons/${id}/personal`,
      bodyRequest
    );
  },
  blockPerson: async (id: string | null): Promise<any> => {
    return await jwtInterceptor.put(`${apiUrlPortal}/persons/${id}/block`);
  },
  unblockPerson: async (id: string | null): Promise<any> => {
    return await jwtInterceptor.put(`${apiUrlPortal}/persons/${id}/unblock`);
  },
  password: async (
    id: string | null,
    newPassword: string,
    oldPassword: string
  ): Promise<any> => {
    const body = {
      newPassword: newPassword,
      oldPassword: oldPassword,
    };
    return await jwtInterceptor.put(
      `${apiUrlPortal}/persons/${id}/password`,
      body
    );
  },
  avatar: async (id: string | null, body?: any): Promise<any> => {
    return await jwtInterceptor.put(
      `${apiUrlPortal}/persons/${id}/avatar`,
      body
    );
  },
  background: async (id: string | null, body?: any): Promise<any> => {
    return await jwtInterceptor.put(
      `${apiUrlPortal}/persons/${id}/background`,
      body
    );
  },
  removeBackground: async (personId: string | null): Promise<any> => {
    return await jwtInterceptor.delete(
      `${apiUrlPortal}/persons/${personId}/background`
    );
  },
  username: async (id: string | null): Promise<any> => {
    return await jwtInterceptor.get(`${apiUrlPortal}/persons/${id}/username`);
  },
  hired: async (id: string | null, body?: any): Promise<any> => {
    return await jwtInterceptor.put(`${apiUrlPortal}/persons/${id}/hired`, body);
  },
  job: async (id: string | null, body?: any): Promise<any> => {
    return await jwtInterceptor.put(`${apiUrlPortal}/persons/${id}/job`, body);
  },
  role: async (id: string | null, role: string): Promise<any> => {
    const body = { role: role };
    return await jwtInterceptor.put(
      `${apiUrlPortal}/persons/${id}/role`,
      body
    );
  },
  addPerson: async (userData: Omit<UserInfo,
      'blocked' | 'hired' | 'description' | 'phone' | 'telegram'>): Promise<any> => {
    const bodyRequest = {
      cityId: userData.city?.id,
      dob: userData.dob,
      gender: userData.gender,
      job: {
        department: userData.department?.id ?? null,
        grade: undefined,
        title: userData.job?.id ?? null,
      },
      name: userData.name,
      patronymic: userData.patronymic || null,
      role: userData.role,
      surname: userData.surname,
      username: userData.email,
    };
    return await jwtInterceptor.post(`${apiUrlPortal}/persons/`, bodyRequest);
  },
  cities: async (page?: number, search?: string): Promise<any> => {
    return await jwtInterceptor.get(
      `${apiUrlPortal}/persons/cities?page=${page ?? ""}&search=${
        search ?? ""
      }&sort=name,asc`
    );
  },
};

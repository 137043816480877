import React, { FC, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";

import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import { Tabs, ToggleButtonGroup, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

import { calendarInitial } from "../../../actions/calendar";
import EditPopover from '../../common/Modals/EditPopover/EditPopover';
import { calendarController } from '../../../services/calendar.controller';
import { CalendarContext } from '../CalendarContext/CalendarContextProvider';
import { CalendarEventType } from '../../../reducer/calendar';
import { generateStyleBackgrounds } from '../CalendarDay/CalendarDay';

const StyledToggleButton = styled(ToggleButton, {
	shouldForwardProp(prop: string) {
		return (
			prop !== "textColor"
			&& prop !== "selectionFollowsFocus"
			&& prop !== "indicator"
		);
	},
})``;

const StyledBox = styled(Box, {
	shouldForwardProp(prop: string) {
		return (
			prop !== "textColor"
			&& prop !== "selectionFollowsFocus"
			&& prop !== "indicator"
			&& prop !== "fullWidth"
		);
	},
})``;

interface CalendarButtonsProps {
	translator?: (props?: any) => string;
	styles?: any;
}

const CalendarButtons: FC<CalendarButtonsProps> = ({
	translator,
	styles,
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));
	const { onCalendarError: handleError } = useContext(CalendarContext);
	const dispatch = useDispatch();

	const getEventTypes = () => {
		calendarController
			.eventTypes()
			.then(res => {
				// Set event types
				dispatch(
					calendarInitial.eventTypes(res.data)
				);
				// Select all available event types
				dispatch(
					calendarInitial.filterEvents(res.data.map((item: CalendarEventType) => item.typeEvent))
				);
			})
			.catch(() => {
				handleError();
			});
	};

	useEffect(() => {
		getEventTypes();
	}, []);

	const eventTypes: CalendarEventType[] = useAppSelector(
		(state) => state.calendarReducer.eventTypes
	);

	const eventsFilter: string[] = useAppSelector(
		(state) => state.calendarReducer.eventsFilter
	);

	const handleFilterChange = (
		event: React.MouseEvent<HTMLElement>,
		newEventsFilter: string[],
	) => {
		dispatch(
			calendarInitial.filterEvents(newEventsFilter)
		);
	};

	const handleExport = async () => {
		const response = await calendarController.export(eventsFilter);

		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'calendar.ics');
		document.body.appendChild(link);
		link.click();
		link.remove();
		window.URL.revokeObjectURL(url);
	};

	const exportButton = (eventsFilter.length > 0)
		? [
			{
				name: "Экспортировать",
				action: handleExport,
			},
		]
		: [];

	const popoverButtons = [
		...exportButton,
	];

	const backgrounds = generateStyleBackgrounds(eventTypes);

	const styleGroup = {
		gap: "8px",
		"& .MuiToggleButtonGroup-grouped": {
			border: 0,
			"&.MuiToggleButton-root": {
				borderRadius: "60px",
				backgroundColor: "rgba(203, 225, 255, 0.17)",
				fontSize: "14px",
				lineHeight: "20px",
				color: theme.palette.text.secondary,
				padding: "10px 12px",
			},
			"&.MuiToggleButton-root:hover": {
				backgroundColor: "rgba(185, 215, 255, 0.08)",
			},
			"&.Mui-selected, &.Mui-selected:hover": {
				color: theme.palette.text.primary,
				...backgrounds,
			},
			"&.MuiToggleButton-root.Mui-disabled": {
				border: 0,
			},
		},
		".MuiTabs-indicator": {
			height: "0px",
		},
		...styles,
	};

	return (
		<>
			<StyledBox display="flex" justifyContent="space-between" px="16px">
				<Tabs
					value={false}
					variant="scrollable"
					scrollButtons={isMobile ? false: "auto"}
					sx={styleGroup}
				>
					<StyledBox>
						<ToggleButtonGroup
							value={eventsFilter}
							onChange={handleFilterChange}
							aria-label="scrollable events"
							sx={styleGroup}
						>
							{eventTypes.map((item, index) => (
								<StyledToggleButton value={item.typeEvent} aria-label={item.fullNameEvent} key={index} className={`calendar-event-${index}`}>
									{translator ? translator(item.fullNameEvent) : item.fullNameEvent}
								</StyledToggleButton>
							))}
						</ToggleButtonGroup>
					</StyledBox>
				</Tabs>
				{(popoverButtons.length > 0) ? <EditPopover buttonsData={popoverButtons} /> : null}
			</StyledBox>
		</>
	);
}

export default CalendarButtons;
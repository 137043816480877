import React, { FC, SetStateAction, Dispatch } from 'react';
import moment from 'moment';

import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import InputWrapperArray from '../../common/Inputs/InputWrapper/InputWrapperArray';
import InputLine from '../../common/Inputs/InputLine/InputLine';
import InputFilled from '../../common/Inputs/InputFilled/InputFilled';
import EditAvatar from './EditAvatar/EditAvatar';
import DatePickerCustom from '../../common/Inputs/DatePickerCustom/DatePickerCustom';
import { UserInfo, UserInfoValidation } from '../../../types/UserInfo.types';
import { checkLanguage } from '../../../constants/errorText';
import { roles } from '../../../constants/role';
import Gender from '../../common/Gender/Gender';
import CityCombobox from './CityCombobox/CityCombobox';
import Post from './Post/Post';
import EditBackground from "./EditBackground/EditBackground";
import {PersonView} from "../../../models/profile.models";
import { Box } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { FiredDate } from './FiredDate/FiredDate';

interface PersonalInfoProps {
  personData: UserInfo;
  setPersonData: Dispatch<SetStateAction<UserInfo>>;
  newAvatar: File | null;
  setNewAvatar: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validation: UserInfoValidation;
  bgInfo: PersonView['background']
}

export const maxUserNameLength = 70;
export const maxUserSurnameLength = 70;
export const maxUserPatronymicLength = 70;

export const PersonalInfo: FC<PersonalInfoProps> = ({
  personData,
  setPersonData,
  newAvatar,
  setNewAvatar,
  validation,
  bgInfo
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('laptop'));

  const currentUserRole = JSON.parse(
    localStorage.getItem('REACT_TOKEN_AUTH_KEY') || '{}',
  ).role;

  const handleChangeInputText =
    (prop: keyof UserInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPersonData({ ...personData, [prop]: event.target.value });
    };

  const handleChangeDate = (newValue?: string | null) => {
    const newDate = moment(newValue, 'DD.MM.YYYY');
    setPersonData({
      ...personData,
      dob: newDate.format('YYYY-MM-DD'),
    });
  };

  const handleChangeHiredDate = (newValue?: string | null) => {
    const newDate = moment(newValue, 'DD.MM.YYYY');
    setPersonData({
      ...personData,
      hired: newDate.format('YYYY-MM-DD'),
    });
  };

  const maxDate = moment().subtract(14, 'years').toDate();
  const disableEditInfo = currentUserRole === roles.user;
  const data = [
    {
      items: [
        {
          title: 'Аватар',
          element: <EditAvatar
            newAvatar={newAvatar}
            setNewAvatar={setNewAvatar}
          />,
        },
        {
          title: 'Обои',
          element: <EditBackground bgInfo={bgInfo} />
        }
      ],
    },
    {
      items: [{
        title: 'Фамилия',
        element: (
            <InputLine
                testid="surname"
                value={personData?.surname}
                onChange={handleChangeInputText('surname')}
                error={validation.surname !== true}
                helperText={checkLanguage}
                disabled={disableEditInfo}
                inputProps={{ maxLength: maxUserSurnameLength }}
            />
        ),
      }]
    },
    {
      items: [{
        title: 'Имя',
        element: (
            <InputLine
                testid="name"
                value={personData?.name}
                onChange={handleChangeInputText('name')}
                error={validation.name !== true}
                helperText={checkLanguage}
                disabled={disableEditInfo}
                inputProps={{ maxLength: maxUserNameLength }}
            />
        ),
      }]
    },
    {
      items: [{
        title: 'Отчество',
        element: (
            <InputLine
                testid="patronymic"
                value={personData?.patronymic}
                onChange={handleChangeInputText('patronymic')}
                error={validation.patronymic !== true}
                helperText={checkLanguage}
                disabled={disableEditInfo}
                inputProps={{ maxLength: maxUserPatronymicLength }}
            />
        ),
      }]
    },
    {
      items: [{
        title: 'Дата рождения',
        element: (
          <FormControl fullWidth error={typeof validation.hired === 'string'}>
            <DatePickerCustom
                value={personData?.dob}
                styles={{
                  width: isMobile ? '100%' : 'calc(50% - 6px)',
                  pr: '8px',
                }}
                maxDate={maxDate}
                error={(!validation.dob || typeof validation.dob === 'string')}
                helperText={(typeof validation.dob === 'string') ? validation.dob : undefined}
                onChange={handleChangeDate}
            />
            {validation?.hired && <FormHelperText style={{ fontSize: '0.85rem', margin: '0.5rem 0 0' }}>{validation?.hired}</FormHelperText>}
          </FormControl>
        ),
      },
      {
        title: 'Дата увольнения',
        element: (
          <FiredDate
            fired={personData.fired}
            onchangeFired={(fired) => setPersonData((prev) => ({ ...prev, fired }))}
            isMobile={isMobile}
          />
        ),
      },
    ]
    },
    {
      name: 'Дата найма',
      element: (
        <DatePickerCustom
          value={personData?.hired}
          styles={{
            width: isMobile ? '100%' : 'calc(50% - 6px)',
            pr: '8px',
          }}
          error={(!validation.hired || typeof validation.hired === 'string')}
          helperText={(typeof validation.hired === 'string') ? validation.hired : undefined}
          onChange={handleChangeHiredDate}
        />
      ),
    },
    {
      items: [{
        title: 'Город',
        element: (
          <CityCombobox
            personData={personData}
            setPersonData={setPersonData}
            disabled={disableEditInfo}
            width={isMobile ? '100%' : '48%'}
            error={!validation.city ? 'Укажите город' : undefined}
          />
        ),
      }]
    },
    {
      items: [{
        title: 'Пол',
        element: (
            <Gender
                personData={personData}
                setPersonData={setPersonData}
                disabled={disableEditInfo}
            />
        ),
      }]
    },
  ];
  return (
    <Stack spacing={4}>
      {data.map((item, index) => (
        <InputWrapperArray key={index} {...item} />
      ))}
    </Stack>
  );
};

export default PersonalInfo;

import React, { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../../common/Buttons/BackButton/BackButton";
import { AppContext } from "../../../shared/AppContextProvider";
import { roles } from "../../../../constants/role";
import { kbController } from "../../../../services/kb.controller";
import { KbContext } from "../../KbContextProvider/KbContextProvider";
import { ArticleMainData } from "../../../../types/ArticleMainData.types";

import { Box } from "@mui/material";

import EditPopover from "../../../common/Modals/EditPopover/EditPopover";
import ConfirmModal from "../../../common/Modals/ConfirmModal/ConfirmModal";
import ArticleModal from "../../KbModal/ArticleModal";

export const ArticleHeader: FC = () => {
  const navigate = useNavigate();

  const { handleSnackbar } = useContext(KbContext);
  const { user } = useContext(AppContext);
  const editAvailable = user.role !== roles.user;
  
  const articleMainData: ArticleMainData = JSON.parse(
    localStorage.getItem("article") || "{}"
  );

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const handleConfirmModal = () => {
    setOpenConfirmModal((prev) => !prev);
  };

  const [openEditModal, setOpenEditmModal] = useState<boolean>(false);
  const handleEditModal = () => {
    setOpenEditmModal((prev) => !prev);
  };

  const handleDelete = () => {
    kbController
      .deleteArticle(articleMainData.articleId, articleMainData.sectionId)
      .then(() => {
        handleConfirmModal();
        navigate(-1);
      })
      .catch(() => {
        handleSnackbar("error");
      });
  };

  const popoverButtons = [
    { name: "Редактировать", action: handleEditModal },
    { name: "Удалить", action: handleConfirmModal },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <BackButton />
        {editAvailable && <EditPopover buttonsData={popoverButtons} />}
      </Box>

      {openConfirmModal && (
        <ConfirmModal
          openConfirmModal={openConfirmModal}
          nameConfirmButton="Удалить"
          text="Статья будет удалена."
          handleConfirm={handleDelete}
          handleModal={handleConfirmModal}
        />
      )}
      {openEditModal && (
        <ArticleModal
          open={openEditModal}
          type="edit"
          sectionId={articleMainData.sectionId}
          articleId={articleMainData.articleId}
          handleModal={handleEditModal}
        />
      )}
    </>
  );
};

export default ArticleHeader;

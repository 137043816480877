import { useState, useEffect } from "react";

import { kbController } from "../services/kb.controller";
import { KbSectionView } from "../models/kb.models";

export const useKbSections = () => {
  const [sections, setSections] = useState<{ id: string; name: string }[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loading) {
      return;
    }

    setLoading(true);

    kbController.sections(currentPage).then((res) => {
      const sectionsName = res.data.items.map((item: KbSectionView) => ({
        id: item.id,
        name: item.name,
      }));
      setSections((prev) => [...prev, ...sectionsName]);
      setTotalItems(res.data.totalItems);
    }).finally(() => {
      setLoading(false);
    });
    /* eslint-disable-next-line */
  }, [currentPage]);

  return { sections, setCurrentPage, totalItems, loading };
};

import React, { FC } from 'react';

import { Chip, ChipProps } from '@mui/material';

import { PersonView } from '../../../models/profile.models';
import UserAvatar from '../User/UserAvatar/UserAvatar';
import { styles } from './styles';

interface ChipPersonProp extends ChipProps {
  person: PersonView;
  onDelete?: (id: string) => void;
}

export const ChipPerson: FC<ChipPersonProp> = ({ person, onDelete, ...rest }) => {
  return (
    <Chip
      sx={styles.chip}
      label={`${person.name} ${person.surname}`}
      avatar={<UserAvatar userInfo={person} styles={styles.avatar} />}
      onDelete={onDelete ? () => onDelete(person.id) : undefined}
      {...rest}
    />
  );
};

import { AxiosResponse } from "axios";
import { apiUrlPortal } from "../constants/apiUrl";
import { KbArticleView, KbRoles, KbSectionView, UpdateKbArticleRequest, UpdateKbGroupArticleRequest } from "../models/kb.models";
import jwtInterceptor from "./jwtInterceptor";

export const kbController = {
  //section
  //Получение всех разделов со статьями 1-ого уровня вложенности
  sections: async (
    page?: number,
    search?: string
  ): Promise<
    AxiosResponse<{
      items: KbSectionView[];
      totalItems: number;
    }>
  > => {
    return await jwtInterceptor.get(
      `${apiUrlPortal}/kb?page=${page ?? ''}&search=${search ?? ''}&sort=name,asc`
    );
  },
  //Получение всех разделов с минимальной информацией (id,name,sequenceNumber)
  onlySections: async (): Promise<
    AxiosResponse<{
      items: Pick<KbSectionView, 'id' | 'name' | "sequenceNumber">[];
      totalItems: number;
    }>
  > => {
    return await jwtInterceptor.get(`${apiUrlPortal}/kb/sections/move`);
  },
  //Получение информации по 1 разделу
  oneSection: async (id: string): Promise<any> => {
    return await jwtInterceptor.get(`${apiUrlPortal}/kb/${id}`);
  },
  //Получение общего количества разделов
  countsSectionSequence: async (): Promise<any> => {
    return await jwtInterceptor.get(`${apiUrlPortal}/kb/getCountsSequence`);
  },
  //Запрос для создания раздела
  createSection: async (body: { name: string, description?: string, visionRoles: KbRoles[], sequenceNumber: string, isReplacement: boolean }): Promise<any> => {
    return await jwtInterceptor.post(`${apiUrlPortal}/kb`, body);
  },
  //Запрос для изменения раздела
  editSection: async (
    sectionId: string,
    body: { name: string, description?: string, visionRoles: KbRoles[], sequenceNumber: string, isReplacement: boolean }
  ): Promise<any> => {
    return await jwtInterceptor.put(
      `${apiUrlPortal}/kb/${sectionId}`,
      body
    );
  },
  //Запрос для удаления раздела
  deleteSection: async (sectionId: string): Promise<any> => {
    return await jwtInterceptor.delete(
      `${apiUrlPortal}/kb/${sectionId}`
    );
  },

  //article
  //Получение статей определенного уровня вложенности, если articleId = null, то верхний уровень вложенности
  articles: async (sectionId: string, articleId: string | null): Promise<any> => {
    return await jwtInterceptor.get(
      `${apiUrlPortal}/kb/sections/${sectionId}/articles/${articleId}/getArticles`
    );
  },
  //Получение всех групп статей у раздела
  allGroupArticles: async (
    sectionId: string
  ): Promise<
    AxiosResponse<{
      items: (Pick<KbArticleView, 'id' | 'name'> & { fullSequenceNumber: string })[];
      totalItems: number;
    }>
  > => {
    return await jwtInterceptor.get(`${apiUrlPortal}/kb/sections/${sectionId}/getGroupArticles`);
  },
  //Получение вложенных статей у определенной группы
  subArticles: async (articleId: string): Promise<any> => {
    return await jwtInterceptor.get(
      `${apiUrlPortal}/kb/articles/${articleId}/nested`
    );
  },
  //Получение данных конкретной статьи
  oneArticle: async (articleId: string, sectionId: string): Promise<any> => {
    return await jwtInterceptor.get(
      `${apiUrlPortal}/kb/sections/${sectionId}/articles/${articleId}`
    );
  },
  //Количество вложенных статей
  countsArticlesSequence: async (sectionId: string, articleId: string | null): Promise<any> => {
    return await jwtInterceptor.get(`${apiUrlPortal}/kb/${sectionId}/articles/${articleId}/number_of_nested_articles`);
  },
  //Запрос на создание статьи
  createArticle: async (sectionId: string, body: FormData): Promise<any> => {
    return await jwtInterceptor.post(
      `${apiUrlPortal}/kb/sections/${sectionId}/articles`,
      body
    );
  },
  //Запрос на редоктирование статьи
  editArticle: async (
    sectionId: string,
    articleId: string,
    body?: UpdateKbArticleRequest | UpdateKbGroupArticleRequest
  ): Promise<any> => {
    return await jwtInterceptor.put(
      `${apiUrlPortal}/kb/sections/${sectionId}/articles/${articleId}`,
      body
    );
  },
  //Запрос на удаление статьи
  deleteArticle: async (articleId: string, sectionId: string): Promise<any> => {
    return await jwtInterceptor.delete(
      `${apiUrlPortal}/kb/sections/${sectionId}/articles/${articleId}`
    );
  },
  //Перемещение/дублирование статей в url текущее положение статьи
  moveArticle: async (
    sectionId: string, 
    articleId: string,
    body: {
      isMove: boolean,
      newGroupArticleId: string | null,
      newSectionId: string,
    }
  ): Promise<any> => {
    return await jwtInterceptor.put(
      `${apiUrlPortal}/kb/sections/${sectionId}/articles/${articleId}/move`,
      body
    );
  },
  attachmentsArticle: async (
    sectionId: string,
    articleId: string,
    body?: any
  ): Promise<any> => {
    return await jwtInterceptor.put(
      `${apiUrlPortal}/kb/sections/${sectionId}/articles/${articleId}/attachments`,
      body
    );
  },
};

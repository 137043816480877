import { apiUrlPortal } from "../constants/apiUrl";
import jwtInterceoptor from "./jwtInterceptor";

import moment from "moment";
import { dbCalendarEventRecurrences } from "../constants/calendar";
import type { CalendarEvent } from "../types/CalendarEvent.type";

export const calendarController = {
  eventTypes: async (): Promise<any> => {
    return jwtInterceoptor.get(`${apiUrlPortal}/corporate/events/types`);
  },
  allEvents: async (): Promise<any> => {
    const events = {
      data: {} as any,
    };
    await jwtInterceoptor.get(`${apiUrlPortal}/calendar/corporate/events`)
      .then(res => {
        res.data.items.forEach((event: any) => {
          const eventStart = moment(event.startDateTime);
          const eventFinish = moment(event.endDateTime);
          const startTime= eventStart.format("HH:mm");
          const finishTime= eventFinish.format("HH:mm");

          const key = eventStart.format("DD-MM-YYYY");
          const eventInfo = {
            calendarEventType: event.eventType,
            name: event.eventName,
            description: event.description,
            date: key,
            isWholeDay: (startTime === '00:00' && finishTime === '23:59'),
            startTime,
            finishTime,
            recurrence: 0,
            id: event.id,
            repeatId: event.repeatId,
          };
          events.data[key]
            ? events.data[key].push(eventInfo)
            : events.data[key] = [eventInfo];
        });
      });
    return events;
  },
  addEvent: async (eventInfo: CalendarEvent): Promise<any> => {
    const bodyRequest = {
      dateEnd: moment(eventInfo.date, "DD-MM-YYYY")
        .add(Number(eventInfo.finishTime.slice(0, 2)), "hours")
        .add(Number(eventInfo.finishTime.slice(3)), "minutes")
        .toISOString(),
      dateStart: moment(eventInfo.date, "DD-MM-YYYY")
        .add(Number(eventInfo.startTime.slice(0, 2)), "hours")
        .add(Number(eventInfo.startTime.slice(3)), "minutes")
        .toISOString(),
      description: eventInfo.description,
      eventName: eventInfo.name,
      eventType: eventInfo.calendarEventType,
      ...eventInfo.recurrence && { recurrence: {
        unit: dbCalendarEventRecurrences[eventInfo.recurrence],
      }},
    };
    return await jwtInterceoptor.post(`${apiUrlPortal}/calendar/corporate/events`, bodyRequest);
  },
  deleteEvent: async (eventId: number, repeatable?: boolean): Promise<any> => {
    return await jwtInterceoptor.delete(
      `${apiUrlPortal}/calendar/corporate/events/${eventId}?repeatable=${repeatable ?? false}`
    );
  },
  editEvent: async (eventInfo: CalendarEvent, repeatable?: boolean): Promise<any> => {
    calendarController.deleteEvent(eventInfo.id!, repeatable);
    return await calendarController.addEvent(eventInfo);
  },
  weekends: async (year: number = moment().year()): Promise<any> => {
    const res = await jwtInterceoptor.get(`${apiUrlPortal}/calendar/${year}`);
    const weekendsSet = new Set();
    res.data.items.forEach((item: any) => {
      const month = item.month[0] + item.month.substring(1, 3).toLowerCase();
      item.holidays.forEach((holiday: number) => {
        weekendsSet.add(month + " " + holiday.toString().padStart(2, "0") + " " + year);
      });
    });
    return weekendsSet;
  },
  export: async (eventsFilter: string[]) => {
    const filters = eventsFilter
      .filter((item) => !!item).map((item) => `eventTypes=${item}`)
      .join('&');

    const config = {
        responseType: 'arraybuffer' as const,
    };

    return jwtInterceoptor.get(`/loyalty-portal/api/v1/calendar/corporate/events/create/file?${filters}`, config);
  },
};
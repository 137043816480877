import { SystemStyleObject, Theme } from '@mui/system';

const keys = [
  'dialogWrapper',
  'title',
  'content',
  'selectBlock',
  'selectTitle',
  'selectContainer',
  'dialogButtons',
] as const;

type StylesType = Record<(typeof keys)[number], SystemStyleObject<Theme>>;

export const styles: StylesType = {
  dialogWrapper: {
    padding: '1rem',
    maxWidth: '32rem',
  },
  title: {
    padding: '1rem 1rem',
    marginBottom: '1rem',
    fontWeight: '700',
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    color: '#FFFFFF',
  },
  content: {
    maxWidth: '32rem',
    padding: '1rem 1rem 2rem',
    color: '#E5EBF2A6',
    fontWeight: '400',
    fontSize: '1rem',
    backgroundColor: '#212633',
    '& > *': { marginBottom: '2rem' },
  },
  selectBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  selectTitle: { marginRight: '2rem' },
  selectContainer: { width: '18rem', flexGrow: 1 },
  dialogButtons: {
    padding: '1rem 2rem',
    display: 'flex',
    width: '100%',
    borderTop: '1px solid #F1F7FF1A',
  },
};

import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

import { Password } from "../LoginPage/Password/Password";
import LoginPageWrapper from "../common/LoginPageWrapper/LoginPageWrapper";
import { passwordController } from "../../services/password.controller";
import Snackbars from "../common/Snackbars/Snackbars";
import { LoginError } from "../LoginPage/LoginError/LoginError";

export const PasswordReset: FC = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const [isApply, setIsApply] = useState<boolean>(false);
  const [errorType, setErrorType] =
    useState<"passwords" | "empty" | "complexity" | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const resetCode = location.split("/reset_password/")[1];
    setToken(resetCode);
  }, [location]);

  const handleSend = () => {
    if (!Boolean(password) || !Boolean(repeatPassword)) {
      setErrorType("empty");
    } else if (password !== repeatPassword) {
      setErrorType("passwords");
    } else {
      setIsLoading(true);
      passwordController
        .restore(token, password)
        .then(() => {
          setIsApply(true);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setErrorType("complexity");
          } else {
            setOpenSnackbar(true);
          }
          setIsLoading(false);
        });
    }
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSend();
  };

  return (
    <LoginPageWrapper handleSubmit={handleFormSubmit}>
      <Typography variant="h4" component="h4" textAlign="center">
        {isApply ? "Вы назначили новый пароль!" : "Сброс пароля"}
      </Typography>
      {!isApply && (
        <>
          {errorType === "empty" && <LoginError label="Заполните все поля" />}
          {errorType === "complexity" && <LoginError label="Пароль должен содержать не менее 8-ми символов из каждой группы: верхний регистр, нижний регистр, цифра, знак (-, _, +, =, !)" />}
          <Password
            label="Введите новый пароль"
            value={password}
            setValue={(value: string) => {
              setPassword(value);
              errorType === "passwords" && setErrorType(undefined);
            }}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
          <Password
            id="new_password"
            label="Повторите пароль"
            value={repeatPassword}
            setValue={(value: string) => {
              setRepeatPassword(value);
              errorType === "passwords" && setErrorType(undefined);
            }}
            showPassword={showRepeatPassword}
            setShowPassword={setShowRepeatPassword}
            error={errorType === "passwords"}
            helperText={
              errorType === "passwords" && (
                <Typography variant="body1" color="rgba(224, 63, 72, 1)">
                  Пароли не совпадают
                </Typography>
              )
            }
          />
        </>
      )}
      {isLoading ? (
        <CircularProgress
          color="inherit"
          sx={{ alignSelf: "center" }}
          size="48px"
        />
      ) : (
        <Button
          variant="contained"
          fullWidth
          sx={{ borderRadius: 60, height: 48 }}
          onClick={() => {
            isApply ? navigate("/") : handleSend();
          }}
          type={isApply ? "button" : "submit"}
        >
          {isApply ? "Перейти ко входу" : "Применить"}
        </Button>
      )}
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type="error"
        position="center"
      />
    </LoginPageWrapper>
  );
};

export default PasswordReset;

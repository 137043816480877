import { SystemStyleObject, Theme } from '@mui/system';

const keys = ['details', 'description'] as const;

type StylesType = Record<(typeof keys)[number], SystemStyleObject<Theme>>;

export const styles: StylesType = {
  details: { padding: 0 },
  description: { marginBottom: '1rem' },
};

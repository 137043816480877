import React, { FC } from 'react';

import { StackProps } from '@mui/material';

import { PersonView } from '../../models/profile.models';
import { ISearchOptions, SelectedListComponentProps } from './types';
import { PersonListFromFetch } from './ui/PersonListFromFetch/PersonListFromFetch';
import { PesonListFromSource } from './ui/PesonListFromSource/PesonListFromSource';

interface PersonsSelectProps extends StackProps {
  getUsers: (users: PersonView[]) => void;
  selected: PersonView[];
  searchProps?: ISearchOptions;
  SelectedListComponent?: FC<SelectedListComponentProps>;
  source?: PersonView[];
  notShowIds: string[];
  hideChosen?: boolean;
}

export const PersonsSelect: FC<PersonsSelectProps> = ({
  getUsers,
  selected,
  searchProps,
  SelectedListComponent,
  source,
  notShowIds,
  hideChosen = false,
  ...rest
}) => {
  if (source)
    return (
      <PesonListFromSource
        source={source}
        getUsers={getUsers}
        selected={selected}
        notShowIds={notShowIds}
        hideChosen={hideChosen}
        {...rest}
      />
    );
  return (
    <PersonListFromFetch
      getUsers={getUsers}
      selected={selected}
      searchProps={searchProps}
      notShowIds={notShowIds}
      hideChosen={hideChosen}
      {...rest}
    />
  );
};

import moment from 'moment';

import { IEventsResponse, IEventsState } from '../../../../types/CalendarEvent.type';

type TEvents = Record<string, IEventsState[]>;

export const transformResDataToCalendar = (data: IEventsResponse[]): TEvents => {
  const events: TEvents = {};
  data.forEach((event) => {
    const eventStart = moment(event.startDateTime);
    const eventFinish = moment(event.endDateTime);
    const startTime = eventStart.format('HH:mm');
    const finishTime = eventFinish.format('HH:mm');

    const key = eventStart.format('DD-MM-YYYY');
    const eventInfo: IEventsState = {
      calendarEventType: event.calendarEventType,
      eventTypeId: event.eventTypeId,
      name: event.eventName,
      description: event.description,
      date: key,
      isWholeDay: startTime === '00:00' && finishTime === '23:59',
      startTime,
      finishTime,
      recurrence: 0,
      id: event.id,
      repeatId: event.repeatId,
      participantsEvent: event.participantsEvent.map(({ participantEvent, participantRole }) => ({
        ...participantEvent,
        eventRole: participantRole,
      })),
    };

    events[key] ? events[key].push(eventInfo) : (events[key] = [eventInfo]);
  });

  return events;
};

import moment from 'moment';

import { dbCalendarEventRecurrences } from '../../../constants/calendar';
import { IEventsState, INewEvent, INewEventRequest } from '../../../types/CalendarEvent.type';

type TransformData = Pick<
  INewEvent,
  'date' | 'startTime' | 'finishTime' | 'description' | 'name' | 'eventTypeId' | 'recurrence'
> &
  Partial<Pick<IEventsState, 'participantsEvent'>>;

export const transformEventForPostRequest = ({
  date,
  finishTime,
  startTime,
  description,
  name,
  eventTypeId,
  recurrence,
  participantsEvent,
}: TransformData): INewEventRequest => {
  const newEvent: INewEventRequest = {
    dateEnd: moment(date, 'DD-MM-YYYY')
      .add(Number(finishTime.slice(0, 2)), 'hours')
      .add(Number(finishTime.slice(3)), 'minutes')
      .toISOString(),
    dateStart: moment(date, 'DD-MM-YYYY')
      .add(Number(startTime.slice(0, 2)), 'hours')
      .add(Number(startTime.slice(3)), 'minutes')
      .toISOString(),
    description,
    eventName: name,
    eventType: eventTypeId,
    recurrence: dbCalendarEventRecurrences[recurrence]
      ? {
          unit: dbCalendarEventRecurrences[recurrence],
        }
      : undefined,
    participantsEvent: participantsEvent
      ? participantsEvent.map(({ id, eventRole }) => ({
          id,
          eventRole: eventRole ?? 'PARTICIPANT',
        }))
      : undefined,
  };
  return newEvent;
};

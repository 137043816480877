import React, { FC, createContext, useEffect, useState } from "react";

import moment from "moment";

import Snackbars from "../../common/Snackbars/Snackbars";
import { calendarController } from "../../../services/calendar.controller";

interface CalendarContextProps {
	children: React.ReactNode;
}

let defaultValue: any;
export const CalendarContext = createContext(defaultValue);

export const CalendarContextProvider: FC<CalendarContextProps> = ({
	children,
}) => {
	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const onCalendarError = () => setOpenSnackbar(true);

	const [weekends, setWeekends] = useState<Set<string>>(new Set());
	const [year, setYear] = useState<number>(moment().year());

	/* TODO : check pass eventTypes to context
	useEffect(() => {
		calendarController
			.eventTypes()
			.then(data => {
				console.log(' calendar events: ', data);
			})
			.catch(() => {
				onCalendarError();
			});
	}, []);
	*/

	useEffect(() => {
		calendarController
			.weekends(year)
			.then(moreWeekends => {
				setWeekends(new Set(function*() {
					yield* weekends;
					yield* moreWeekends;
				}()));
			})
			.catch(() => {
				onCalendarError();
			});
	}, [year]);  // eslint-disable-line

	return (
		<CalendarContext.Provider value={{
			weekends,
			setYear,
			onCalendarError,
		}}>
			{children}
			<Snackbars
				open={openSnackbar}
				setOpen={setOpenSnackbar}
				type="error"
				position="center"
				message="С Календарём что-то не так, свяжитесь с администратором"
			/>
		</CalendarContext.Provider>
	);
};

export default CalendarContextProvider;
import React, {
  FC,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";

import { GridSortModel } from "@mui/x-data-grid";

import { SnackBarType } from "../../common/Snackbars/Snackbars";
import { UserStatus } from "../../../types/UserStatus.types";
import { snackbarFunction } from "../../../operation/snackbarFunction";
import Snackbars from "../../common/Snackbars/Snackbars";
import { UserFilter } from "../../../types/UserFilters.types";

export const companyInitialState = {
  isPreserved: false,
  shouldGetData: true,
  coordToScrollTo: 0,
  isForUserEditing: false,
  status: "",
  totalRecords: 0,
  currentPage: 0,
  sortingState: [] as GridSortModel,
  filtersValue: {} as UserFilter,
  searchFilters: "",
};

interface CompanyContextProps {
  children: React.ReactNode;
}

interface CompanyContextValue {
  toggleItems: UserStatus[];
  status: UserStatus;
  setStatus: Dispatch<SetStateAction<UserStatus>>;
  searchStatus: string;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  totalRecords: number;
  setTotalRecords: Dispatch<SetStateAction<number>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  handleSnackbar: (type: SnackBarType, message?: string) => void;
  sortingState: GridSortModel;
  setSortingState: Dispatch<SetStateAction<GridSortModel>>;
  companyInitialState: typeof companyInitialState;
}
let defaultValue: CompanyContextValue = {
  toggleItems: ["Активные", "Заблокированные"],
  status: "Активные",
  setStatus: () => {},
  searchStatus: "",
  searchValue: "",
  setSearchValue: () => {},
  totalRecords: 0,
  setTotalRecords: () => {},
  currentPage: 0,
  setCurrentPage: () => {},
  isLoading: false,
  setIsLoading: () => {},
  handleSnackbar: () => {},
  sortingState: [],
  setSortingState: () => {},
  companyInitialState,
};
export const CompanyContext = createContext(defaultValue);

export const CompanyContextProvider: FC<CompanyContextProps> = ({
  children,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarType, setSnackbarType] = useState<SnackBarType>("success");
  const [snackbarText, setSnackbarText] = useState<string | undefined>(
    undefined
  );

  const toggleItems: UserStatus[] = ["Активные", "Заблокированные"];
  const [status, setStatus] = useState<UserStatus>(
    companyInitialState.isPreserved ? companyInitialState.status : toggleItems[0]
  );
  const searchStatus = `blocked:${status === toggleItems[0] ? false : true}`;

  const [searchValue, setSearchValue] = useState<string>("");

  const [totalRecords, setTotalRecords] = useState<number>(
    companyInitialState.isPreserved ? companyInitialState.totalRecords : 0
  );
  const [currentPage, setCurrentPage] = useState<number>(
    companyInitialState.isPreserved ? companyInitialState.currentPage : 0
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSnackbar = (type: SnackBarType, message?: string) =>
    snackbarFunction(
      setOpenSnackbar,
      type,
      setSnackbarType,
      message,
      setSnackbarText
    );

  const [sortingState, setSortingState] = useState<GridSortModel>(
    companyInitialState.isPreserved ? companyInitialState.sortingState : []
  );

  useEffect(() => () => {
    companyInitialState.status = status;
    companyInitialState.totalRecords = totalRecords;
    companyInitialState.currentPage = currentPage;
    companyInitialState.sortingState = sortingState;
  }, [status, totalRecords, currentPage, sortingState]);

  useEffect(() => {
    companyInitialState.isPreserved = true;
    return () => {
      companyInitialState.isPreserved = false;
    };
  }, []);

  return (
    <CompanyContext.Provider
      value={{
        toggleItems,
        status,
        setStatus,
        searchStatus,
        searchValue,
        setSearchValue,
        totalRecords,
        setTotalRecords,
        currentPage,
        setCurrentPage,
        isLoading,
        setIsLoading,
        handleSnackbar,
        sortingState,
        setSortingState,
        companyInitialState,
      }}
    >
      {children}
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type={snackbarType}
        position="center"
        message={snackbarText}
      />
    </CompanyContext.Provider>
  );
};

export default CompanyContextProvider;

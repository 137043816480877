import React, { FC } from "react";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { AttachmentView } from "../../../../models/attachment.model";

interface PreviewVideosProp {
  videos: AttachmentView[];
}

export const PreviewVideos: FC<PreviewVideosProp> = ({ videos }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const videoItemStyle = {
    "& .MuiImageListItem-img": { borderRadius: "8px" },
    video: {
      maxHeight: "400px",
    },
    display: "grid",
    maxHeight: "400px",
  };

  return (
    <ImageList cols={6} gap={8} variant="quilted">
      {videos
        ?.filter((item: any, index: number) => index <= 13)
        .map((item: any, index: number) => (
          <ImageListItem
            key={index}
            cols={index <= 1 ? 3 : 1}
            rows={isMobile ? 1 : index <= 1 ? 2 : 1}
            sx={videoItemStyle}
          >
            <video
              src={`/loyalty-portal/api/attachments/${item.name}${item.extension}`}
              controls
            />
          </ImageListItem>
        ))}
    </ImageList>
  );
};
export default PreviewVideos;

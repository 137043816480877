import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";
import InputMask from "react-input-mask";

import InputWrapper from "../../common/Inputs/InputWrapper/InputWrapper";
import InputLine from "../../common/Inputs/InputLine/InputLine";
import { UserInfo, UserInfoValidation } from "../../../types/UserInfo.types";
import {
  checkEmail,
  checkPhoneNumber,
  checkTelegram,
  PHONE_PLACEHOLDER,
} from '../validation';

interface ContactsProps {
  personData: UserInfo;
  setPersonData: Dispatch<SetStateAction<UserInfo>>;
  phoneNumberError?: string | null;
  setPhoneNumberError: Dispatch<SetStateAction<string | null>>;
  validation: UserInfoValidation;
}
export const Contacts: FC<ContactsProps> = ({
  personData,
  setPersonData,
  phoneNumberError,
  setPhoneNumberError,
  validation,
}) => {
  const [editing, setEditing] = useState(false);

  const handlePhoneNumberChange = (value?: string) => {
    const invalidPhone = !checkPhoneNumber(value) && !editing;
    setPhoneNumberError(invalidPhone ? `Введите номер телефона в виде ${PHONE_PLACEHOLDER}` : null);
  };

  const handleChange =
    (prop: keyof UserInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setEditing(true);
      setPersonData({ ...personData, [prop]: event.target.value });
    };

  const handleBlur = () => {
    setEditing(false);
  };

  useEffect(() => {
    handlePhoneNumberChange(personData?.phone);
  }, [personData?.phone, editing]);

  const data = [
    {
      name: "Контактный е-mail",
      element: (
        <InputLine
          testid="email"
          value={personData?.email}
          onChange={handleChange("email")}
          onBlur={handleBlur}
          placeholder="example@example.com"
          error={!validation.email && !editing}
          helperText={"Введите email в виде - ___@___.__"}
        />
      ),
    },
    {
      name: "Телефон",
      element: (
        <InputMask
          mask="+7\ (999) 999 99 99"
          value={personData?.phone}
          onChange={handleChange("phone")}
          onBlur={handleBlur}
        >
          <InputLine
            testid="phone"
            placeholder={PHONE_PLACEHOLDER}
            error={!validation.phone && !editing}
            helperText={phoneNumberError ?? ''}
          />
        </InputMask>
      ),
    },
    {
      name: "WhatsApp",
      element: (
        <InputMask
          mask="+7\ (999) 999 99 99"
          value={personData?.phone}
          onChange={handleChange("phone")}
          onBlur={handleBlur}
        >
          <InputLine testid="whatsapp" placeholder={PHONE_PLACEHOLDER} />
        </InputMask>
      ),
    },
    {
      name: "Telegram",
      element: (
        <InputLine
          testid="telegram"
          value={personData?.telegram}
          onChange={handleChange("telegram")}
          onBlur={handleBlur}
          placeholder="@name"
          error={!validation.telegram && !editing}
          helperText={"Введите имя, начиная с @"}
        />
      ),
    },
  ];
  return (
    <Stack spacing={4}>
      {data.map((item, index) => (
        <InputWrapper key={index} title={item.name} element={item.element} />
      ))}
    </Stack>
  );
};

export default Contacts;

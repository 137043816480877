import React, { FC } from "react";

import Fab from "@mui/material/Fab";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";

import { iconButton } from "../../../../constants/buttonStyle";

interface Props {
  children: React.ReactElement;
}

function ScrollBottom(props: Props) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 20,
    target: document.querySelector("#main-scroll") ?? window,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector("#back-to-bottom-anchor");

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 100, right: "25%" }}
      >
        {props.children}
      </Box>
    </Fade>
  );
}

export const NavigateButton: FC = (props) => {
  return (
    <ScrollBottom {...props}>
      <Fab
        aria-label="scroll to bottom"
        sx={{ ...iconButton }}
        size="small"
        color="secondary"
        component="label"
      >
        <KeyboardArrowDownIcon />
      </Fab>
    </ScrollBottom>
  );
};

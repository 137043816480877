import React, { FC, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import { profilesInitial } from '../../../actions/profiles';
import { roles } from '../../../constants/role';
import { textSnackbars } from '../../../constants/snackBarNotifications';
import { getUsersRightSidebar } from '../../../operation/getUsersRightSidebar';
import { personController, useUpdateFiredMutation } from '../../../services/person.controller';
import EditPopover from '../../common/Modals/EditPopover/EditPopover';
import { CompanyContext } from '../CompanyContext/CompanyContextProvider';
import { DialogBlock } from './DialogBlock/DialogBlock';
import { Moment } from 'moment';

interface ActionButtonProps {
  id: string;
}
export const ActionButton: FC<ActionButtonProps> = ({ id }) => {
  const [firedDate, setFiredDate] = useState<Moment | null>(null);
  const [isDialog, setIsDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateFired] = useUpdateFiredMutation()

  const loggedUser = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY') || '{}');

  const handleEdit = (userId: string) => {
    localStorage.setItem('userId', userId);
    companyInitialState.isForUserEditing = true;
    companyInitialState.coordToScrollTo = document.getElementById('main-scroll')!.scrollTop;
    navigate('/profile/edit');
  };

  const {
    status,
    setTotalRecords,
    currentPage,
    handleSnackbar,
    setSortingState,
    companyInitialState,
  } = useContext(CompanyContext);
  const isActive = status === 'Активные';

  const blockService = (userId: string) => {
    personController
      .blockPerson(userId)
      .then(() => {
        personController.allPersons('blocked:false', undefined, currentPage).then((res) => {
          dispatch(profilesInitial.allProfiles(res.data.items));
          setTotalRecords(res.data.totalItems);
          setSortingState([]);
        });
      })
      .then(() => {
        handleSnackbar('success', textSnackbars.blockUser);
      })
      .then(() => getUsersRightSidebar(loggedUser.id, dispatch))
      .catch(() => {
        handleSnackbar('error');
      });
  };

  const unblockService = (userId: string) => {
    personController
      .unblockPerson(userId)
      .then(() => {
        personController.allPersons('blocked:true', undefined, currentPage).then((res) => {
          dispatch(profilesInitial.allProfiles(res.data.items));
          setTotalRecords(res.data.totalItems);
          setSortingState([]);
        });
      })
      .then(() => {
        handleSnackbar('success', textSnackbars.unblockUser);
      })
      .then(() => getUsersRightSidebar(loggedUser.id, dispatch))
      .catch((error) => {
        const axiosError = error as AxiosError<{errorMessage: string}>
        handleSnackbar('error', axiosError.response?.data.errorMessage);
      });
  };

  const popoverButtons = [{ name: 'Редактировать', action: () => handleEdit(id) }];
  if (isActive) {
    popoverButtons.push({ name: 'Заблокировать', action: () => setIsDialog(true) });
  } else {
    popoverButtons.push({ name: 'Разблокировать', action: () => unblockService(id) });
  }

  return (
    <>
      {loggedUser?.role !== roles.user && popoverButtons.length > 0 && (
        <>
          {loggedUser.id !== id && (
            <EditPopover buttonsData={popoverButtons} />
          )}
          {isActive && (
            <DialogBlock
              open={isDialog}
              firedDate={firedDate}
              onClose={() => setIsDialog(false)}
              onCancel={() => setIsDialog(false)}
              onConfirmBlock={() => {
                blockService(id)
                setIsDialog(false)
              }}
              onConfirmFired={() => {
                if (!firedDate) return;
                updateFired({id, body: {fired: firedDate.format("YYYY-MM-DD")}})
                setIsDialog(false)
              }}
              onSetFiredDate={setFiredDate}
            ></DialogBlock>
          )}
        </>
      )}
    </>
  );
};

export default ActionButton;

import React, { FC } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { ReactComponent as TelegramIcon } from "../../../assets/icons/logo_telegram.svg";
import { ReactComponent as WhatsAppIcon } from "../../../assets/icons/logo_whatsapp.svg";

import { PersonView } from "../../../models/profile.models";

interface ContactsProps {
  userInfo: PersonView;
}

export const Contacts: FC<ContactsProps> = ({ userInfo }) => {
  const phoneNumber = userInfo?.contact?.phone?.replaceAll(/[\D^+]/g,'') ?? '';
  const telegramLogin = userInfo?.contact?.telegram?.replaceAll(/^@/g,'') ?? '';

  const data = [
    {
      name: "email",
      info: userInfo?.contact?.email,
      link: `mailto:${userInfo?.contact?.email}`,
      icon: <EmailIcon color="secondary" />,
    },
    {
      name: "phone",
      info: userInfo?.contact?.phone,
      link: `tel:${phoneNumber}`,
      icon: <PhoneIcon color="secondary" />,
    },
    {
      name: "whatsApp",
      info: userInfo?.contact?.phone,
      link: (phoneNumber) ? `https://wa.me/${phoneNumber}` : '',
      icon: <WhatsAppIcon color="secondary" />,
    },
    {
      name: "telegram",
      info: userInfo?.contact?.telegram,
      link: (telegramLogin) ? `https://t.me/${telegramLogin}` : '',
      icon: <TelegramIcon color="secondary" />,
    },
  ].filter((item) => !!item.info);

  const stylePaper = {
    p: 3,
    borderRadius: "12px",
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gap: "18px",
  };

  const styleTitle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  if (data.length === 0) {
    return null;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" mb={2}>
        Контакты
      </Typography>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item mobile={12} laptop={6} key={index}>
            <Paper component="a" href={item.link} sx={stylePaper}>
              {item.icon}
              <Typography variant="body2" sx={styleTitle} title={item.info}>{item.info}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Contacts;

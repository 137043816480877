import { SystemStyleObject, Theme } from '@mui/system';

const keys = ['container', 'datePicker'] as const;

type StylesType = Record<(typeof keys)[number], SystemStyleObject<Theme>>

export const styles: StylesType = {
  container: {
    marginLeft: '5rem',
    width: 'calc(50% - 6px)',
    display: 'flex',
    alignItems: 'center',
  },
  datePicker: {
    marginRight: '1rem',
  },
};

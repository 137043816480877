import React, { FC, useContext } from "react";
import { useNavigate } from "react-router";

import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import moment from "moment";

import DialogWrapper from "../../../../common/Modals/DialogWrapper/DialogWrapper";
import ActionButtons from "./ActionButtons/ActionButtons";
import StatusBar from "../StatusBar/StatusBar";
import { AppContext } from "../../../../shared/AppContextProvider";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import AchievComments from "../AchievComments/AchievComments";
import { AchievContext } from "../../../AchievContextProvider/AchievContextProvider";
import { achievementController } from "../../../../../services/achievement.controller";

interface TaskProps {
  openModal: boolean;
  handleOpen: () => void;
}

const textStyle = {
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",
};

export const ViewModal: FC<TaskProps> = ({ openModal, handleOpen }) => {
  const { isMobile } = useContext(AppContext);
  const { editableAcces, handleSnackbar, handleTasks, isChangeStatus } =
    useContext(AchievContext);
  const achievementId = sessionStorage.getItem("achievementId") || "";

  const task = useAppSelector(
    (state) => state.achievementsReducer.achievementTask
  );
  const modalHeader = (
    <Box>
      {editableAcces && (
        <ActionButtons taskId={task.id} handleClose={handleOpen} />
      )}
      <Box>
        <Typography
          variant="body1"
          sx={textStyle}
          color={(theme) => theme.palette.text.secondary}
        >
          Создал {task.author?.name} {task.author?.surname}{" "}
          {moment(task.date).format("DD.MM.YYYY")}
        </Typography>
        {task.modifyBy && (
          <Typography
            variant="body1"
            sx={textStyle}
            color={(theme) => theme.palette.text.secondary}
          >
            Обновил {task.modifyBy?.name} {task.modifyBy?.surname}{" "}
            {task.modifyAt && moment(task.modifyAt).format("DD.MM.YYYY")}
          </Typography>
        )}
      </Box>
    </Box>
  );

  const wordWrap = { wordWrap: "break-word" };
  const mainInfo = (
    <Box>
      <Typography variant="h3" sx={wordWrap}>
        {task.header}
      </Typography>
      <Typography variant="body2" sx={wordWrap}>
        {task.content}
      </Typography>
    </Box>
  );

  const statusBar = <StatusBar type="edit" task={task} />;
  const comments = <AchievComments taskId={task.id} />;
  const mobileList = (
    <Stack gap={3}>
      {modalHeader}
      {mainInfo}
      {statusBar}
      {comments}
    </Stack>
  );

  const navigate = useNavigate();
  const closeModal = () => {
    handleOpen();
    if (isChangeStatus) {
      achievementController
        .tasks(achievementId)
        .then((res) => handleTasks(res.data.items))
        .catch(() => handleSnackbar("error"));
    }
    //for link notification
    if (window.location.href.includes("tasks")) {
      const userId = localStorage.getItem("userId");
      navigate(`/profile/${userId}`);
    }
  };

  return (
    <DialogWrapper
      openModal={openModal}
      handleModal={closeModal}
      width="862px"
      stylesContent={{ maxHeight: "798px" }}
    >
      {isMobile ? (
        mobileList
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          wrap="nowrap"
          gap={6}
        >
          <Grid item laptop={8}>
            <Stack gap={3}>
              {modalHeader}
              {mainInfo}
              {comments}
            </Stack>
          </Grid>
          <Grid item laptop={4}>
            {statusBar}
          </Grid>
        </Grid>
      )}
    </DialogWrapper>
  );
};

export default ViewModal;

import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import moment, { Moment, isMoment } from 'moment';

import { SharedButton } from '../../../common/Buttons/SharedButton';
import DatePickerCustom from '../../../common/Inputs/DatePickerCustom/DatePickerCustom';
import { theme } from '../../../../constants/theme';
import { styles } from './styles';
import { DialogFired } from './ui/DialogFired/DialogFired';

interface FiredDateProps {
  isMobile: boolean;
  fired: string | null;
  onchangeFired: (fired: string | null) => void;
}

export const FiredDate = ({ isMobile, fired, onchangeFired }: FiredDateProps): JSX.Element => {
  const [firedState, setFiredState] = useState<'clear' | Moment | null>(null);
  const [date, setDate] = useState<Moment | null>(fired ? moment(fired, 'YYYY-MM-DD') : null);

  useEffect(() => {
    setDate(fired ? moment(fired, 'YYYY-MM-DD') : null);
  }, [fired]);
  return (
    <Box sx={[styles.container, isMobile && {marginLeft: 0, width: '100%' }]}>
      <DatePickerCustom
        value={date?.format() ?? ''}
        styles={styles.datePicker}
        disableFuture={false}
        onChange={(date: Moment) => {
          setDate(date);
          setFiredState(date);
        }}
      />
      <SharedButton
        designType="secondary"
        sizeByDesign="40px"
        systemStyleObject={{ flexShrink: 0 }}
        onClick={() => setFiredState('clear')}
        disabled={!date}
      >
        Очистить
      </SharedButton>
      <DialogFired
        open={firedState === 'clear'}
        onClose={() => setFiredState(null)}
        onCancel={() => setFiredState(null)}
        onConfirm={() => {
          onchangeFired(null)
          setDate(null);
          setFiredState(null);
        }}
        content="После удаления даты увольнения система больше не будет автоматически блокировать доступ данного сотрудника к порталу"
      />
      <DialogFired
        open={isMoment(firedState)}
        onClose={() => setFiredState(null)}
        onCancel={() => setFiredState(null)}
        onConfirm={() => {
          if (date) onchangeFired(date.format('YYYY-MM-DD'))
          setFiredState(null);
        }}
        content="После сохранения новой даты увольнения, если новая дата уже наступила, доступ к системе будет автоматически заблокирован"
      />
    </Box>
  );
};

import React, { FC, useEffect, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { kbController } from "../../../services/kb.controller";
import { KbSectionView } from "../../../models/kb.models";
import { KbContext } from "../KbContextProvider/KbContextProvider";
import { dataInitial } from "../../../actions/data";

import ShowMoreButton from "../ShowMoreButton/ShowMoreButton";
import SectionWrapper from "./SectionWrapper/SectionWrapper";

import {
  Box,
  Stack
} from "@mui/material";

export const KnowledgeSections: FC = () => {
  const dispatch = useDispatch();

  const sections: KbSectionView[] = useAppSelector(
    (state) => state.dataReducer.articleSections
  );
  
  const { searchKbValue, totalItems, setTotalItems } = useContext(KbContext);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const getNexSections = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const getFirstSections = () => {    
    kbController.sections().then((res) => {      
      dispatch(dataInitial.articleSections(res.data.items));
      setTotalItems(res.data.totalItems);
      setCurrentPage(0);
    });
  };

  useEffect(() => {
    if (searchKbValue) {
      kbController.search(searchKbValue).then((res) => {
        dispatch(dataInitial.articleSections(res.data.items));
        setTotalItems(res.data.totalItems);
        setCurrentPage(0);
      });
    } else {
      getFirstSections();
    }
  }, [searchKbValue]);

  useEffect(() => {
    if (currentPage > 0) {
      kbController.sections(currentPage).then((res) => {
        dispatch(dataInitial.articleSections([...sections, ...res.data.items]));
        setTotalItems(res.data.totalItems);
      });
    }
  }, [currentPage]);

  const showMore = sections.length < totalItems;

  return (
    <Box>
      <Stack gap="12px">
        {sections?.map((item, index) => (
          <SectionWrapper
            updSections={getFirstSections}
            section={item}
            key={index}
          />
        ))}
      </Stack>
      {showMore && (
        <Box display="flex" justifyContent="center" mt="12px">
          <ShowMoreButton onClick={getNexSections} />
        </Box>
      )}
    </Box>
  );
};

export default KnowledgeSections;

import { achievementsInitial } from "../actions/achievements";
import { ACHIEVEMENTS } from "../constants/actions";
import {
  AchievementView,
  TaskTagView,
  TaskView,
} from "../models/achievement.models";
import { CommentView } from "../models/comment.models";
import { ReturnActionsTypes } from "../store";

import { initialAchievements } from "./initialStates/initialAchievements";

export type AchievementsInfo = {
  achievement: AchievementView;
  achievementTask: TaskView;
  achievementsList: AchievementView[];
  achievementTags: TaskTagView[];
  achievementComments: CommentView[];
};

const initialState: AchievementsInfo = initialAchievements;

export const achievementsReducer = (
  state = initialState,
  action: ReturnActionsTypes<typeof achievementsInitial>
): AchievementsInfo => {
  switch (action.type) {
    case ACHIEVEMENTS.ACHIEVEMENT:
      const achievementsList = state.achievementsList.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      return { ...state, achievementsList, achievement: action.payload };
    case ACHIEVEMENTS.ACHIEVEMENT_TASK:
      return { ...state, achievementTask: action.payload };
    case ACHIEVEMENTS.ACHIEVEMENTS_LIST:
      return { ...state, achievementsList: action.payload };
    case ACHIEVEMENTS.ACHIEVEMENT_TAGS:
      return { ...state, achievementTags: action.payload };
    case ACHIEVEMENTS.ACHIEVEMENT_COMMENTS:
      return { ...state, achievementComments: action.payload };
    default:
      return state;
  }
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linear-wrapper{
    width: 100%;
    height: 8px;
    background-color: #212633;
    display: flex;
    gap: 4px;
}

.linear-segment{
    height: 100%;
    border-radius: 10px;
}

@keyframes fillAnimation {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

.linear-segment-full {
    animation: fillAnimation 0.5s ease-out forwards;
}`, "",{"version":3,"sources":["webpack://./src/components/common/LinearProgressBar/linearProgressBar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI;QACI,SAAS;IACb;IACA;QACI,WAAW;IACf;AACJ;;AAEA;IACI,+CAA+C;AACnD","sourcesContent":[".linear-wrapper{\n    width: 100%;\n    height: 8px;\n    background-color: #212633;\n    display: flex;\n    gap: 4px;\n}\n\n.linear-segment{\n    height: 100%;\n    border-radius: 10px;\n}\n\n@keyframes fillAnimation {\n    from {\n        width: 0%;\n    }\n    to {\n        width: 100%;\n    }\n}\n\n.linear-segment-full {\n    animation: fillAnimation 0.5s ease-out forwards;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
